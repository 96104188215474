.cold-work-container{
    background-color: white;
    border: 1px solid black;
    /* padding: 10px 15px; */
    /* margin: 10px 15px; */
    width: 100vw;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.template-container{
  background-color: yellow !important;
  padding: 25px ;
}
.cold-work-template{
  background-color: yellow !important;
  /* border: 1px solid black; */
  /* padding: 10px 15px; */
  padding: 25px ;
  /* margin: 10px 15px; */
  width: 100vw;
}

.template-container-content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}
.span-table{
  font-size: 12.5px;
}
.isprl-img{
  width: 11vw;
  height: 24vh;
}
/* .template-container-first img {
    width: 100%;
    height: auto;
    max-width: 300px; 
    border: 1px solid #ccc;
    border-radius: 8px;
  } */
  .template-container-second{
    text-align: center;
    font-weight: 600;
  }
  .line1{
    /* font-weight: bold; */
    font-size: 26px;
  }
  .line2{
    /* font-weight: bold; */
    font-size: 15.5px;
  }
  .line3{
    /* font-weight: bold; */
    font-size: 22px;
  }
  .line4{
    /* font-weight: bold; */
    font-size: 16.5px;
  }
  .line5{
    /* font-weight: bold; */
    font-size: 22px;
  }
  .line6{
    /* font-weight: bold; */
    font-size: 16.5px;
  }
  .line11{
    font-weight: 600;
    font-size: 20px;
    color: red;
  }
  .template-container-header{
    font-weight: 600;
    text-align: center;
    align-items: center;
  }
  .template-heading{
    text-align: left;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
  }
  .cold-remark h4{
    text-align: left;
    text-decoration: underline;
    /* padding-left: 33px; */
    font-size: 18px;
    font-weight: 600;
  }
  .prno{
    width: 4vw;
    font-weight: 600;
    font-size: 20px;
    color: black !important;
  }.line11{
    display: flex;
  }
  .table-closing1{
    width: 40vw;
  }
  .table-closing2{
    width: 20vw;
  }