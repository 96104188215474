@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .heading-end-last {
        width: 402px;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1199px){
    .header-logo {
        width: 50%;
    }
    /* .heading-end-last {
        width: 383px;
    } */
}
@media screen and (min-width: 1200px) and (max-width: 1299px){
    .username span{
        font-size: 16px;
    }
    .header-logo {
        width: 50%;
    }
    .heading-end-last {
        width: 406px;
    }
}
@media screen and (min-width: 2100px) and (max-width: 2199px){
    .product-content-containe{
        height: 94.5vh;
    }
}