.view-attachment{
 background: skyblue;
 padding: 5.005px 9.997px;
 border-radius: 5px;
 border: none;
 color: white;
 font-size: 16px;
 margin-left: 5px;
}
svg.download-attachment-icon {
    margin-top: 12px !important;
}
.download-attachment{
    background: skyblue;
 padding: 5.005px 9.997px;
 border-radius: 5px;
 border: none;
 color: white;
 font-size: 16px;
 margin-left: 5px;
   }
.upload-label{
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 5px !important;
}
.view-attachment-icon,.download-attachment-icon{
    vertical-align: text-bottom;
}
.invoice-body-container-content {
    display: flex;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
.invoice-upload-files {
    border: 1px solid #ddd;
    margin: 10px 30px;
    width: 90%;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: #f9f9fb;
}
.file-name{
    color: blue;
}
.file-name-label{
    display: flex;
}
.filename{
    width: 65%;
}