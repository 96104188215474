.reminder-popup-model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.5); */
    background-color: #00000080;
    z-index: 1000;
}
.reminder-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 60%;
    height: 60%;
    z-index: 9999;
}

.reminder-popup-model-content-header,
.paymentedit-popup-model-content-header {
    padding: 20px;
    border-bottom: 1px solid #eee;
    color: #212529;
    font-size: 22px;
    font-weight: 500;
    display: flex;
    justify-content: space-between;
}
.popup-new-form {
    /* background: #f7fafa; */
    background-color: #f8f8f8;
    padding: 10px;
}
.popup-form-detail {
    height: 40vh !important;
    overflow-y: scroll;
    border: 1px solid #ddd;
    background: white;
    border-radius: 0.35rem;
    padding: 10px;
}
.paymentedit-popup-model {
    /* position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; */

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.paymentedit-popup-content {
    /* background-color: #fff;
    top: 50;
    left: 50;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 43vw !important; */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    height: 68%;
    z-index: 9999;

}

.footers {
    /* position: fixed; */
    background-color: rgb(255, 255, 255);
    border-top: 1px solid #ccc;
    bottom: 0%;
    /* width: 92.2% !important; */
    padding: 15px 20px;
    /* border-radius: 8px; */
}

.newperson-user-form {
    padding: 20px;
}
.form-group-payment,
.form-group-payments {
    display: flex;
    width: 100%;
    margin: 10px 0px;
}

.input-group-project {
    display: flex !important;
}
.input-group-project label {
    cursor: pointer;
    display: flex !important;
}

.form-group-payment label,
.form-group-payments label {
    width: 28%;
    font-size: 13px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 400;
    display: inline-block;
}

.form-group-payment input {
    width: 19vw !important;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}



.form-group-payments input {
    width: 19vw !important;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-payment textarea {
    border: 1.5px solid #ccc;
    border-radius: 4px;
    padding: 7px 21.5px 7px 10px;
    width: 18vw !important;
}
.form-group-payment input:hover {
    border: 1.5px solid #5da0bd !important;
}

.form-group-payment input:focus {
    outline: 1.5px solid #5da0bd;
}

.form-group-payment textarea:focus {
    outline: 1.5px solid #5da0bd;
}

.person-user-save-button{
    background: #408dfb;
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    margin-left: 10px;
    padding: 5.005px 9.997px;
}
.person-user-cancel-button {
    background-color: #f5f5f5;
    border: 1px solid #c5bfbf;
    border-radius: 5px;
    color: #212529;
    font-size: 13px;
    margin-left: 10px;
    padding: 5.005px 9.997px;
}

.person-user-save-button.sending {
    background-color: gray; /* Change the color to indicate it's disabled */
    border: 1px solid gray;
    cursor: not-allowed; /* Show the not-allowed cursor */
  }
  
  .person-user-save-button:disabled {
    background-color: gray;
    border: 1px solid gray;
    cursor: not-allowed;
  }
  .sending-message{
    color: blue;
    text-align: center;
    padding: 10px;
  }
  .success-message{
    color: green;
    text-align: center;
    padding: 10px;
  }
  .error-message-email{
    color: red;
    text-align: center;
    padding: 10px;
  }
  .button-group-email{
    display: flex;
    justify-content: space-between;
  }

  .ok-button{
    background: green;
    border: 1px solid green !important;
    border-radius: 5px;
    color: white;
    font-size: 13px;
    margin-left: 10px;
    padding: 5.005px 9.997px;
  }

  .email-filed .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 35.3% !important;
}
.email-filed .input-group {
    width: 35.5% !important;
}
input.dailyconfig1 {
    border: none !important;
}
.emailconfig1 input {
    border: none !important; /* Removes the border */
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Ensures no shadow is applied */
  }
  .subject1 textarea {
    border: none !important; /* Removes the border */
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Ensures no shadow is applied */

  }
  .body1 textarea {
    border: none !important; /* Removes the border */
    outline: none; /* Removes the focus outline */
    box-shadow: none; /* Ensures no shadow is applied */

  }
  
  .permit-popup-model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #4443431a;
    z-index: 1000;
}
.permit-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 40%;
    height: 50%;
    z-index: 9999;
}
.permit-popup-form-detail {
    height: 30vh !important;
    overflow-y: scroll;
    border: 1px solid #ddd;
    background: white;
    border-radius: 0.35rem;
    padding: 20px 25px;
}
.popup-email-date{
    margin-left: 25px;
    margin-top: 8px;
    color: cadetblue;
    font-size: 14px;
}
  