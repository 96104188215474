.request-button{
    /* background-color: #45a049;
    padding: 5.005px 9.997px;
    border-radius: 5px;
    color: white;
    margin-left: 5px;
    font-size: 13px;
    border: none; */

    border: 1px solid #45a049;
    color: white;
    background: #45a049;
    border-radius: 4px;
    padding: 4.005px 9.997px;
    margin-left: 5px;
    width: 10vw;
}
.inquiry-attach-popup {
    position: absolute;
    top: 79.5%;
    right: 12%;
    background-color: #fff;
    border: 1px solid #ccc;
    /* padding: 10px; */
    border-radius: 5px;
    z-index: 999;
    width: max-content;
  }
  .prRequest-popup{
    position: absolute;
    z-index: 999 !important;
    top: 84%;
    right: 15%;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
    /* width: 15%; */
  }
  /* .Whole-popup {
    position: absolute;
    z-index: 999 !important;
    top: 84%;
    right: 22%;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
} */
.Pr-popup{
  position: absolute;
    z-index: 999 !important;
    top: 84%;
    right: 25%;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
}
.User-popup{
  position: absolute;
    z-index: 999 !important;
    top: 95%;
    right: 5%;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
}
.polist-popup {
  position: absolute;
  z-index: 999 !important;
  top: 84%;
  right: 8.5%;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 9999;
  width: max-content;
}
 .technical-description-popup{
  position: absolute;
  z-index: 999 !important;
  top: 84%;
  right: 6.5%;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 9999;
  width: 20%;
 }
  .attach-popup-contant-body {
    padding: 2px 10px;
  }
  
  .view-data.active {
    background-color: #9ca8b6;
    color: #ffffff;
  }
  
  /* karan new css */
  .attach-popup-contant-body .file-delete-btn {
    padding: 0px !important;
    margin: 0px !important;
    margin-right: 5px !important;
  }
  .attach-popup-contant-body .file-delete-btn span {
    height: 20px !important;
  }
  .no-attachments {
    padding: 8px 12px;
  }
  .file-txt {
    font-size: 12px !important;
  }
  .archive-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #2f2e2e;
    font-weight: 400;
  }
  .file-footer{
    display: flex;
    justify-content: space-between;
    padding:  0 10px;
  }
  .file-txt{
    color: blue;
  }
  .inquiry-file-btns {
    background: #007bff;
    color: white;
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    padding: 8px 9.009px !important;
    font-size: 12px;
    cursor: pointer;
    border: none;
    /* border: 1px solid #007bff; */
}
.inquiry-file-icon{
    font-size: 17px;
    vertical-align: text-bottom;
    margin-top: 2px !important;
}
.request-statuses221{
    display: flex;

}
.back-company{
    padding-top: 5px !important;
    margin-right: 7px;
    cursor: pointer;
    vertical-align: top;
}
.edit-popup-buttons button {
    border: 1px solid #408DFB;
    color: white;
    background: #408DFB;
    border-radius: 4px;
    padding: 5.005px 9.009px;
}
.edit-popup-buttons{
  display: flex;
}
.edit-button-inquiry1{
  display: flex;
}
.edit-show-icon{
vertical-align: middle;
padding-top: 2px;
padding-right: 2px;
}

.edit-button-inquiry1 {
  background-color: #007bff; /* Default color */
  color: white;
  cursor: pointer;
}

.edit-button-inquiry1.disabled-button {
  background-color: gray; /* Gray color when disabled */
  color: #cccccc; /* Lighter gray text color when disabled */
  cursor: not-allowed; /* Cursor style to indicate it's disabled */
}
.edit-button-inquiry1 {
  /* padding: 8px 16px; */
  padding: 4.005px 9.997px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.edit-button-inquiry1.disabled-button {
  background-color: #d3d3d3; /* Gray color */
  color: #808080; /* Optional: lighter text color */
  cursor: not-allowed;
  border: 1px solid #d3d3d3 ;
}
.generate-scheduler-button1{
  padding: 4.005px 9.997px;
  background-color: #4CAF50;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3sease;
    margin-left: 5px;
    width: 9.5vw;
}
.generate-scheduler-button1:disabled {
  background-color: #ccc;
  color: #666;
  border: 1px solid #aaa !important;
  opacity: 0.6;
  cursor: not-allowed !important;
  width: 9.5vw;
}
.pr-button{
  display: flex;
}