@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    .dashboard-container-content{
        flex-direction: column;
    }
    .chart-donut{
        width: 100%;
    }
    .donut-chart{
        width: 100% !important;
    }
    .detail-chart{
        width: 26%;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1199px){
    .dashboard-container-content{
        flex-direction: column;
    }
    .chart-donut{
        width: 100%;
    }
    .donut-chart{
        width: 90%  !important;
    }
    .detail-chart{
        left: 24%;
        width: 34%;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
    .donut-chart canvas{
        left: -8%;
    }
    .heading-total{
        font-size: 10px;
    }
    .chart-donut-heading{
        font-size: 17px;
    }
    .total-project-line{
        font-size: 7px;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1299px){
    .dashboard-container-content{
        flex-direction: column;
    }
    .chart-donut{
        width: 100%;
    }
    .donut-chart{
        width: 90%  !important;
    }
    .detail-chart{
        left: 24%;
        width: 34%;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
    .donut-chart canvas{
        left: -8%;
    }
    .heading-total{
        font-size: 11px;
    }
    .chart-donut-heading{
        font-size: 17px;
    }
    .total-project-line{
        font-size: 9px;
    }
}
@media screen and (min-width: 1300px) and (max-width: 1399px) {

    .dashboard-container-content{
        flex-direction: column;
    }
    .chart-donut{
        width: 100%;
    }
    .donut-chart{
        width: 90%  !important;
    }
    .detail-chart{
        left: 24%;
        width: 34%;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
    .donut-chart canvas{
        left: -8%;
    }
    .heading-total{
        font-size: 13px;
    }
    .chart-donut-heading{
        font-size: 17px;
    }
    .total-project-line{
        font-size: 10px;
    }
}
@media screen and (min-width: 1900px) and (max-width: 1999px){
    .donut-chart {
        margin-left: 138px !important;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
}
@media screen and (min-width: 2100px) and (max-width: 2199px){
    .donut-chart {
        margin-left: 193px !important;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
}
@media screen and (min-width: 2200px) and (max-width: 2299px){
    .donut-chart {
        margin-left: 193px !important;
    }
    .chart-donut1, .chart-donut2 {
        height: 64vh;
        overflow-y: scroll;
    }
}