/* Container for Switch */
.switch-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Adds spacing between labels and switch */
}

/* OFF and ON labels */
.switch-label-left,
.switch-label-right {
  font-size: 14px;
  font-weight: bold;
  color: #555;
  transition: color 0.3s ease-in-out;
}

/* Styling for the Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

/* Hide default checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider (switch background) */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

/* Knob inside the switch */
.slider::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Switch is checked */
input:checked + .slider {
  background-color: #4caf50;
}

/* Move the knob to the right */
input:checked + .slider::before {
  transform: translateX(26px);
}

/* Change label colors when switched */
input:checked ~ .switch-label-right {
  color: #4caf50;
}

input:not(:checked) ~ .switch-label-left {
  color: red;
}
