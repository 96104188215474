.hr-view{
    padding-bottom: 10px;
}
.file-footer-hr a {
    color: #4545c2 !important;
}
.attach-hr-file-container {
    justify-content: space-between;
  
    align-items: center;
    width: 100%;
}
span.hr-srNO {
    font-weight: 600;
}
.attach-popup-attachments {
    position: absolute;
    /* top: -7%; */
    right: 5.5%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 18%;
    max-width: 300px;
}
.inquiry-file-btn:focus{
    background-color: black;
}
.no-attachments-message {
    color: gray;
    font-style: italic;
    text-align: center;
    margin: 10px 0;
  }
  
  