.hot-work-tables thead th {
    background-color: #DB5E7E !important ;
}
.permit-check1 {
    appearance: none;
    -webkit-appearance: none;
    border: 2px solid black !important;
    background-color: #DB5E7E !important ;
    /* width: 16px; */
    height: 2vh !important;
    width: 2.5vw !important;
    cursor: pointer;
}