/* 

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .dashboard-container-content{
    display: flex;

  }
  .chart, .table{
    width: 50%;
  }
  
  .dashboard-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .chart-container {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 800px;
  }
  
  .pie-chart {
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  
  canvas{
    box-sizing: border-box;
    display: block;
    height: 422px;
    width: 376px;
  } */

.dashboard.expanded {
  width: calc(100% - 255px) !important;
}
.expanded {
  margin-left: 250px;
}
.data-form-heading-content-right-side {
  display: flex;
  justify-content: space-between;
}
.data-form-header1 {
  padding: 14px 20px;
}
.no-data-message {
  text-align: center;
}
.chart-donut1,
.chart-donut2 {
  height: 65vh;
  overflow-y: scroll;
}

/* General container styling for the dashboard */
.dashboard-container {
  padding: 20px;
  background-color: white;
  /* border-radius: 12px; */
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); */
  height: 73vh;
  overflow-y: scroll;
  overflow-x: hidden !important;
}
.dashboard-container {
  margin: 0px -5px;
}
.dashboard-container::-webkit-scrollbar,
.dashboard-table-body::-webkit-scrollbar {
  width: 8px;
}

.dashboard-container::-webkit-scrollbar-thumb,
.dashboard-table-body::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.dashboard-container::-webkit-scrollbar-track,
.dashboard-table-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.dashboard-container-content {
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px; */
}

.dashboard-title {
  font-size: 22px;
  color: #4a4a4a;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.5px;
}

.active-status {
  color: rgb(41, 128, 185) !important;
  font-weight: bold;
}

/* Chart section styling */
.chart {
  width: 48%;
  /* height: 70vh; */
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  /* transition: transform 0.3s ease; */
}

/* .chart:hover {
  transform: scale(1.03);
} */

/* .chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 340px;
} */

/* .pie-chart canvas {
  width: 100%;
  height: auto;
  max-width: 400px;
} */

/* Table section styling */
/* .dashboard-tables {
  width: 48%;
  height: 70vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
} */

.dashboard-tables table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.dashbord-table th,
.dashbord-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  color: #555;
}

.dashbord-table th {
  background-color: #f9fafb;
  color: #333;
  font-weight: 600;
}

/* .table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
} */

/* Add hover effect on table rows */
/* .table tbody tr:hover {
  background-color: #e6f7ff;
  cursor: pointer;
} */

.active-status {
  color: #00a676;
  font-weight: bold;
}

.complet-status {
  color: #008cba;
  font-weight: bold;
}

/* Responsive design for smaller screens */

/* .dashboard-container{
} */
.dashbord-table {
  /* height: 65vh; */
  overflow-y: scroll;
  overflow-x: hidden;
}
.dashbord-table::-webkit-scrollbar-thumb,
.chart-donut-body::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.dashbord-table::-webkit-scrollbar-track,
.chart-donut-body::-webkit-scrollbar-track {
  background-color: transparent;
}

.dashbord-table::-webkit-scrollbar,
.chart-donut-body::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.charts {
  display: flex;
  gap: 15px;
}

.chart-donut {
  width: 50%;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  /* Border radius in pixels */
  padding: 15px 0;
}
.chart-donut1,
.chart-donut2 {
  width: 50%;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  margin: 10px 0;
  gap: 20px;
  padding: 15px 0;
}

.chart-donut-heading,
.chart-line-heading {
  padding: 0 18px;
  font-weight: 600;
  font-size: 18px;
  color: #2a2e34;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
}

.chart-donut-body {
  padding: 10px;
  /* overflow-x: hidden;
  overflow-y: scroll;
  height: 62vh; */
}
.chart-donut-body-content {
  text-align: center;
  padding: 13px 0;
}
.donut-chart {
  padding: 10px 0;
  position: relative;
  margin-left: 42px !important;
}
.donut-chart canvas {
  z-index: 999;
  position: relative;
}
.pie-content1 {
  font-size: 18px;
  font-weight: 600;
}
.chart-footer {
  text-align: center;
  padding: 10px 0;
}
.chart-footer2 {
  text-align: center;
  padding: 10px 0 10px 0;
}
.footer-content1 {
  font-size: 18px;
  font-weight: 600;
}
.refresh-button-dashboard {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}

.refresh-icons {
  vertical-align: middle;
  padding-bottom: 2px;
}
.detail-chart {
  position: absolute;
  top: 32%;
  left: 37%;
  z-index: 1;
}
.donut-chart-expense canvas {
  z-index: 999;
  position: relative;
}

.boxs {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  padding: 10px 00px;
  width: 240px;
}

.total-active-projects-box {
  width: 16.5%;
}

.total-invoice-requests-box {
  width: 17.5%;
}

.total-inquiry-box {
  width: 17.5%;
}

.total-overdue-box {
  width: 17.5%;
}
.dashboard-container-first-part,
.dashboard-container-second-part {
  display: flex;
  justify-content: space-between;
}
.dashboard-container-second-part {
  gap: 20px;
}
.boxs:hover {
  border-color: #cccccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.heading-content {
  display: flex;
  justify-content: space-between;
  padding: 0px 18px;
}

.heading-total {
  color: #2a2e34;
  text-align: left;
  font-weight: 600;
  font-size: 13px;
}
.total-project {
  padding: 10px;
  text-align: center;
  margin-top: 5px !important;
}

.total-project-number {
  font-weight: 500;
  /* Bold weight */
  font-size: 31px;
}

.total-project-line {
  font-weight: 600;
  /* Bold weight */
  font-size: 10px;
  color: #656f7d;
}

.total-project:hover {
  background: lightgray;
  margin: 0px 15px;
  border-radius: 8px;
}
.dashboard-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dashboard-table::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.dashboard-table::-webkit-scrollbar-track {
  background-color: transparent;
}
