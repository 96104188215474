.boq-button{
    background: blue;
    padding: 5.005px 9.997px;
    border-radius: 5px;
    border: none;
    color: white;

}
.b-th1{
    width: 8vw;
}
.b-th2{
    width: 20vw;
}
.b-th3{
    width: 5vw;
}
.b-th4{
    width: 5vw;
}
.b-th5{
    width: 5vw;
}
.b-th6{
    width: 5vw;
}
.b-th3 .css-b62m3t-container {
    width: 100.3% !important;
}
.boq-action-button{
    display: flex;
    gap: 5px;
}