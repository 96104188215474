.certificate-container {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    padding: 24px;
    background-color: #fff;
    line-height: 1.5;
  }
  
  .certificate-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }
  
  .certificate-header h1 {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    flex: 3;
  }
  
  .date-time {
    text-align: right;
  }
  
  .date-time p {
    margin: 0;
  }
  
  .certificate-body {
    margin-bottom: 20px;
  }
  
  .equipment-info,
  .isolation-info,
  .isolation-info-english,
  .tag-number,
  .department-info,
  .equipment-number,
  .equipment-name,
  .work-completed,
  .work-completed-english {
    margin: 10px 0;
    line-height: 1.6;
  }
  
  .signature-section {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .signature-field {
    flex: 1;
    text-align: center;
    margin: 0 10px;
  }
  
  .signature-line {

    margin-bottom: 5px;
    height: 10px;
  }
  
  .section-divider {
    border-bottom: 1px dashed #000;
    margin: 20px 0;
  }
  
  .section-b h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;
  }
  
  .energisation-request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
  }
  
  .date-time-right {
    text-align: right;
  }
  
  .department-info-line {
    border-bottom: 1px dotted #000;
    padding-bottom: 5px;
  }
  
  @media (max-width: 768px) {
    .certificate-header {
      flex-direction: column;
    }
  
    .date-time {
      margin-top: 10px;
      text-align: left;
    }
  
    .signature-section {
      flex-direction: column;
    }
  
    .signature-field {
      margin: 20px 0;
    }
  
    .energisation-request {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .date-time-right {
      margin-top: 10px;
      text-align: left;
    }
  }
  
  