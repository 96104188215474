.status-show{
    border: 1.5px solid #ddd;
    padding: 5.005px 15.997px;
    border-radius: 25px;
    margin-left: 18px;
    box-shadow: 0 2px 5px 0 rgba(221, 221, 221, 0.5);

}
.department-show{
    border: 1.5px solid #ddd;
    padding: 5.005px 15.997px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(221, 221, 221, 0.5);
}
.option-show{
    border: 1.5px solid #ddd;
    padding: 5.005px 15.997px;
    border-radius: 25px;
    box-shadow: 0 2px 5px 0 rgba(221, 221, 221, 0.5);
}
.report-name{
    font-weight: 500;
}