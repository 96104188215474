@media (min-width: 1200px) and (max-width: 1299px) {
  .pasword-icon {
    top: 10px;
  }
  .form-group input[type="radio"] {
    margin-top: 5px !important;
  }
  .breakdown-first-popup {
    top: 48% !important;
    left: 56% !important;
  }
  .breakdown-second-popup {
    position: absolute;
    top: 72% !important;
    left: 56.75% !important;
  }
  .form-group input {
    margin-top: 3px !important;
  }
  .attach-popup-amc4 {
    top: 48px !important;
    right: 2% !important;
  }
  .input-group textarea {
    width: 254.5px !important;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .pasword-icon {
    top: 10px;
  }
  .breakdown-second-popup {
    top: 67% !important;
    left: 53.75% !important;
  }
  .breakdown-first-popup {
    top: 46% !important;
    left: 54% !important;
  }
  .prRequest-popup {
    top: 75% !important;
    right: 16% !important;
  }
  .checkbox-input input[type="radio"] {
    margin-top: -2px !important;
  }
  .attach-popup-amc4 {
    top: 19px !important;
    right: 0% !important;
  }
  /* .action-button {
    width: 20%;
  } */
  .Pr-popup {
    top: 70% !important;
    right: 22% !important;
  }
  .Whole-popup {
    left: 78% !important;
  }
  .input-group textarea {
    width: 284.5px !important;
  }
  .checkbox-input {
    display: flex;
    margin-right: 10px;
    align-items: center;
  }
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .pasword-icon {
    top: 10px;
  }
  .breakdown-first-popup {
    top: 45% !important;
    left: 53% !important ;
  }
  .breakdown-second-popup {
    top: 67% !important;
    left: 53.75% !important;
  }
  .checkbox-input input[type="checkbox"] {
    margin-top: 4px !important;
  }
  .fourth-popup {
    left: 39% !important;
    bottom: -163px !important;
  }
  .attach-popup-amc4 {
    top: 45px !important;
    right: 2% !important;
  }
  .Whole-popup {
    top: 78.5% !important;
    right: 8% !important;
  }
  .input-group textarea {
    width: 317.5px;
  }
}
