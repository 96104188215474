.document-library-button{
    background: #2a4286;
    border: none;
    color: white;
    /* padding: 5px 12px; */
    padding: 5.005px 9.997px;
    /* text-align: center; */
    border-radius: 4px;
    font-size: 13px;
}
.document-library-button-attchment {
    color: white;
    border: none;
    background: #2a4286;
    border-radius: 4px;
    padding: 8.005px 9.997px;
    margin-left: 5px;
}
.status-vendor button{
    border-radius: 30px;
    color: #fff;
    padding: 5px 8px;
    border: none;
    text-align: center !important;
}
.pr-request-status{
display: flex;
}

.edit-button-table.disabled-button {
    background-color: #d3d3d3;
    color: #808080;
    cursor: not-allowed !important;
    border: none;
}
.edit-button-table {
    padding: 4.005px 9.997px;
    background: #2a4286;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: flex
;
}
.edit-icon-table {
    vertical-align: middle;
    padding-top: 2px;
    padding-right: 2px;
}

.pr-request-button {
    border: none;
    color: white;
    background: #45a049;
    border-radius: 4px;
    padding: 4.005px 9.997px;
    margin-left: 5px;
    width: 8vw;
}