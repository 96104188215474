body.dark {
  background-color: black;
  color: white;
}
body.light {
  background-color: white;
  color: black;
}
body.dark .data-form-heading-content-left-side {
  color: white;
}
body.dark .dashboard-container {
  background: black;
  color: white;
}
body.light .dashboard-container {
  background-color: white;
  color: black;
}
body.dark div {
  color: white;
}
body.dark th {
  background: black;
  color: white;
}
body.light th {
  background-color: white;
  color: black;
}
body.dark td {
  color: white;
}
body.light td {
  color: black;
}
body.dark label#filter-select-label {
  color: white;
  background-color: black;
}
body.dark
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border: 1px solid white;
}
body.dark
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: white;
}
body.light
  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: black;
}
body.dark input.search-bar {
  background: black;
  color: white;
}
body.dark button.refresh-button {
  background: black;
  color: white;
}
body.dark button.sort-button {
  background-color: black;
  color: white;
}
body.dark .entity-dashboard {
  background: #29292f;
}
body.dark th {
  background: black !important;
  color: white !important;
  /* border: 1px solid whitesmoke; */
}
body.dark
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  background: black;
  border: 1px solid white;
  color: white;
}

body.dark .root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background: gray !important;
}
body.dark .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: gray !important;
}
body.dark .paginations {
  background: black;
}
body.dark span {
  color: white !important;
}
body.dark .dropdown-option select {
  background: black;
  color: white;
  border: 1px solid white;
}
body.dark .arrow-number {
  background-color: black;
  border-radius: 17px;
  color: white;
  border: 1px solid white;
}
body.dark .data-list-scroll-bodys tr:hover {
  background-color: rgb(163, 158, 158);
}
body.dark .invoice-upload-files-schedules {
  background: black;
}
body.dark .form-group-select label {
  color: white !important;
}
body.dark .form-group label {
  color: white;
}
body.dark .footer {
  background-color: rgb(71, 71, 71);
}
body.dark .sort-by-button button {
  color: white;
  background: black;
  border-bottom: 1px solid white;
}
body.dark .sort-by-button button:hover{
  background-color: gray !important;
}
body.dark .sort-by {
  border-bottom: 1px solid white;
}
body.dark .sort-popup-content {
  background-color: black;
  color: white;
}
body.dark .delete-item {
  background-color: darkgray;
  color: whitesmoke;
}
body.dark .new-button {
  background: black;
  border: 1px solid white;
  color: #ffffff;
}
body.dark .css-13cymwt-control {
  background-color: black;
  color: white;
}
body.dark .css-1nmdiq5-menu {
  background-color: black;
  color: white;
}
body.dark .form-group-selectname label {
  color: white;
}
body.dark .form-group-selectname input {
  background-color: black;
  color: white;
}
/* body.dark .css-t3ipsp-control {
  background: black;
  color: white;
} */
body.dark .input-group input {
  background-color: black;
  color: white;
}
body.dark .css-1p3m7a8-multiValue {
  background-color: darkgray;
  color: black;
}
body.dark .change-password-container-content {
  background-color: black;
  color: white;
  border: 1px solid darkgray;
}
body.dark .form-group-popup input {
  background-color: black;
  color: white;
}
body.dark .project-detail {
  box-shadow: 10px 9px 4px -6px rgba(163, 153, 163, 0.72);
}
body.dark input[type="text"] {
  background: black;
  color: white;
}
body.dark .react-datepicker__header {
  background: black;
}
body.dark .react-datepicker__month-container {
  background: black;
}
body.dark .react-datepicker__month {
  background: black;
}
body.dark .input-group textarea {
  background: black;
  color: white;
}
body.dark .data-view-header {
  border-bottom: 1px solid darkgray;
  color: white;
  background: black;
}
body.dark .user-view-body {
  background: black;
}
body.dark .text-medium-header {
  background: black;
  color: white;
}
body.dark .text-medium-body {
  background: black;
}
body.dark .rows label {
  color: darkgray;
}
body.dark .attachment-header {
  background-color: black;
}
body.dark .attach-scrroll-body {
  background: black;
}
body.dark .attach-file-container:hover {
  background-color: rgb(77, 77, 77);
}
body.dark .attach-popup-contant-body {
  background: black;
}
body.dark .inquiry-attach-file-container:hover {
  background-color: rgb(77, 77, 77);
}
body.dark .invoice-upload-files {
  background-color: black;
}
/* Define these styles in your CSS */
/* .tooltip-dark {
  background-color: black;
  color: white;
}

.tooltip-light {
  background-color: white;
  color: black;
}
body.dark .tooltipinfo{
  background-color: black;
  color: white;
} */
body.dark .css-y8tvd1-MuiPaper-root-MuiAlert-root {
  background-color: darkred;
}
body.dark .actions-purchase-sheet-header-content {
  background-color: black;
  color: white;
  border: 1px solid white;
}
body.dark .action-purchase-scroll-body td:hover {
  background: none;
}
body.dark input[type="number"] {
  background: black;
  color: white;
}
body.dark .tab-button {
  background-color: #c3ceff;
  color: black;
}
body.dark .tab-button.active {
  background-color: gray;
  color: white !important;
}
body.dark
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-colorSuccess.MuiAlert-standardSuccess.MuiAlert-standard.css-svzhua-MuiPaper-root-MuiAlert-root {
  background: #506c1f;
  border: 1px solid lightgrey;
}
body.dark .customerpersonpopupform-header {
  background-color: black;
}
.inquiry-customerperson-modal-content {
  background-color: black;
}
body.dark .customerpersonpopupform-heade {
  background-color: black;
  color: white;
}
body.dark .form-group label {
  color: white;
}
.css-13hxqku-control {
  transition: none;
}
.css-vbhje1-control {
  transition: none;
}
body.dark .export-popup-content {
  background: black;
  color: white !important;
  border: 1px solid white;
}
body.dark .css-u4tvz2-MuiFormLabel-root {
  color: white;
}
body.dark .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  color: darkgray !important;
}
body.dark .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1976d2;
}
body.dark
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation0.MuiAlert-root.MuiAlert-colorError.MuiAlert-standardError.MuiAlert-standard.css-x7o5da-MuiPaper-root-MuiAlert-root {
  background: rgb(90, 0, 0);
}
body.dark customerpersonpopup-form {
  background: black;
  color: white;
}
body.dark .form-group textarea {
  background: black;
  color: white;
}
body.dark .customerpersonpopupform-footer {
  background: #0a0000;
}
body.dark .config-tab.selected {
  background-color: #21263c;
}
body.dark .customerpersonpopupform-body {
  background: black;
}
body.dark .form-seperator {
  background-color: gray;
  color: white;
}
body.dark .form-group-top label,
.form-group-top-select label {
  color: white !important;
}
body.dark .frequency-popup-content {
  background-color: black;
  color: white;
}
body.dark .oisd-th th {
  background: gray !important;
}
.css-14h2tre-MuiPaper-root-MuiAlert-root {
  background-color: rgb(90, 0, 0);
}
body.dark .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: gray;
}
tr{
  border-left:1px solid white ;
}
body.dark .modal-container{
  background-color: black;
  color: white;
  border: 1px solid gray;
}
