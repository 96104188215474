.active-status {
  color: rgb(41, 128, 185);
  font-weight: bold;
}
.total-status {
  font-size: 16px;
  font-weight: bold;
}
.complet-status {
  color: #2e8b57 !important;
  font-weight: bold;
}
.hold-status {
  color: #d4ac0d;
  font-weight: bold;
}
.cancelled-status {
  color: #f81700 !important;
  font-weight: bold;
}
.approved-status {
  color: green !important;
  font-weight: bold;
}
.approve-status {
  color: rgb(212, 172, 13) !important;
  font-weight: bold;
}
.rejected-status {
  color: red !important;
  font-weight: bold;
}
.returned-status {
  color: rgb(255, 152, 0) !important;
  font-weight: bold;
}
.non-clickable {
  cursor: not-allowed; /* Change cursor to indicate non-clickable */
}
th.hold{
  color: #d4ac0d !important;
}
th.cancel{
  color: red !important;
}
th.progress-status {
  color: #df7f26 !important;
}
th.overdue-status {
  color: #C0612B !important;
}
th.tenure-status {
  color: #313639 !important;
}
.active-status {
  color: rgb(41, 128, 185) !important;
  font-weight: bold;
}
.progress-status {
  color: #df7f26 !important;
  font-weight: bold;
}
.overdue-status {
  color: #C0612B !important;
  font-weight: bold;
}
.tenure-status {
  color: #313639 !important;
  font-weight: bold;
}
.export-btn {
  background-color: #007bff; /* Primary button color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 5px; /* Rounded corners */
  padding: 5px 14px; /* Padding inside the button */
  font-size: 14px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
  margin-left: 10px;
}

.export-btn:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.export-btn:active {
  background-color: #004085; /* Even darker shade on click */
}












