.body-seprator{
    display: flex;
    align-items: end;
    justify-content: space-between;
    padding: 15px;
}
.dropdown-container-history-content-first{
    width: 16%;
}
.dropdown-container-history-content{
  align-items: center;
  display: flex !important;
  justify-content: center !important;
}
.search-bar-container-history {
    display: flex !important;
    align-items: center;
    justify-content: space-between !important;
    height: 31px;
    border: 1px solid #ddd;
    padding: 2.5px 15px;
    border-radius: 0.25rem;
    width: 25vw;
    z-index: 10000 !important;
}
.dropdown-container-history {
    border: none;
    border-radius: 4px;
    font-size: 13px;
    margin-right: 10px;
    padding: 0;
    width: 300px !important;
    align-items: center;
    display: flex;
    justify-content: center;
}
.dropdown-container-history .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100vw !important;
    z-index: 10000 !important;
   

}
.data-list-heading-content-right-sides{
    margin-right: 15px;
    display: flex;
}
.body-separator{
    height: 130px;
}
.dropdown-container-history .css-13cymwt-control {
    border: 2px solid #d7dbd7 !important;
    border-radius: 20px !important;
    padding: 12px;
    width: 40Vw;
}
.dropdown-container-history .css-t3ipsp-control:focus {
    /* border: 2px solid #d7dbd7 !important; */
    outline: #d7dbd7 !important;
    border-radius: 20px !important;
    padding: 12px !important;
    width: 40Vw !important;
}
.dropdown-container-history .css-1u9des2-indicatorSeparator {
    background: none !important;
}
.dropdown-container-history .css-qbdosj-Input {
    font-weight: 700 !important;
}
.dropdown-container-history .css-t3ipsp-control{
    width: 40vw !important;
    max-width: 40vw !important;
    height: 62.3px !important;
    border-radius: 20px !important;
    padding: 12px !important;  
    border-width: 2px !important;
}
.dropdown-container-history .css-t3ipsp-control:focus{
    padding: 12px !important;  
}
.dropdown-container-history .css-1jqq78o-placeholder{
    font-weight: 200 !important;
    font-size: 18px !important;
}
.dropdown-container-history .css-13cymwt-control:focus {
    border: 1.5px solid #d7dbd7 !important;
}
.dropdown-container-history .css-1nmdiq5-menu{
    width: 100vw !important;
    display: contents !important;
    z-index: 99999 !important;
}
.dropdown-container-history .css-1dimb5e-singleValue {
    font-size: 18px !important;
    font-weight: 700 !important;
}
.attachment-items{
    color: blue;
}

/* Center the dropdown initially */
.dropdown-container-history {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.6s ease;
    z-index: 10000 !important;  /* Make sure dropdown stays above content */
  }
  
  /* Move dropdown to the top with transition */
  .dropdown-container-history.at-top {
    top: 190px;
    left: 60%;
    transform: translate(-50%, 0);
    z-index: 10000 !important;
  }
  
  /* Center the dropdown initially */
  .dropdown-container-history.centered {
    position: absolute;
    left: 60%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000 !important;
  }
  
  /* Add a margin to the body content to make space for the dropdown at the top */
  .data-list-bodys {
    margin-top: 140px !important;
  }
  .data-list-scroll-bodys-history{
    margin-top: 00px;
  }

  /* Container for the page content */
.page-content {
    transition: margin-left 0.3s ease;
  }
  .no-data-containers{
    text-align: center;
    margin-top: 350px !important;
  }

.data-list-table-reports {
    display: flex;
    flex-direction: column;
}
.data-list-table-reports {
    position: relative !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    width: 100%;
    z-index: 1;
    height: 61vh !important;
    margin: 0px !important;
}
.data-list-table-reports {
    position: absolute;
    max-height: 38vh !important;
}
.data-list-table-reports::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
}

.data-list-table-reports::-webkit-scrollbar-track {
    background-color: transparent;
}

.data-list-table-reports::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}
.print-156{
     text-align: end;
}
.print-button{
  margin-right: 10px;
  background: #408DFB;
  border: 1px solid #408DFB;
  padding: 5.005px 9.997px;
  color: white;
  border-radius: 5px;
}