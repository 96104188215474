.inquiry-customerperson-popup-content {
    position: fixed;
    top: 0;
    left: 22%;
    width: 100%;
    height: 100%;
    z-index: 1050;
}

.inquiry-customerperson-modal {
    position: relative;
}

.inquiry-customerperson-modal-content {
    position: relative;
    width: 50%;
    padding: 0px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    z-index: 1080;
}

.customerpersonpopupform-header {
    display: flex;
    justify-content: space-between;
    background-color: #f9f9fb;
    border-bottom: 1px solid #eee;
    padding: 12px 20px;
}

.customerpersonpopupform-title {
    font-weight: 400;
    font-size: 16.9;
}

.customerpersonpopupform-btn {
    cursor: pointer;
    color: red;
    font-size: 1.5rem;
    font-weight: 700;
}

.customerpersonpopup-form {
    padding: 20px;
}

.form-groups {
    display: flex;
    margin-bottom: 5px;
}

.form-groups label {
    width: 20%;
    font-size: 13px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 400;
    display: inline-block;
    color: #212529;
}

.form-groups input {
    width: 100% !important;
    padding: 7px 25px 7px 10px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
    color: #212529;
}

.customerpersonpopupform-footer {
    border-top: 1px solid #ccc;
    bottom: 0%;
    padding: 15px 20px;
}

.customerpersonpopupform-save-button {
    background: #408DFB;
    padding: 5.005px 9.997px;
    border: 1px solid #408DFB !important;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 13px;
}

.customerpersonpopupform-cancel-button {
    background-color: #F5F5F5;
    padding: 5.005px 9.997px;
    border: 1px solid rgb(197, 191, 191);
    border-radius: 5px;
    font-size: 13px;
    color: #212529;
    font-size: 13px;
    margin-left: 10px;
}

.customerpersonpopupform-save-button:hover {
    background-color: #0870df;
}

.customerpersonpopupform-cancel-button:hover {
    background-color: #f3f0f0;
}

.form-group-selected {
    display: flex;
    margin: 10px 0px !important;
}

/* .form-group-selected label {
    width: 20%;
    font-size: 13px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 400;
    display: inline-block;
}

.form-group-selected Select {
    width: 100% !important;
    padding: 9px 0px 8.5px 6px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
} */

#companyNames {
    width: 40.2% !important;
}

.form-group-select-name input {
    margin-left: 10px;
    width: 15% !important;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-select-name {
    display: flex;
}

.form-group-select-name label {
    width: 20%;
    font-size: 13px;
    font-weight: 400;
    display: inline-block;
    color: #212529;
}

.form-group-select-name .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 15.4% !important;
}

.form-group-select-name input:hover {
    border: 1.5px solid #5da0bd !important;
    box-shadow: 0 -4px 5px -3px rgba(67, 89, 131, 0.1);
}

.form-group-select-name input:focus {
    outline: 1.5px solid #5da0bd !important;
}
.form-group-select-name{
    margin-bottom: 10px;
}

.inquiry-customercompany-popup-content {
    height: 100%;
    left: 22%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1050;
}
.inquiry-customercompany-modal {
    position: relative;
}
.inquiry-customercompany-modal-content {
    background: #fff;
    border: 1px solid #0003;
    border-radius: .3rem;
    padding: 0;
    position: relative;
    width: 50%;
    z-index: 1080;
}

form {
    height: auto !important;
}
/* .input-groups {
    width: 35%;
} */
/* #departments,
#EquipmentTypee,
#EquipmentSubTypes,
#EquipmentSubTypess,
#SubDepartment {
    width: 40.2% !important;
} */