.cold-work-table table{
border: 1px solid black !important;
width: 100% !important;
}
.cold-work-table th,
.cold-work-table td{
    background: yellow !important;
    color: black;
    border: 1px solid black !important;
    font-weight: 200 !important;
    font-size: 17px;
}
th.permit-center-align {
    text-align: center !important;
    font-size: 15px;
    /* padding: 15px 15px; */
    padding: 7px 13px;
}
/* th.permit-done {
    width: 7%;
    text-align: center !important;
}
th.permit-not-required {
    width: 8%;
    text-align: center !important;
} */

.permit-1{
    width: 3.8vw;
}
.permit-2{
    width: 31vw;
}
.permit-3{
    width: 6.2vw;
}
.permit-4{
    width: 7.8vw;
}
.permit-center-align{
    text-align: center !important;
}
.cold-work-table td{
    font-size: 16px;
    /* text-align: left !important; */
}
.permit-template6 {
    font-size: 16px !important;
    width: 33vw !important;
}
th.permit-template-clearance{
    text-align: center !important;
    /* font-size: 15px; */
    font-size: 20px !important;
    font-weight: 600 !important;
    /* padding: 15px 15px; */
    padding: 7px 13px;
}
.permit-row td {
    font-size: 20px !important;
    font-weight: 600 !important;
}

.cold-work-table tr:hover {
    background-color: yellow !important;
}
.cold-remark{
    padding: 5px 15px;
}
.underline-bottom{
    border-bottom: 1px solid black;
    padding-bottom: 15px;
}

.cold-work-table td {
    color: #212529;
    /* font-size: 15px !important; */
    font-size: 18.7px !important;
    font-weight: 600 !important;
    /* padding: 5px 8px; */
    padding: 5px 0px 5px 6px;
    text-align: center;
    cursor: pointer;
}

.cold-work-table thead th {
    background-color: yellow;
}
.permit-row{
    height: 12vh;
}
.renewal-permit1{
    width: 8vw;
}
.renewal-permit01{
    width: 14vw;
}
.renewal-permit2{
    width: 14vw;
}
th.permit-center-align {
    text-align: center !important;
    /* font-size: 15px; */
    /* padding: 15px 15px; */
    /* padding: 7px 13px; */
    padding: 7px 10px;
    font-size: 20px !important;
    font-weight: 600 !important;
}
th.permit-closing {
    text-align: left !important;
    /* font-size: 15px; */
    /* padding: 15px 15px; */
    padding: 7px 13px;
    font-size: 20px !important;
    font-weight: 600 !important;
}
.checkmarks{
    font-weight: 600;
    font-size: 18px;
}


  
.pera-flex{
    display: flex;
}

.permit-check {
    appearance: none; /* Remove default browser styling */
    -webkit-appearance: none; /* For Safari */
    border: 2px solid black !important;
    background: yellow;
    /* width: 16px; */
    height: 2vh !important;
    width: 2.5vw !important;
    cursor: pointer;
  }
  
  .permit-check:checked {
    background: yellow;
    border: 2px solid black; /* Optional: Different styling for checked state */
  }
  .permit-row-end {
    height: 15vh !important;
}