.status-color1 {
  font-weight: bold;
}
.status-color2 {
  font-weight: bold;
  color: blue;
}
.status-color3 {
  font-weight: bold;
  color: red;
}
.status-color4 {
  font-weight: bold;
  color: green;
}
.status-color5 {
  font-weight: bold;
  color: rgb(219, 145, 33);
}
.status-color6 {
  font-weight: bold;
  color: rgb(157, 115, 177);
}
.status-schedules {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ss-status {
width: 10vw;
}
.h-btn {
  width: 15%;
}
.attachment-button {
  display: flex;
}
.attachment-button-a {
  color: white !important;
  border: 1px solid #408dfb;
  background: #408dfb;
  border-radius: 5px;
  padding: 5.005px 9.009px;
  margin-left: 10px;
}
.attachment-actions {
  margin-left: 10px;
}
.reort-footer {
  position: fixed !important;
  bottom: 49px !important;
  /* height: 6.5vh !important; */
  /* background-color: rgba(225, 227, 225, 1.00); */
  /* background-color: rgb(236, 225, 225); */
  /* background: rgb(223, 222, 222); */
  background-color: #f8f9fa;
  border-top: 1px solid #eee;
  text-align: center;
  align-items: center;
  z-index: 999;
  border-color: transparent !important;
  /* width: 100% !important; */
  /* max-width: 100%; */
  width: max-content;
  
}
.reort-footer-container {
  /* padding: 15px; */
  /* display: flex; */
  /* justify-content: space-between; */
}
.page-content.expanded .reort-footer {
  width: 82.5%;
}
.page-content.collapsed .reort-footer {
  width: 96% !important;
}



.reort-footer td {
    padding: 12px;
    text-align: left;
    border-top: 2px solid #007bff;
  }
  tfoot {
    position: sticky;
    bottom: 0px;
    background: white;
    z-index: 2;
  }
  .report-footer{
    background-color: #deedfd;
  }
  .tooltip {
    position: relative;
    cursor: pointer;
  }
  
  .tooltip .tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    z-index: 10;
    font-size: 12px;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
  

  /* Container styling */
.status-color1 {
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}
.status-color4{
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
}

/* Info icon styling */

.info-icon {
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.info-icon:hover {
  color: #0056b3;
}

.info-icons {
  color: green;
  cursor: pointer;
  transition: color 0.3s ease;
}

.info-icons:hover {
  color: #75bb75;
}

/* Popup container */
.info-popup {
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 8px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  min-width: 20vw;
  z-index: 100;
  height: 22vh;
  top: 36px;
  left: 30%;
}

.info-popups {
  position: absolute;
  background: white;
  border-radius: 8px;
  padding: 8px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  min-width: 25vw;
  z-index: 100;
  height: 22vh;
  top: 35px;
  right: 80%;
}
.info-popup-content{
  overflow-y: scroll;
  height: 21vh;
}


.info-popup-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.info-popup-content::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.info-popup-content::-webkit-scrollbar-track {
  background-color: transparent;
}
.copied-text{
  color: green !important;
  padding-left: 5px;
}


.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 12px;
}

.popup-header h4 {
  margin: 0;
  font-size: 16px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #ff5a5a;
}

.employee-table {
  width: 100%;
  border-collapse: collapse;
}

.employee-table th,
.employee-table td 
{
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: left;
}

.employee-table th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.employee-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.copy-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
  color: #555;
}

.loading-text {
  text-align: center;
  font-weight: bold;
  color: #555;
}

.no-data {
  text-align: center;
  font-size: 14px;
  color: #888;
}
th.popup-heading-manpower {
    background-color: #eaeaea !important;
    border: none !important;
    
}
.employee-table tbody tr {
  border-bottom: 1px solid #e5e6e8;
  
}
.employee-table thead tr{
  border-radius: 1px !important;
  background: #eaeaea !important;
}

.employee-table thead tr {
  background: #eaeaea !important;
}
.employee-table td{
  color: #212529 !important;
  font-size: 13px !important;
  font-weight: 500;
}

.employee-table thead tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.employee-table thead tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.info-popup-content{
  /* padding: 5px 0 10px 0; */
}
.info-popup-content-header{
  color: black;
  font-weight: bold;
  /* padding: 5px 0 10px 0; */
  padding: 5px 0;
  font-size: 15px;
}













