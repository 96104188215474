.pr-form {
    padding: 25px;
    box-shadow: 0px 0px 5px #00000025;
    border-radius: 15px;
    margin-bottom: 25px;
}
.quotation-history h2 {
    font-size: 22px;
    font-weight: 400;
}
.quotation-history .attachment-item{
    justify-content: left;
    background: transparent;
    padding: 0px;
}
.quotation-history .attachment-item a{
    display: flex;
    align-items: center;
    justify-content: center;
}
.quotation-history .attachment-item span{
    color: blue !important;
}


.data-form-bodys{
    padding: 17px 20px;
    height: 40vh !important;
    overflow-y: scroll;
}
.data-form-bodys::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

.data-form-bodys::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
}

.data-form-bodys::-webkit-scrollbar-track {
    background-color: transparent;
}
.message-show{
    text-align: center;
    width: 64vw;
}

.css-1nmdiq5-menu{
    z-index: 9999 !important;
    /* height: 15vh !important;
    overflow-y: auto !important;
    visibility: visible !important; */
}
.attchment-file-lists{
    color: blue;
}
.remove-button{
    background-color: #F5F5F5;
    /* padding: 5.005px 9.997px; */
    padding: 5px  5px;
    border: 1px solid rgb(197, 191, 191);
    border-radius: 5px;
    font-size: 13px;
    color: #212529;
    font-size: 13px;
    margin-left: 5px;
}
.tables thead th {

    background-color: white;
    color: black;

}

.pr-vendor-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    /* box-shadow: 0px 0px 10px gray; */
    border-radius: 5px;
    z-index: 9999;
  }
  .pr-vendor-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .pr-vendor-popup-content button {
    margin: 5px;
    padding: 8px 16px;
    cursor: pointer;
  }
  .final-vendor-button{
    display: flex;
  }
  .pr-vendor-popup-container{
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    /* background-color: #4443431a; */
    background: #4443431a !important;
    z-index: 1000;
  }
  .quotation-row {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    border-bottom: 1.5px solid #ebeaf2;
}

.prRequest-popup-vendor{
    position: absolute;
    z-index: 999 !important;
    top: 66%;
    right: 5%;
    background: #fff;
    border: 1px solid #ccc;
}