.tabs button {
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

/* .tab-content div {
  margin-top: 20px;
} */
.new-button.disabled {
  background-color: #d6d6d6 !important; /* Gray for disabled */
  border: 1px solid #d6d6d6 !important;
  color: #999 !important;
  cursor: not-allowed !important;
  pointer-events: none; /* Disable all interactions */
}
.hidden-pdf-content {
  /* visibility: hidden; */
  /* position: absolute; */
}
.span.tab-count {
  color: red !important;
}
/* .permit-buttons {
  display: flex;
  justify-content: space-between;
} */

/* .new-permit .new-button{
  padding: 2.005px 9.997px;
} */

.edit-req-but{
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-actions {
  margin-top: 20px;
}

.confirm-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 4px;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}
.dnld-btn{
  font-size: 15px;
  font-weight: bold;
  vertical-align: sub;
}
