.export-popup-overlay {
  position: absolute;
  top: 175px;
  right: 35px;
  background: rgba(0,0,0,0.1); 
  padding: 1.5px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  z-index: 1300 !important;
}

.export-popup-content {
  background: #fff;
  padding: 10px 15px;
  border-radius: 8px;
  width: 200px;
  /* position: relative; */
}

/* .export-popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
} */
.export-header{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.export-popup-close{
  cursor: pointer;
  border: none;
  color: red; 
  background: none;
  font-size: 16px;
}
.header-name{
  font-size: 16px;
  font-weight: bold;
}
.format-group{
  padding: 10px 0; 
}