.header {
  /* background-color: #21263c; */
  /* background-color: #f5f5f7; */
  background-color: transparent;
  color: #ffff;
  font-family: "Inter", sans-serif;
  height: auto;
  border-bottom: 1px solid #e4e4e4;
  /* position: fixed; */
  /* width: 100%; */
  /* height: 48px */
}
.header.collapsed {
  margin-left: 50px !important;
}
.header.expanded {
  margin-left: 250px !important;
}
.header-layout {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-wrapper {
  /* position: relative; */
  /* display: flex; */
  /* grid-template-columns: repeat(3, 1fr); */
  /* justify-content: space-between; */
  width: 100%;
  align-items: center;
  text-align: center;
}

/* .heading-start {
  justify-content: flex-start;
  display: flex;
  align-items: center;
} */

.header-logo {
  width: auto;
  height: 45px;
  filter: brightness(white) !important;
}
.username {
  display: flex;
  align-items: center;
  padding: 4px 7px;
  margin-right: 5px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}
.username span {
  font-size: 17px;
  color: black;
  margin-left: 4px;
}
.username-letter {
  margin: 0px;
}

.heading-end {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #f0f0f0;
}

.letter-background {
  /* background-color: #2a9d90;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border: none;
  margin-right: 5px;
  cursor: pointer;
  padding: 1px 1.5px; */

    /* vertical-align: middle; */
    /* padding: 5.005px 9.997px;
    border: 1px solid #9ce0e4;
    background-color: #9ce0e4 ;
    color: white;
    font-weight: 400;
    border-radius: 0.462rem; */



    background: #2a4286;
    border: 1px solid #ddd;
    border-radius: 50%;
    color: #fff !important;
    font-weight: 400;
    height: 36px;
    margin-right: 5px;
    vertical-align: middle;
    width: 36px;
    font-size: 13px;
}

.username-letter {
  /* margin: 0px 6px 0 6px; */
}

.first-letter {
  /* padding: 5.5px 8.5px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.fullscreen-toggle {
  /* background: white;
  border: 1px solid #ddd;
  color: gray !important;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  cursor: pointer; */

    /* vertical-align: middle;
    margin-right: 5px;
    padding: 5.005px 9.997px;
    border: 1px solid #ddd;
    color: #212529;
    font-weight: 400;
    border-radius: 0.462rem; */

     border: 1px solid #ddd;
  border-radius: 50%;
  color: #212529 !important;
  font-weight: 400;
  height: 36px;
  margin-right: 5px;
  vertical-align: middle;
  width: 36px;
  font-size: 13px;
  background: none;

}

.fullscreen-icon {
  font-size: 18px;
  vertical-align: sub;
  /* padding-top: 3px; */
}
.theme-switchs {
  /* background: white;
  border: 1px solid #ddd;
  color: gray !important;
  border-radius: 50%;
  width: 32px;
  height: 32px; */

    vertical-align: middle;
    padding: 5.005px 9.997px;
    border: 1px solid #ddd;
    /* background-color: #f5f5f5; */
    color: #212529;
    font-weight: 400;
    border-radius: 0.462rem;
    margin-right: 5px;
}
.sun {
  color: #e67e22;
}
.icon {
  font-size: 20px;
}
.icon {
  /* padding-top: 3px; */
  vertical-align: sub;
}
.notification {
  padding: 0 8px;
  padding-top: 4px !important;
  cursor: pointer;
  margin-left: 6px;
  font-size: 18px;
}

.logout {
  background-color: #21263c;
  font-size: 15px;
  color: white;
  font-size: 16px;
  border: 1px solid silver;
  /* border: none; */
  padding: 8px 11px;
  margin-right: 5px;
  border-radius: 22px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
}

.logout:hover {
  background-color: rgb(48, 57, 107);
}
.setting {
  padding: 0 8px;
  padding-top: 4px !important;
  cursor: pointer;
  margin-right: 6px;
  font-size: 18px;
}

.notification:hover {
  background-color: #333850;
  border-radius: 6px;
}

.setting:hover {
  background-color: #333850;
  border-radius: 6px;
}

.heading-end-first,
.heading-end-center {
  border-right: 1px solid gray;
  display: flex;
}

.heading-end-last {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
  
}

.heading-end-first p {
  margin-right: 5px;
}

.header-profile-popup {
  position: fixed;
  top: 6.8%;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Use rgba to set opacity */
  z-index: 1000;
}
.logout svg {
  color: white !important;
  font-size: 18px !important;
  background: #408df8 !important;
  padding: 4px 6px !important;
  border-radius: 50px !important;
}
.log-out{
  /* padding: 5.005px 9.009px;
  background: #444ED0;
  border-radius: 5px;
  margin-left: 5px;
  color: white; */

  /* vertical-align: middle;
  padding: 5.005px 9.997px;
  border: 1px solid #ddd;
  color: #212529;
  font-weight: 400;
  border-radius: 0.462rem;
  cursor: pointer; */

  border-radius: 50%;
  color: #212529 !important;
  font-weight: 400;
  height: 36px;
  margin-right: 5px;
  vertical-align: middle;
  width: 36px;
  font-size: 13px;
  background: none;
  border: 1px solid #ddd;
}
.log-out-icon{
  vertical-align: sub;
  font-size: 18px;
}
.notification-header{
  /* padding: 5.005px 9.009px;
  background: #444ED0;
  border-radius: 5px;
  margin-left: 5px;
  color: white; */

  /* vertical-align: middle;
  padding: 5.005px 9.997px;
  border: 1px solid #ddd;
  color: #212529;
  font-weight: 400;
  border-radius: 0.462rem;
  cursor: pointer;
  margin-right: 5px; */
  cursor: pointer;
  position: relative;

  border: 1px solid #ddd;
  border-radius: 50%;
  color: #212529 !important;
  font-weight: 400;
  height: 36px;
  margin-right: 5px;
  vertical-align: middle;
  width: 36px;
  font-size: 13px;
  background: none;
  
}
.notification-header-icon{
  vertical-align: sub;
  font-size: 18px;
}



/* .unread-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: -5px;
  animation: blink 1s infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-header {
  position: relative;
  cursor: pointer;
}

.notification-header-icon {
  font-size: 24px;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}


 */






 .unread-count {
  background-color: #ff6b6b; /* Lighter shade of red */
  color: white;
  border-radius: 50%;
  /* padding: 2px 6px; */
  font-size: 9px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 1.5s infinite;  /* Pulse animation */
  width: 20px;
  height: 20px;
}

.notification-header {
  position: relative;
  cursor: pointer;
}

.notification-header-icon {
  /* font-size: 24px; */
}

/* Pulse animation (like heartbeat effect) */
/* @keyframes pulse {
  0% {
    transform: scale(1);  
    box-shadow: 0 0 0 0 rgba(253, 144, 144, 0.3);  
  }
  50% {
    transform: scale(1);  
    box-shadow: 0 0 12px 12px rgba(236, 151, 151, 0.5);  
  }
  100% {
    transform: scale(1);  
    box-shadow: 0 0 0 0 rgba(253, 144, 144, 0.3); 
  }
} */

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(253, 144, 144, 0.3);
  }
  50% {
    transform: scale(1);
    box-shadow: 0 0 6px 6px rgba(236, 151, 151, 0.4); /* Reduce spread */
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(253, 144, 144, 0.3);
  }
}


