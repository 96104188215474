.custom-pagination {
    position: fixed;
    bottom: 0px;
    width: 90%;
    background: white;
    border-top: 1px solid #eee;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 9;
    font-size: 13px;
    padding: 4px 0;
  }
  
  .custom-arrow {
    background: none;
    border: none;
    padding: 8px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    font-size: 16px;
    color: #555;
  }
  
  .custom-arrow:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  
  .custom-arrow:hover:not(:disabled) {
    background-color: #f0f0f0;
    color: #007bff;
  }
  
  .custom-prev {
    margin-right: 10px;
  }
  
  .custom-next {
    margin-left: 10px;
  }
  
  .custom-page-info {
    margin: 0 15px;
    font-size: 14px;
    color: #333;
  }
  