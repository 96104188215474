/* Karan Custom CSS */
.new-frequency-row .add-new-button{
    margin: 0px;
    width: 75px;
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.new-frequency-row .add-new-button svg{
    font-size: 17px;
}
.frequency-row{
    margin-top: 15px !important;
}
.frequency-row:first-child{
    margin-top: 0px !important;
    width: 300px !important;
}
.new-frequency-row {
  display: flex;
  flex-direction: column;
  width: 29% !important;
}
.textarea{
  width: 85% !important;
}
.add-new-buttons {
    background-color: #007bff; /* Blue background color */
    color: #fff; /* White text color */
    border: none; /* No border */
    padding: 5.005px 9.997px; /* Padding around the text */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show pointer cursor on hover */
  }
  
  .add-new-buttons:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  .add-new-frequency-customer{
    display: flex;
    justify-content: end;
    margin-top: 10px !important;
    margin-right: 66px;
  }
  .add-new-frequency{
    display: flex;
    justify-content: center;
    margin-top: 10px !important;
    margin-right: 50px;
  }
  .add-close-button{
   background: none;
    color: #e91e1e; /* White text color */
    border: none; /* No border */
    padding: 0px 0px; 
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Show pointer cursor on hover */
  }
  .frequency-plus-icon{
    font-size: 16px;
    vertical-align: sub;
}
  .frequency-close-icon{
    font-size: 16px;
    vertical-align: super !important;
    padding-bottom: 18px !important;
    margin-left: 5px;
  }
  .new-frequency-rows {
    display: flex;
    flex-direction: column;
    width: 30% !important;
}
  
.location-group{
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  width: 935px;
}
.frequency-row textarea {
  border: 1.5px solid #ccc;
  border-radius: 4px;
  padding: 7px 25.5px 7px 10px;
  width: 97% !important ;
}
.location-group-content{
  display: flex;
}
.tabs-container {
  
  border-radius: 4px; /* Optional: for rounded corners */
}

.config-tab {
  border: none; /* Remove default border */
}

.config-tab.selected {
  border: 1px solid #ddd; /* Border for the selected tab */
  border-radius: 4px 4px 0 0; /* Optional: rounded corners on top */
  padding: 8px 16px; /* Optional: adjust padding to match the content */
  background-color: #f9f9f9; /* Optional: background color for selected tab */
}
.data-list-contentss{
  border-bottom: 1px solid #ccc;
  width: 35vw !important;
  /* padding: 10px; */
}
.css-1ujnqem-MuiTabs-root {

  justify-content: space-between !important;
}
.frquency-type{
  padding: 10px 0px;
}
textarea.point-fr.place-businees {
  width: 694px !important;
}
.input-group.frequency-row {
  width: 318px !important;
}
.input-group1 {
  width: 27.5% !important;
  display: flex;
}