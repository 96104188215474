/* Popup background */
.add-popup {
  position: absolute;
  z-index: 999 !important;
  top: 84%;
  right: 10%;
  background: #fff;
  width: 10vw;
  border: 1px solid #ccc;
  z-index: 9999;

  }
  .view-button{
    position: relative;
  }
  
  /* Popup content */
  .add-popup-content {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Popup button */
  .popup-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5.005px 9.009px;
    margin: 8px 0;
    width: 100%;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .popup-button:hover {
    background-color: #45a049;
  }
  .email-icon{
    vertical-align: middle;
    font-size: 18px;
  }