
.a-th1{
width: 3vw;
}
.a-th2{
    width: 5vw;  
}
.a-th3{
    width: 5vw;  
}
.a-th4{
    width: 3.5vw;  
}
.a-th5{
    width: 10vw;  
}
.a-th6{
    width: 5vw;  
}
.a-th7{
    width: 1vw;  
}
.audit-td input{
    /* width: 5vw; */
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    height: 100%;
}
.audit-td .react-datepicker-wrapper{
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex
;
}
.audit-td .react-datepicker-wrapper input{
    border: none !important;
    border-radius: 5px;
    padding: 10px 1px;
    height: 100%;
}
.audit-td .react-datepicker-wrapper input:hover{
    border: none !important;
    border-radius: 5px;
    padding: 10px 1px;
    height: 100%;
}
.audit-td .react-datepicker-wrapper input:focus{
    /* border: none !important; */
    outline: none !important;
    border-radius: 5px;
    padding: 10px 1px;
    height: 100%;
}
.audit-td textarea {
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    padding: 0 !important;
    /* padding: 7px 25.5px 7px 10px; */
    /* width: 100% !important; */
}
.polist-popup1-audit{
    position: fixed;
    z-index: 999 !important;
    top: 52%;
    right: 5%;
    background: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
    width: 15%;
}
.project-details-left-side-audit {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
}
.action-purchase-scroll-bodys-audit input {
    width: 98%;
    /* border: none !important; */
    border: 1px solid #408dfb !important;
    height: 100%;
    border-radius: 5px;
    text-align: center;
    /* padding: 0 0; */
}
.action-purchase-scroll-bodys-audit input:hover {
    
    border: 1px solid #408dfb !important;
}
.action-purchase-scroll-bodys-audit input:focus {
    
    outline: 1px solid #408dfb !important;
}
.action-purchase-scroll-bodys-audit td {
    border: 1px solid #ebeaf2;
    padding: 5px 3px;
    height: 40px;
    text-align: center !important;
}.a-th1 .css-b62m3t-container{
    position: relative;
    box-sizing: border-box;
    width: 100.3% !important;
    border: 0.8px solid #408dfb !important;
    border-radius: 5px;
}
.audit-td-select input  {
    border: none !important;

}
.audit-td-select input:focus {
    outline: none !important;

}
.error-fields .react-datepicker-wrapper {
    border: 1px solid red !important;
    border-radius: 5px;
    height: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex
;
}
.error-fields input {
    border: none !important;
}

.error-field-select .css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
    width: 100.3% !important;
    border: 0.8px solid red !important;
    border-radius: 5px;
}
.error-field-select input{
border: none !important;
}