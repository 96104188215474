.action-purchase-sheet-header {
  /* border-top: 1px solid ; */
  background-color: #f9f9fb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 1px solid #ebeaf2;
  margin: 0px 20px !important;
}

.actions-purchase-sheet-header-content {
  display: flex;
  justify-content: space-between;
}
.actions-purchase-sheet-heading-content-left-side {
  padding: 15px 0px;
  margin-left: 15px;
  font-weight: 600;
  font-size: 16px;
}
.action-purchase-header {
  padding: 5px 25px;
}

.actions-purchase-heading-content-right-side {
  padding: 15px 0px;
  margin-right: 15px;
}

.action-purchase-heads th {
  background: white !important;
  border: 1px solid #ebeaf2 !important;
  /* color: #222222 !important; */
  padding: 8px 0px;
  text-align: center !important;
}

.action-purchase-heading {
  padding: 20px;
}

.action-purchase-heading-content {
  display: flex;
  justify-content: space-between;
}

.action-purchase-heading-content-left-side {
  color: #212529;
  font-size: 22px;
}
.action-purchase-scroll-bodys td {
  border: 1px solid #ebeaf2;
  padding: 5px 3px;
  height: 40px;
  text-align: center !important;
}

.action-purchase-scroll-body td:hover {
  background: #f9f9fb;
}

.action-purchase-scroll-bodys input {
  width: 98%;
  border: none !important;
  height: 100%;
  border-radius: 5px;
  text-align: center;
  padding: 0 0;
}

.action-purchase-scroll-bodys input:hover {
  width: 98%;
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
}

.action-purchase-heads tr {
  border-top: none !important;
}

.action-table tr {
  border: none !important;
  /* width: 58% !important; */
}

.action-purchase-scroll-bodys td {
  border: 1px solid #ebeaf2;
}

.action-purchase-table {
  position: relative !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
}

.action-purchase-table {
  position: absolute !important;
  height: 64vh;
  padding-bottom: 30px !important;
  padding: 0px !important;
  /* width: 82% !important; */
  /* margin: 0px 20px !important; */

  text-align: center;
  display: contents;
}

.action-purchase-table table {
  border-collapse: collapse;
  display: table;
  border-spacing: 2px;
  border-color: gray;
  color: #212529;
  width: 80vw !important;
}
td.th2{
  color: blue;
}
/* td.inward{
  color: green;
} */
td.inward{
  color: green;
}
.action-purchase thead th {
  position: sticky !important;
  top: -2 !important;
  background-color: white;
  z-index: 1;
}

.th2 {
  width: 2% !important;
}
.th3 {
  width: 2% !important;
}
.th4 {
  width: 2% !important;
}
.th5 {
  width: 2% !important;
}
.th6 {
  width: 2% !important;
}
.th1 .css-b62m3t-container,
.th2 .css-b62m3t-container,
.th3 .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 100.3% !important;
  border: 0.8px solid #408dfb !important;
  border-radius: 5px;
}
.item-action-icon {
  cursor: pointer;
  font-size: 1.4em;
  font-weight: bold !important;
  padding: 0.1em;
  border-radius: 50%;
  color: white;
  margin-top: 11px !important;
  margin-left: 10px;
}

.item-action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.save-icon {
  color: #28a745;
  font-size: 1.1em !important;
  margin-top: 14px !important;
}

.cancel-icon {
  color: #dc3545;
  font-size: 1.3em !important;
  margin-top: 14px !important;
  /* Red color */
}

.item-delete-icons {
  color: rgb(228, 69, 69);
  font-size: 1.1em;
  border-radius: 50%;
  cursor: pointer;
  padding: 0.3em;
}
.add-row-icon {
  background-color: #408dfb;
  color: white;
  border-radius: 50%;
  vertical-align: text-top;
  font-size: 14px;
}
.action-caluculation {
  margin: 15px 0px 5px 0px !important;
  padding: 0 0px;
  width: 60%;
}

.add-rows {
  color: #212529;
  padding: 5.005px 9.997px !important;
  background-color: #f9f9fb;
  border: none;
}

.th3 input,
.th4 input,
.th5 input {
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  /* -webkit-appearance: none; */
  margin-top: 1px;
  border: 1px solid greenyellow;
}

.th3 input:hover,
.th4 input:hover,
.th5 input:hover {
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
}

.th3 input:focus,
.th4 input:focus,
.th5 input:hover {
  outline: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
}
.delete-row {
  color: white;
  background: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px 9.997px;
  font-size: 13px;
  margin-left: 5px;
}
.action-purchase-body {
  margin: 0px 20px !important;
}
th.total {
  color:  blue !important;
}
th.quantity {
  color: #4caf50 !important;
}
th.pending {
  color: #d4ac0d !important;
}
th.rejected{
    color: red !important;
}
td.total {
    color: blue;
    font-weight: 600;
}
td.quantity {
    color: #4caf50;
    font-weight: 600;
}
td.rejected{
    color: red;
    font-weight: 600;
}