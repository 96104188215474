th.total{
    color: blue !important;
}
.total, .totals{
    color: blue !important;
}
th.quantity{
    color: green !important;
}
td.quantity{
    color: green !important;
}
th.rejected{
    color: red !important;
}
td.rejected{
    color: red !important;
}
th.balance{
    color: darkmagenta !important;
}
td.balance{
    color: darkmagenta;
}
