
.add-new-button {
    background: #408DFB;
    /* border: 1px solid #408DFB; */
    border: none;
    display: inline-block;
    /* font-weight: 400; */
    color: #FFFFFF;
    /* line-height: 1.6; */
    padding:s !important;
    border-radius: 0.25rem;
    margin-left: 10px;
    height: 36px;
}
.frequency-row{
    display: flex;
    width: 100% !important;
}
.frequency-row input{
width: 30%;
}
.point-fr{
    width: 30% !important;
    margin-right: 10px;
}
.new-frequency-row{
display: flex;
flex-direction: column;
width: 32%;
}