/* * {
    margin-top: 0 !important;
  } */

.person-user-container {
  background-color: white;
  position: absolute;
  top: 6.9%;
  overflow: hidden;
  width: 87%;
}

.data-list-content-container {
  transition: all 0.3s;
}

.data-list-header-content {
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
}

.data-list-heading-content-left-side {
  color: #212529;
  font-size: 22px;
  font-weight: 500;
}

.data-list-heading-content-right-side {
  display: flex;
}

.data-list-scroll-body {
  height: 76vh !important;
}

.data-list-table {
  display: flex;
  flex-direction: column;
}

.data-list-table {
  position: relative !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  width: 100%;
  height: 74vh !important;
  margin: 0px !important;
}

.data-list-table {
  position: absolute;
  max-height: 76vh !important;
}

.data-list-table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.data-list-table::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.data-list-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.data-list-scroll-bodys tr:hover {
  background-color: #f1f1f1;
}

.hoverable-row {
  position: relative;
}
.hoverable-row:hover .edit-data-button {
  visibility: visible;
}

table {
  border-collapse: collapse;
  display: table;
  border-spacing: 2px;
  /* border-color: gray; */
  border-color: transparent !important;
  color: #212529;
  width: 100% !important;
  max-width: 100%;
}

thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
  /* border-top: 1.5px solid #ebeaf2; */
}

th {
  /* position: sticky !important; */
  top: -1px;
  z-index: 1;
  /* border-top: 1px solid #ebeaf2; */
  border-bottom: 1px solid #e4e4e4 !important  ;
  color: #757383;
  font-size: 11px;
  padding: 8px;
  letter-spacing: 0.7px;
  font-weight: 600;
  text-align: left !important;
}

td {
  color: #212529;
  font-size: 13px;
  padding: 12px 8px;
  text-align: left !important;
  cursor: pointer;
}

.table thead th {
  position: sticky;
  top: -2 !important;
  background-color: white;
  /* background: transparent !important; */
  color: black;
  z-index: 1;
}

.new-button {
  background: #408dfb;
  border: 1px solid #408dfb;
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.6;
  padding: 5.005px 9.997px;
  border-radius: 0.25rem;
  margin-left: 10px;
}

.new-button a {
  color: #ffffff;
}
.new-button:hover {
  background-color: #0870df;
}

.new-plus-icon {
  vertical-align: text-top !important;
  margin-top: 1.1px;
}

.expanded {
  width: calc(100% - 250px) !important;
  /* Adjust the width based on your needs */
}

.collapsed {
  width: calc(100% - 50px) !important;
  /* Adjust the width based on your needs */
}
.data-list-body {
  margin: 10px;
  /* padding: 2px; */
  border: 1px solid #e4e4e4 !important;
  /* border-left: 1px solid #e4e4e4 !important;;
    border-right: 1px solid #e4e4e4 !important;; */
  border-radius: 5px;
}

.no-users-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Adjust height as needed */
  border-top: 1px solid #ebeaf2 !important;
  box-shadow: 0 -4px 5px -3px rgba(0, 0, 0, 0.1) !important;
}

.no-users-message {
  font-size: 18px;
  color: #666;
  text-align: center;
}

.edit-data-button {
  visibility: hidden;
  border: none;
  background: none;
}

.edit-button {
  background: #408dfb;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 16px;
}

.edit-popup {
  position: absolute;
  top: 69.5%;
  right: 1%;
  background-color: white;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-popup-button button {
  border: none;
  background: white;
  border-radius: 6px !important;
  padding: 5.005px 9.997px !important;
}

.edit-popup-button button:hover {
  border: none;
  background: #408dfb;
  color: white;
  border-radius: 6px !important;
  padding: 10px;
}

.action-header {
  padding: 16px 10px;
}

.action-header-content {
  display: flex;
  justify-content: space-between;
}

.print,
.bulk-update,
.delete {
  background-color: #f5f5f5;
  color: #212529;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 5.05px 9.997px;
  margin-left: 10px;
}

.print:hover,
.bulk-update:hover,
.delete:hover {
  background-color: #f3f0f0;
}

.print-icon,
.delete-icon {
  vertical-align: text-bottom !important;
}

.delete-popup {
  position: absolute;
  top: 14.3%;
  left: 24.5%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.delete-popup-content {
  padding: 4px;
}

.delete-popup-content button {
  display: block;
  width: 100%;
  padding: 7px 10px;
  clear: both;
  font-weight: 400;
  color: #333850;
  text-align: inherit;
  white-space: nowrap;
  background-color: #00000000;
  border: 0;
}

.delete-popup-content button:hover {
  background: #408dfb;
  color: #ffffff;
  border-radius: 6px;
}

.confirm-delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.confirm-delete-popup-content {
  position: fixed;
  top: 0;
  left: 33%;
  width: 100%;
  height: 100%;
  z-index: 1050;
}

.confirm-model {
  position: relative;
}

.confirm-model-content {
  position: relative;
  width: 35%;
  padding: 0px;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  z-index: 1080;
  font-size: 13px;
}

.confirm-model-content-body {
  padding: 20px;
}

.confirm-model-content-footer {
  padding: 20px;
  border-top: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.confirm-delete-button {
  background-color: #408dfb;
  color: #fff;
  border: 1px solid #408dfb;
  margin: 0px 3.25px 0px 0px;
  padding: 5.005px 9.99px;
  border-radius: 7.392px;
}

.confirm-cancel-button {
  background-color: #f5f5f5;
  color: #212529;
  border: 1px solid#ddd;
  margin: 0px 0px 0px 3.25px;
  padding: 5.005px 9.99px;
  border-radius: 7.392px;
}
input[type="checkbox"] {
  cursor: pointer;
  width: 14px;
  height: 14px;
  border: 1px solid #00000040;
  border-radius: 3px;
}
input[type="radio"]:hover:enabled {
  border-color: #408dfb;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(64, 141, 251, 0.16);
}
.action-header-close-button {
  background: none;
  border: none;
  font-size: 16px;
}

.action-header-close-icon {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  margin-top: 5px;
  text-shadow: 0 1px 0 #fff;
}

.sort-button {
  margin-left: 10px;
  vertical-align: middle;
  padding: 5.005px 9.997px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  color: #212529;
  font-weight: 400;
  border-radius: 0.462rem;
}

.sort-button:hover {
  background-color: #f3f0f0;
}

.sort-icon {
  vertical-align: middle;
  font-size: 16px;
}

.sort-popup-container {
  position: absolute;
  top: 15%;
  right: 1.5%;
  background-color: white;
  border: 1px solid #ebeaf2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 999;
  text-align: left;
  width: 14% !important;
  border-radius: 8px;
}

.sort-popup-content {
  display: inline-block !important;
  padding: 4px;
  min-width: 95% !important;
}

.sort-by {
  color: #838195;
  min-width: 10%;
  color: #838195;
  padding: 7px 16px 7px 8px;
  font-size: 12px;
}

.sort-by-button button {
  display: inline-block !important;
  margin: 2px 0;
  border: none;
  color: #333850;
  background: white;
  text-align: left;
  width: 100% !important;
  padding: 7px 20px 7px 10px;
}

.sort-by-button button:hover {
  display: inline-block !important;
  margin: 2px 0;
  border: none;
  background: #408dfb;
  color: white;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  padding: 7px 20px 7px 10px;
}

.delete-item {
  display: inline-block !important;
  margin: 0px 0;
  border: none;
  color: #333850;
  background: white;
  text-align: left;
  width: 100%;
  padding: 7px 20px 7px 10px;
  border-top: 1px solid #ebeaf2;
}

.delete-item:hover {
  display: inline-block !important;
  margin: 2px 0;
  border: none;
  background: #408dfb;
  color: white;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  padding: 7px 20px 7px 10px;
}

.search-bar-container {
  border: 1px solid #ddd;
  padding: 2.5px;
  border-radius: 0.25rem;
}
.search-bar-container {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  padding: 2.5px 5px;
  /* width: 18vw; */
  justify-content: space-between;
}
.search-bar {
  border: none;
  font-size: 16px;
}

.search-bar-icon {
  vertical-align: text-bottom;
  color: #aca6a6;
  /* margin-bottom: 1px; */
}

.search-bar:focus {
  outline: none !important;
}

.refresh-button {
  margin-left: 10px;
  vertical-align: middle;
  padding: 5.005px 9.997px;
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  color: #212529;
  font-weight: 400;
  border-radius: 0.462rem;
}

.refresh-button:hover {
  background-color: #f3f0f0;
}

.refresh-icon {
  vertical-align: middle;
  font-size: 16px;
}
.no-users-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  border-top: 1px solid #ebeaf2 !important;
  box-shadow: 0 -4px 5px -3px rgba(0, 0, 0, 0.1) !important;
}

.no-users-message {
  font-size: 18px;
  color: #666;
  text-align: center;
}

.view-data {
  color: #408dfb;
  text-decoration: underline;
}
.action-buttons {
  display: flex;
}

.instock {
  color: #549d06 !important;
  font-weight: bold;
}

.instock-1 {
  color: #960000 !important;
}

.instock-2 {
  color: #0f0066 !important;
}

.instock-3 {
  color: #004d85 !important;
}

.instock-4 {
  color: #007d13 !important;
}

.instock-product1 div {
  color: #960000;
  border: none !important;
  background: #ffd9d9;
  border-radius: 50px;
  border-radius: 20px;
  padding: 5.005px 9.909px;
  font-weight: bold;
  width: 70px !important;
  /* max-width: 70px !important; */
  /* display: block !important; */
}

.instock-product2 div {
  color: #0f0066;
  border: none !important;
  background: #e0daff;
  border-radius: 20px;
  padding: 5.005px 9.909px;
  font-weight: bold;
  width: 70px !important;
  /* display: block !important; */
}

.instock-product3 div {
  color: #004d85;
  border: none !important;
  background: #daefff;
  border-radius: 20px;
  padding: 5.005px 9.909px;
  font-weight: bold;
  width: 70px !important;
  /* display: block !important; */
  /* max-width: 70px !important; */
}

.instock-product4 div {
  color: #007d13;
  border: none !important;
  background: #daffe0;
  border-radius: 20px;
  padding: 5.005px 9.909px;
  font-weight: bold;
  width: 70px !important;
  /* display: block !important; */
}

/* .instock-product4 {
    background: #DAFFE0;
    border-radius: 20px;
    width: 70px !important;
{
} */

.instock-1,
.instock-2,
.instock-3,
.instock-4 {
  text-align: left;
}

.generate-scheduler-button {
  background-color: #4caf50; /* Green background */
  border: none; /* No borders */
  color: white; /* White text */
  padding: 5px 12px; /* Top and bottom padding, Left and right padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* No underline */
  display: inline-block; /* Inline block element */
  font-size: 14px; /* Text size */
  margin: 4px 2px; /* Margin around the button */
  cursor: pointer; /* Pointer/hand icon */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* Box shadow */
  transition-duration: 0.4s; /* Transition time */
  width: 12vw;
}

.generate-scheduler-button:hover {
  background-color: #45a049; /* Darker green background on hover */
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
.hover-icon {
  margin-left: 3px;
  vertical-align: middle;
  font-size: 16px;
  /* color: rgb(122, 122, 25); */
  color: #408dfb;
}
/* .collapsed {
    width: calc(100% - 50px) !important;
} */
.collapsed {
  margin-left: 50px;
}
.expanded {
  margin-left: 250px;
}
.main-container .page-contentcollapsed {
  margin-left: 50px;
}
.main-container .page-contentexpanded {
  margin-left: 250px;
}
.page-content.expanded {
  margin-left: 250px;
}

.page-content.collapsed {
  margin-left: 50px;
}

.page-content.collapsed .paginations {
  width: 96% !important;
}
.page-content.expanded .paginations {
  width: 82.5%;
}
.address-td {
  width: 25% !important;
}
.ponumber-th {
  width: 15% !important;
}
.file-footer a {
  color: blue !important;
}
.input-groups a {
  color: blue !important;
  text-decoration: underline;
}
.document-button {
  background-color: blue;
  border: none;
  color: white;
  padding: 5px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  /* margin: 4px 2px; */
  cursor: pointer;
  border-radius: 8px;
}
.center-alignment {
  text-align: center !important;
}
.new-button-access {
  width: 9vw;
  background: #408dfb;
  border: 1px solid #408dfb;
  display: inline-block;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.6;
  padding: 5.005px 9.997px;
  border-radius: 0.25rem;
  margin-left: 10px;
}
.info-icon {
  margin-left: 5px;
  cursor: pointer;
  color: gray;
  vertical-align: sub;
}
.info-icon:hover {
  color: black;
}
.emails {
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Adds space between emails */
  max-width: 25vw;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.body {
  max-width: 25vw;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

