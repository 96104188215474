.template-container-pera {
    font-size: 20px !important;
    font-weight: 600;
    /* padding: 0px; */
    /* padding-left: 15px; */
}
.template-container {
    padding: 0px 20px;
}
.work-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5; 
    text-decoration: underline;
  }
  /* .template-container-pera span{
    width: 15vw;
  } */

  .cold-work{
padding-top: 15px;
  }