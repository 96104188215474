.main-content {
    padding: 20px;
    transition: margin-left 0.3s;
  }
  
  .sidebar-expanded {
    margin-left: 250px;
  }
  
  .sidebar-collapsed {
    margin-left: 80px;
  }
  
  .pr-request-view {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pr-request-view h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .back-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-bottom: 20px;
    color: #333;
  }
  
  .table-container {
    overflow-x: auto;
  }
  
 
  
  th, td {
    padding: 15px;
    text-align: center;
  }
  
  .attachments {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .attachment-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
  }
  
  .attachment-buttons {
    display: flex;
    gap: 10px;
  }
  
  .view-btn, .download-btn {
    padding: 8px 12px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-btn {
    background-color: #2196f3;
    color: white;
  }
  
  .view-btn:hover {
    background-color: #1e88e5;
  }
  
  .download-btn {
    background-color: #4caf50;
    color: white;
    text-decoration: none;
    display: inline-block;
  }
  
  .download-btn:hover {
    background-color: #45a049;
  }

  th {
   
    color: #333;
  }
  
  /* td {
    border-bottom: 1px solid #ddd;
  }
   */
  /* td:last-child {
    text-align: center;
  } */
  
  .remark-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
  }
  
  .action-buttons {
    /* display: block; */
    gap: 10px;
    margin-right: 5px;
    /* justify-content: center; */
  }
  
  .accept-btn, .reject-btn, .return-btn {
    padding: 10px 20px;
    border: none;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    margin-right: 6px;
    width: 90px !important;
    transition: background-color 0.3s;
  }
  
  .accept-btn {
    background-color: #4caf50;
  }
  
  .accept-btn:hover {
    background-color: #45a049;
  }
  
  .reject-btn {
    background-color: #f44336;
  }
  
  .reject-btn:hover {
    background-color: #e53935;
  }
  
  .return-btn {
    background-color: #ff9800;
  }
  
  .return-btn:hover {
    background-color: #fb8c00;
  }
  .status-color{
    margin-top: 10px;
  }
  .status-view-button{
    display: flex;
    margin-top: 10px !important;
    /* justify-content: space-between;
    padding: 0 110px; */
  }
  .file-name-label {
    display: flex;
    margin-top: 15px !important;
}
.button-fleex{
  display: flex;
  justify-content: space-between;
}
.form-remark-group{
  display: flex;
}
.form-remark-group label{
  padding-top: 5px ;
  padding-right: 10px;
}
.action-button{
  margin-left: 10px;
}

.file-name-message {
  color: #999; 
  font-style: italic;
  padding: 10px;
  text-align: center;
  justify-content: center;
}
.form-remark-group span{
margin-top: 5px !important;
}
.pr-view{
  margin-bottom: 1px;
}
.closed {
  color: red !important;
}

.open {
  color: green !important;
}
