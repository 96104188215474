th.active-status {
  color: rgb(41, 128, 185) !important;
}
th.complet-status{
  color: #2e8b57 !important;
}
th.hold{
  color: #d4ac0d !important;
}
th.cancel{
  color: red !important;
}
.data-list-table-report {
    position: absolute;
    max-height: 70vh !important;
}

.data-list-table-report {
    position: relative !important;
    overflow-x: auto !important;
    overflow-y: auto !important;
    width: 100%;
    height: 70vh !important;
    margin: 0px !important;
}
.data-list-table-report {
    display: flex;
    flex-direction: column;
}
.data-list-table-report::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

.data-list-table-report::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
}

.data-list-table-report::-webkit-scrollbar-track {
    background-color: transparent;
}
  .generate-scheduler-button:disabled {
    /* background-color: #6c757d; */
    cursor: not-allowed;
  }


  .loaders {
    clear: both;
    /* margin: 1rem  auto; */
    margin: 3px 6px;
    width: 6.65rem !important;
    height: 1.125rem;
    border: 1px white solid;
    border-radius: 4px;
    background: linear-gradient(-60deg, transparent 0%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
    background-size: 20px 30px;
    background-position: 0px 0px;
    animation: spLoadBar 0.8s infinite linear;
  }
  
  @keyframes spLoadBar {
    from {
      background-position: 0px 0px;
    }
  
    to {
      background-position: 20px 0px;
    }
  }
  .department-status{
    text-align: left;
  }
  .total-report-icon{
    vertical-align: text-bottom;
    padding-bottom: 2px;
    padding-bottom: 0.8px;
  }
  .total-equipments1{
    color: blue !important;
  }
  .total-equipments2{
    color: green !important;
  }
  .total-equipments3{
    color: red !important;
  }