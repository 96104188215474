.form-seperator {
  background-color: rgb(237, 243, 245);
  padding: 3px 7px;
  margin: 10px 0;
}
.form-header-seperator {
  padding: 15px 0;
}
.form-header-seperator-content {
  display: flex;
  align-items: center;
  gap: 126px;
}
.box {
  border: 1px solid gray;
  padding: 65px 55px;
  margin-right: 10px;
}
.form-group-top-select .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 100% !important;
}
.input-group-top {
  width: 87% !important;
}
.form-group-top input {
  width: 100% !important;
  padding: 7px 20px 7px 10px !important;
  border: 1.5px solid #ccc !important;
  border-radius: 4px;
  margin-bottom: 5px;
  color: #212529;
}
.form-group-top label,
.form-group-top-select label {
  width: 22%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  color: #212529 !important;
}

/* Karan Custom CSS */
.equip {
  width: 100%;
}
.equip1 {
  width: 10%;
}
.equip2 {
  width: 40%;
}
.equip3 {
  /* width: 50%; */
  /* margin-left: 50px; */
}
.equips3 {
  padding: 15px 20px;
}
.equip3-boxs {
  /* background: #f6f6f6;
    border-radius: 5px;
    padding: 25px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-left: 50px; */
  padding: 15px 20px;
  height: 60vh;
  width: 75vw;
  overflow-y: scroll;
}
.equip2 .input-group-top {
  width: 100% !important;
}
.equip2 .input-group-top input {
  width: -webkit-fill-available !important;
}
.equip3 .equip-category {
  margin-left: 25px;
  /* padding:  15px 35px; */
  /* background: #f6f6f6; */
  /* border-radius: 5px; */
  width: 50%;
}
.equip3 .frequency-table-container {
  width: 50%;
}
.equip-category .parent {
  position: relative;
}
.equip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.equip-category .parent .equip-icon {
  position: absolute;
  content: "";
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}
.equip-category .child:first-child {
  margin-left: 15px;
  position: relative;
}
.equip-category .child:nth-child(2) {
  margin-left: 25px;
  position: relative;
}
.equip-category .child .equip-icon {
  position: absolute;
  content: "";
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}
.equip-category .child:nth-child(3) {
  margin-left: 35px;
  position: relative;
}
/* .equip-category .child:nth-child(3)::before{
    position: absolute;
    content: '';
    width: 15px;
    height: 2px;
    background: #000;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
} */
.equip-category .child:nth-child(4) {
  margin-left: 45px;
  position: relative;
}
/* .equip-category .child:nth-child(4)::before{
    position: absolute;
    content: '';
    width: 15px;
    height: 2px;
    background: #000;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
} */
.equip-category .child:last-child {
  margin-left: 55px;
  position: relative;
}
/* .equip-category .child:last-child::before{
    position: absolute;
    content: '';
    width: 15px;
    height: 2px;
    background: #000;
    top: 50%;
    left: -20px;
    transform: translateY(-50%);
} */
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 126px;
  padding: 0px;
}
.box svg {
  font-size: 75px;
}

.frequency-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.frequency-popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 80vw;
}

.frequency-popup h3 {
  margin-top: 0;
}

.frequency-popup button {
  margin-top: 10px;
}
.frequency-popup-content-header {
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
}
.frequency-detail {
  font-size: 18px;
  font-weight: 600;
}
.cross-button {
  border: none;
  background: none;
}
.cross-icon {
  color: red;
  font-size: 18px;
  font-weight: bold;
  vertical-align: sub;
}
.equip-categorys {
  display: flex;
  /* background: black; */
  color: black;
  border-radius: 5px;
  padding: 10px;
}
.equip-category {
  margin-left: 50px;
}
.frequency-tables {
  display: flex;
  justify-content: space-between !important;
  padding: 10px;
  padding-top: 15px;
  gap: 10px !important;
}
.frequency-table {
  border: 1px solid #ccc;
}

.equip3-boxs::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.equip3-boxs::-webkit-scrollbar-track {
  background-color: transparent;
}

.equip3-boxs::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}
.frequency-button {
  background-color: #408dfb;
  border: none;
  padding: 5.005px 9.009px;
  border-radius: 5px;
  color: white;
  margin-left: 15px;
}
.oem {
  color: #464f54 !important;
  font-weight: 620;
}
.oem-th {
  background: #dadcdd !important;
}
.oisd {
  color: #318798 !important;
  font-weight: 620;
}
.oisd-th th {
  background: #d6e7ea !important;
}
.factory-act {
  color: #d65569 !important;
  font-weight: 620;
}
.factory-act-th th {
  background: #f7dde1 !important;
}
.peso {
  color: #e79d46 !important;
  font-weight: 620;
}
.peso-th th {
  background: #faebda !important;
}
.button-flex {
  display: flex;
}
.form-group-top-select {
  position: relative;
}
button.frequency-button {
  position: absolute;
  left: 100%;
  top: 28px;
  width: max-content;
}
