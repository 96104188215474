.radio-button{
    display: flex;
}
.form-groupsv input {
    width: 100% !important;
    padding: 7px 25px 7px 10px !important;
    border: 1.5px solid #ccc !important;
    border-radius: 4px;
    margin-bottom: 5px;
    color: #212529;
}
.radio-check{
    width: 10px !important;
}
.error-message-date{
    margin-left: 76px !important;
    color: #e54643 !important;
    font-size: 14px;
    margin-top: 5px !important;
    width: 30vw;
}
.error-message-radio{
    margin-left: 158px !important;
    color: #e54643 !important;
    font-size: 14px;
    margin-top: 1px !important;
    width: 30vw;
}
.error-message-radio2{
    margin-left: 218px !important;
    color: #e54643 !important;
    font-size: 14px;
    margin-top: 1px !important;
    width: 30vw;
}

.attach-popup-amc5 {
    position: relative;
    top: 5.5vh;
    right: 8%;
    background-color: #fff;
    border: 1px solid #ccc;
    /* padding: 10px; */
    border-radius: 5px;
    z-index: 999;
    width: 92%;
}
.error-field input {
    border: 1px solid red !important;
 
  }
  
  .error-field input:focus {
    /* outline: none; */
    border: 1px solid darkred;
  }
  .error-fields .css-b62m3t-container{
    position: relative;
    box-sizing: border-box;
    width: 100.3% !important;
    border: 1px solid red !important;
    border-radius: 5px;

  }
  .error-fields :focus{
    outline: none !important;
  }
  .action-purchase-scroll-bodys-pr input {
    width: 98%;
    /* border: none !important; */
    height: 100%;
    border-radius: 5px;
    text-align: center;
    padding: 0 0;
}

.action-purchase-scroll-bodys-pr input {
    width: 98%;
    /* border: none !important; */
    /* border: 1px solid #408dfb !important; */
    height: 100%;
    border-radius: 5px;
    text-align: center;
    /* padding: 0 0; */
}
.action-purchase-scroll-bodys-pr input:hover {
    
    border: 1px solid #408dfb !important;
}
.action-purchase-scroll-bodys-pr input:focus {
    
    /* outline: 1px solid #408dfb !important; */
}
.th4 input:focus{
     outline: 1px solid #408dfb !important; 
}
.action-purchase-scroll-bodys-pr td {
    border: 1px solid #ebeaf2;
    padding: 5px 3px;
    height: 40px;
    text-align: center !important;
}
#react-select-10-input,
#react-select-11-input{
    border: none !important;
}