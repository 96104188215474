.paginations, .paginations-popup {
    position: fixed !important;
    bottom: 0px !important;
    height: 6.5vh !important;
    /* background-color: rgba(225, 227, 225, 1.00); */
    /* background-color: rgb(236, 225, 225); */
    background: white;
    border-top: 1px solid #eee;
    text-align: center;
    align-items: center;
    z-index: 999;
  }
  .paginations {
border-left: 1px solid #e4e4e4;
  }
  .page-content.expanded .paginations-popup {
    width: 100%;
}
  .pagination-body{
    padding: 8px 20px;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
  }
  .pagination-left-side{
  
  }
  .pagination-right-side{
    display: flex;
  
  }
  .dropdown {
    margin-right: 20px;
  }
  .dropdown-option {
    border: none;
    /* border-radius: 17px; */
    margin-left: 5px;
    /* padding: 3px 5px 2px 5px; */
    cursor: pointer;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1); */
  }
  .dropdown-option select {
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 17px;
    padding: 3px 5px 2px 5px;
  }
  .dropdown-option select:focus {
    outline: none;
  }
  .arrow-number{
    background-color: white;
    border-radius: 17px;
    display: flex;
    padding: 0px 15px;
    text-align: center;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  
  .arrow1,
  .arrow2,
  .arrow3{
    padding: 0px 5px 0px 0px;
  }
  .arrow4{
    padding: 0px;
  }
  
  .arrow1:hover,
  .arrow2:hover,
  .arrow3:hover,
  .arrow4:hover{
    color: rgb(164, 162, 162);
  }
  .arrow1,
  .arrow2,
  .arrow3,
  .arrow4 {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .arrow1 .arrow1text,
  .arrow2 .arrow2text,
  .arrow3 .arrow3text
  .arrow4 .arrow4text {
    visibility: hidden;
    width: 80px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    font-size: 10px;
    position: absolute;
    z-index: 1001 !important;
    bottom: 100%;
    left: 50%;
    margin-left: -40px;
  }
  
  .arrow1:hover .arrow1text,
  .arrow2:hover .arrow2text,
  .arrow3:hover .arrow3text,
  .arrow4:hover .arrow4text {
    visibility: visible;
  }
  .arrow1 .arrow1text{
    padding: 5px 0;
  }
  .arrow2 .arrow2text {
    padding: 5px 5px !important;
  }
  .arrow3 .arrow3text {
    padding: 5px 0;
    font-size: 10px;
  }
  .arrow4 .arrow4text {
    padding: 5px 0;
  }
  
  
  
  
  
  
  
  
  
  