.tabs {
    display: flex;
    padding: 5px 44px ;
    border-bottom: 1px solid #eee;
  }

/* th.th3 {
    color: blue !important;
}
td.quantity{
  color: blue !important;
} */
  .tab-button {
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .tab-button.active {
    background-color: #f9f9f9;
    color: #1976d2;
  }
  
  .tab-pane {
    padding: 20px;
    /* background-color: #fff; */
  }
  .tab-content{

  }
  .action-purchase-tables {
    max-height: 35vh; /* Adjust height as per your requirement */
    overflow-y: auto;
  }

.action-purchase-tables::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

.action-purchase-tables::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
}

.action-purchase-tables::-webkit-scrollbar-track {
    background-color: transparent;
}





  .action-table thead th{
    position: sticky;
    top: -2 !important;
    background-color: white;
    color: black;
    z-index: 1;
  }
  
  .scrollable-table table {
    width: 100%; /* Ensures the table fits within the scrollable div */
  }
  


  .detail-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  th.Total-Qty{
    color: blue !important;
  }
  
  th.Inward-Qty{
    color: green !important;
  }
  .detail-popup-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 80vw;
  }

  .detail-popup-content-header {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
}
  
.vendor-detail {
  font-size: 18px;
  font-weight: 600;
}
.vendor {
  padding: 20px;
  height: 60vh;
  overflow-y: scroll;
  width: 75vw;
}
 .th44 input {
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
  padding: 16px 0;
}

.th44 input:focus {
  outline: 1px solid #408dfb !important;
  border-radius: 5px;
  height: 100%;
}
.invoice-body-container{
  border: 1px solid #eee;
  border-radius: 5px;
  margin: 5px;
  /* padding: 10px 0; */
  padding-top: 15px;
}






.tabs-container {
  display: flex;
  justify-content: flex-start;
  border-bottom: 2px solid #ddd;
}

.tab-button {
  padding: 10px 20px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s ease;
}

.tab-button.active {
  border-bottom: 2px solid #007bff; /* Active tab border */
  /* font-weight: bold; */
  color: #007bff; /* Active tab text color */
}

.tab-button:hover {
  border-bottom: 2px solid #007bff; /* Hover effect */
}

.tab-button:focus {
  outline: none;
}
.confirm-model-content-body p{
  text-align: left;

}
.confirm-delete-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(0, 0, 0, 0.5); */
  background-color: #00000080;
  z-index: 1000;
}

.challan-btn:disabled {
  background-color: #ccc; 
  color: #666; 
  cursor: not-allowed !important; 
  opacity: 0.6; 
  border: 1px solid #aaa; 
}

.data-form-details-button:disabled {
  background-color: #ccc; 
  color: #666; 
  border: 1px solid #aaa !important; 
  opacity: 0.6; 
  cursor: not-allowed !important;
}
.cancel-button {
  background: #dc3545;
  color: white;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  transition: 0.3s;
}
.cancel-button-pr{

}
.cancel-button-pr {
  color: white;
  background: red;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px 9.997px;
  font-size: 13px;
  margin-left: 5px;
}
.approved-pr-table{
  overflow-y: scroll;
  height: 70vh;
}