.inspection-body{
   display: flex;
   gap: 10px;
}
.inspection-body-container1{
    width: 30%;
}
.inspection-body-container2{
    width: 70%;
    /* height: 70vh;
    overflow-y: scroll; */
}
.inspection-box{
    background-color: #f3f7f7;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.inspection-box-header{
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.inspection-box-header3{
    font-weight: bold;
    display: flex;
    font-size: 14px;
}
.box-contain{
    display: flex;
    justify-content: space-between;
}
.inspection-box-body2{
    border: 1px solid #f4eded;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 10px;
    height: 55vh;
    overflow-y: scroll;
    margin-bottom: 15px;
}
.inspection-box-body3{
    border: 1px solid #f4eded;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 10px;
}
.inspection-box-header2{
    font-weight: bold;
}
.inspaction-description{
    width: 25vw;
}
.data-form-reject-button:disabled, .data-form-save-button.disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
    border: 1px solid #ccc !important;
}
.new-button-download:disabled {
    background-color: #ccc; /* Grey background */
    border: 1px solid #ccc;
    cursor: not-allowed !important;   /* Disable pointer cursor */
  }
  .data-form-reject-button:disabled {
    background-color: #ccc;
    border: 1px solid #ccc;
    cursor: not-allowed !important;
    /* color: white; */
    color: #666;
}