.tabs button {
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

/* .tab-content div {
  margin-top: 20px;
} */

.radio-check-option {
}
.radio-check-option label {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between radio button and label text */
  font-family: Arial, sans-serif;
  font-size: 14px;
  color: #777; /* Neutral text color for labels */
  cursor: not-allowed; /* Cursor indicates disabled state */
}
.radio-check-option input[type="radio"] {
  appearance: none; /* Remove default radio button styles */
  width: 18px;
  height: 18px;
  border: 2px solid #ccc; /* Default border color */
  border-radius: 50%;
  background-color: #f2f2f2; /* Light gray background */
  position: relative;
  cursor: not-allowed;
}

.radio-check-option input[type="radio"]:checked {
  background: #888 !important; /* Darker gray when checked */
  border-color: #555; /* Border darkens */
}

.radio-check-option input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #444 !important; /* Dark gray for inner dot */
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.radio-check-option input[type="radio"]:disabled {
  opacity: 0.6; /* Makes disabled inputs look faded */
}

.blinking-new-badge {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.new-badge {
  align-items: center;
  border: none !important;
  border-radius: 20px;
  justify-content: center;
  left: 32%;
  overflow: hidden;
  padding: 6px 13px;
  position: relative;
  /* background-color: #fff; */
}

.new-badge:before {
  color: #007bff;
  content: "New";
  pointer-events: none;
  position: absolute;
}

.blinking-new {
  animation: blink 1s infinite;
}
.blinking-news {
  /* animation: blink 1s infinite; */
  color: red;
  font-size: 14px;
  font-weight: 600;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinking-new {
  color: red;
}

.tab-label-container {
  display: flex;
  align-items: center; /* Align vertically centered */
  gap: 8px; /* Space between label and count */
  position: relative;
}

.gray-button {
  background-color: gray;
  color: white;
  border: none;
  cursor: not-allowed;
}

.request-button.disabled {
  background-color: #ccc; /* Disabled button color */
  color: #666; /* Disabled text color */
  cursor: not-allowed; /* Change cursor to show it's not clickable */
  border: 1px solid #ccc;
  margin-right: 5px;
}
.data-list-table-permit-table {
  position: absolute;
  max-height: 59vh !important;
}
.data-list-table-permit-table {
  position: relative !important;
  overflow-x: auto !important;
  overflow-y: auto !important;
  width: 100%;
  height: 59vh !important;
  margin: 0px !important;
}
.data-list-table-permit-table {
  display: flex;
  flex-direction: column;
}
.data-list-scroll-permit-body {
  height: 64vh !important;
}
.data-list-table-permit-table::-webkit-scrollbar {
  width: 2px;
  height: 2px !important;
}

.data-list-table-permit-table::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.data-list-table-permit-table::-webkit-scrollbar-track {
  background-color: transparent;
}
.new-button-download {
  background: #408dfb;
  border: 1px solid #408dfb;
  color: white;
  border-radius: 4px;
  /* padding: 5.005px 9.009px; */
  padding: 4.005px 9.997px;
  margin-right: 5px;
}

.data-form-save-button:disabled,
.data-form-save-button.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border: 1px solid #ccc !important;
  margin-right: 10px;
}
.permit-buttons {
  width: 14vw;
  margin-right: 5px;
  text-align: center;
}

.permit-buttons1 {
  display: flex;
  text-align: center;
}

.edit-button-inquiry12 {
  padding: 4.005px 9.997px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
/* .edit-button-inquiry1 {
  background-color: #007bff;
  color: white;
  cursor: pointer;
} */
.edit-button-inquiry12 {
  display: flex;
}

.request-buttons {
  border: 1px solid #45a049;
  color: white;
  background: #45a049;
  border-radius: 4px;
  padding: 4.005px 9.997px;
  margin-left: 5px;
  margin-right: 5px;
}
.request-buttons.disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
  border: 1px solid #ccc;
  margin-right: 5px;
}

/* .pdf-view-data{
  position: absolute;
  z-index: -1;
} */

.new-tab-count {
  /* background: #1976d2; */
  /* background: red;
  border-radius: 50%;
  color: white;
  padding: 5.005px 9.997px;
  border-radius: 5px;
  margin-left: 10px;
  text-transform: none !important; */

  background-color: #f53e3e;
  color: white;
  border-radius: 50%;
  /* padding: 2px 6px; */
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -15px;
  right: -20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  z-index: 999 !important;
}
.permit-tab .css-1usuzwp-MuiButtonBase-root-MuiTab-root {
  padding: 16px 20px 8px 20px !important;
}
.view-pulse-button {
  background: rgb(9, 175, 9) !important;
  border: 1px solid rgb(9, 175, 9) !important;
  color: white;
  border-radius: 4px;
  /* padding: 5.005px 9.009px; */
  padding: 4.005px 9.997px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  /* position: relative; */
}
.close-view-pulse-button {
  background: rgb(9, 175, 9) !important;
  border: 1px solid rgb(9, 175, 9) !important;
  color: white;
  border-radius: 4px;
  /* padding: 5.005px 9.009px; */
  padding: 4.005px 9.997px;
  margin-right: 5px;
}
.red-button {
  background-color: #ff6b6b;
  color: white;
  border-radius: 50%;
  /* padding: 2px 6px; */
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -2px;
  right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  z-index: 9999 !important;
}

.dot-relative {
  position: relative;
}
.red-button-close {
  background-color: #ff6b6b;
  color: white;
  border-radius: 50%;
  /* padding: 2px 6px; */
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: -4px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  z-index: 9999 !important;
}
/* Background overlay */
/* === Background Overlay === */
.reject-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1050;
  backdrop-filter: blur(3px);
}

/* === Popup Box === */
.reject-popup-content {
  background: #fff;
  width: 450px;
  padding: 24px;
  border-radius: 10px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

/* === Header Styling === */
.reject-popup-header {
  font-size: 22px;
  font-weight: 600;
  color: #d9534f;
  margin-bottom: 16px;
}

/* === Popup Text === */
.reject-popup-content p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* === Button Group === */
.popup-buttons {
  display: flex;
  justify-content: center;
  gap: 12px;
}

/* === Confirm Reject Button (Red) === */
.reject-confirm-button {
  background: linear-gradient(135deg, #dc3545, #c82333);
  color: white;
  border: #dc3545;
  /* padding: 10px 20px; */
  padding: 5.005px 9.99px;
  border-radius: 7.392px;
  font-size: 13.3px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  /* box-shadow: 0px 3px 8px rgba(220, 53, 69, 0.3); */
}


/* === Cancel Button (Gray) === */
.reject-cancel-button {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 7.392px;
  color: #212529;
  /* border: none; */
  padding: 5.005px 9.99px;
  /* border-radius: 6px; */
  font-size: 13.3px;
  margin: 0 0 0 3.25px;
  font-weight: 500;
  cursor: pointer;
}

/* === Fade-in Animation === */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* === Responsive Design === */
@media (max-width: 480px) {
  .reject-popup-content {
    width: 90%;
    padding: 20px;
  }
  .reject-confirm-button,
  .reject-cancel-button {
    width: 100%;
  }
}
/* === Background Overlay === */
.permit-approval-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1050;
}

/* === Popup Content === */
.permit-approval-popup-content {
  background: #fff;
  width: 450px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
  position: fixed;
  top: 25px;
}

/* === Header Styling === */
.approval-popup-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}

/* === Message Styling === */
.approval-popup-message {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

/* === Buttons Container === */
.approval-popup-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* === Approve Button === */
.approve-button {
  background-color: #408dfb;
  border: 1px solid #408dfb;
  color: white;
  /* padding: 10px 20px; */
  padding: 5.005px 9.99px;
  /* border: 1px solid #28a745; */
  border-radius: 7.392px;
  cursor: pointer;
  font-size: 13.3px;
  transition: 0.3s;
  margin: 0 3.25px 0 0;
}

.approve-button:hover {
  background: #218838;
}

/* === Cancel Button === */
.approve-button-cancel {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 7.392px;
  color: #212529;
  /* padding: 10px 20px; */
  padding: 5.005px 9.99px;
  cursor: pointer;
  margin: 0 0 0 3.25px;
  font-size: 13.3px;
  transition: 0.3s;
}

.cancel-button:hover {
  background: #c82333;
}

/* === Fade-in Animation === */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
