.technik-approve-btn{
    background-color: #4caf50;
    border: none;
    color: white;
    padding: 5.005px 9.997px;
    border-radius: 5px;
    margin-right: 5px;
}
.technik-reject-btn{
    background-color: #f44336;
    border: none;
    color: white;
    padding: 5.005px 9.997px;
    border-radius: 5px;
}

/* Custom Popup Styles */
.custom-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); /* Semi-transparent dark background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Make sure the popup appears on top */
  }
  
  .custom-popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    max-width:500px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .custom-popup-content h3 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
  }
  
  .custom-popup-content textarea {
    width: 90% !important;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    color: #333;
    resize: none; /* Disable textarea resizing */
    margin-bottom: 20px;
  }
  
  .custom-popup-actions {
    display: flex;
    /* justify-content: space-between; */
  }
  
  .popup-button {
    background-color: #007bff; /* Primary button color */
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .popup-button:hover {
    background-color: #0056b3; 
  }
  
  .close-button {
    background-color: #dc3545;
  }
  
  .close-button:hover {
    background-color: #c82333;
  }
  .data-form-save-button-save {
    background:#4CAF50;
    padding: 4.005px 9.997px;
    border: 1px solid #4CAF50;
    border-radius: 5px;
    color: #ffffff;
    font-size: 13px;
}
.qty1{
  color: blue !important;
}
.qty2{
  color: green !important;
}
.qty3{
  color: red !important;
}
.data-form-save-button-edit{
  background:#408dfb;
  padding: 4.005px 9.997px;
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
}
.attach-popup-inward {
  position: absolute;
  top: 35px;
  left: 39.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 18%;
  max-width: 300px;
}

.data-form-save-button-save:disabled {
  background-color: #ccc;
  color: #666;
  border: 1px solid #aaa !important;
  opacity: 0.6;
  cursor: not-allowed !important;
}

.generate-scheduler-button:disabled {
  background-color: #ccc;
  color: #666;
  border: 1px solid #aaa !important;
  opacity: 0.6;
  cursor: not-allowed !important;
}
.app-Qty{
color: green !important;
}
.rej-Qty{
color: red !important;
}

.polist-popup1 {
  position: absolute;
  z-index: 999 !important;
  top: 16%;
  right: 2.5%;
  background: #fff;
  border: 1px solid #ccc;
  z-index: 9999;
  width: 15%;
}
.attach-popup-contant-body {
  padding: 2px 10px;
  height: 12vh;
  overflow-y: scroll !important;
  overflow-x: hidden;
}