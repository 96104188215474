.page-content {
  margin-left: 250px;
}

.data-form-container {
  background-color: white;
  position: absolute;
  top: 6.9%;
  overflow: hidden;
  width: 87%;
}

.data-form-content-container {
  transition: all 0.3s;
}

.data-form-header-content {
  display: flex;
  justify-content: space-between;
}

.data-form-header {
  padding: 17px 20px;
}

.data-form-heading-content-left-side {
  color: #212529;
  font-size: 22px;
}

.data-form-body {
  padding: 17px 20px;
  height: 70vh !important;
  /* overflow-y: scroll; */
  overflow-y: auto;
  /* min-height: 100vh; */
  /* background-color: gray; */
 
}

/* .data-form-body form {
  height: calc(79vh - 15px) !important;
}
.new-data-form {
  max-height: calc(79vh - 66px) !important;
  overflow-y: scroll;
} */


.data-form-body::-webkit-scrollbar {
  width: 8px;
}

.data-form-body::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.data-form-body::-webkit-scrollbar-track {
  background-color: transparent;
}
.header-separator {
  border: 1px solid #e4e4e4 !important;
}
.footer {
  position: fixed;
  background-color: rgb(255, 255, 255);
  border-top: 1px solid #ccc;
  bottom: 0%;
  width: 100%;
  padding: 15px 20px;
  z-index: 999;
  border-left: 1px solid #e4e4e4;
}
/* .footer {
  background: #0000 !important;
  border-top: 1px solid #ccc !important;
  bottom: 0;
  box-shadow: none !important;
  padding: 0 !important;
  position: fixed;
  width: 87.5%;
}
.footer-contents {
  align-items: center;
  display: flex;
  font-size: 13px;
  margin-right: 15px;
  text-align: center;
} */

.data-form-save-button {
  background: #408dfb;
  padding: 4.005px 9.997px;
  border: 1px solid #408dfb !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
}
.data-form-details-button {
  background: #d8c40c;
  padding: 4.005px 9.997px;
  border: 1px solid #cfab0a !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
}

.data-form-cancel-button {
  background-color: #f5f5f5;
  padding: 5.005px 9.997px;
  border: 1px solid rgb(197, 191, 191);
  border-radius: 5px;
  font-size: 13px;
  color: #212529;
  font-size: 13px;
  margin-left: 10px;
}

.data-form-save-button:hover {
  background-color: #0870df;
}

.data-form-cancel-button:hover {
  background-color: #f3f0f0;
}

.form-group {
  display: flex;
  margin: 10px 0px !important;
}

.form-group label {
  width: 22%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  display: inline-block;
  color: #212529;
}

.form-group input {
  width: 100% !important;
  padding: 7px 25px 7px 10px !important;
  border: 1.5px solid #ccc !important;
  border-radius: 4px;
  margin-bottom: 5px;
  color: #212529;
}
.form-group input:hover {
  border: 1.5px solid #5da0bd !important;
  box-shadow: 0 -4px 5px -3px rgba(67, 89, 131, 0.1) !important;
}
.form-group input:focus {
  outline: 1.5px solid #5da0bd;
}
.input-group {
  width: 27.5% !important;
}

.form-group-select {
  display: flex;
  /* margin: 10px 0px !important; */
  margin-bottom: 18px;
}
.form-group-select label {
  width: 22%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  display: inline-block;
  color: #212529;
}

.form-group-select Select:focus {
  outline: 1.5px solid #5da0bd;
}

.form-group-selectname input {
  margin-left: 10px;
  width: 10% !important;
  padding: 5px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group-selectname Select {
  width: 60% !important;
}

.form-group-selectname label {
  width: 22%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  display: inline-block;
  color: #212529;
}

.form-group-selectname {
  display: flex;
  margin: 10px 0px !important;
}

.form-group-selectname .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 10.4% !important;
}

.form-group-selectname input:hover {
  border: 1.5px solid #5da0bd !important;
}

.form-group-selectname input:focus {
  outline: 1.5px solid #5da0bd;
}
.error-message {
  margin-left: 43px !important;
  color: red !important;
  font-size: 14px;
  margin-top: 5px !important;
  padding: 2px;
}

.error-messages {
  margin-left: 13px !important;
  font-size: 14px;
  margin-top: 5px;
  color: red;
}
.texts-danger {
  color: #e54643;
  font-size: 14px;
  margin-left: 20px;
  margin-top: 5px !important;
}
.text-danger {
  color: #e54643;
  font-size: 14px;
  margin-left: 2px;
}
textarea {
  border: 1.5px solid #ccc;
  border-radius: 4px;
  padding: 7px 25.5px 7px 10px;
  width: 100% !important ;
}

textarea:hover {
  border: 1.5px solid #5da0bd !important;
  box-shadow: 0 -4px 5px -3px rgba(67, 89, 131, 0.1) !important;
}
textarea:focus {
  outline: 1.5px solid #5da0bd;
}
.css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 27.3% !important;
}

Select .css-13cymwt-control {
  width: 100% !important;
}

.css-t3ipsp-control {
  width: 100% !important;
  border: 1.5px solid #ccc !important;
  box-shadow: none !important;
}
.password-toggle-button {
  /* border-radius: 5px; */
  /* border: 1.5px solid #ccc !important; */
  padding: 0px 9.997px !important;
  background: none;
  margin-left: -41px;
  border: none;
  height: 0.2vh;
  margin-top: 0px;
}
.pasword-icon {
  /* margin-top: 12px  !important; */
  position: relative;
  top: 12px;
  /* vertical-align: top; */
  font-size: 16px;
}

.password-group {
  display: flex;
}
.css-1u9des2-indicatorSeparator {
  margin-bottom: 0 !important;
}

.select-container {
  width: 100%;
}

form {
  height: auto !important;
}

.companycolor {
  color: rgb(167, 163, 163) !important;
  font-weight: bold;
}
.input-group-name {
  display: flex;
}
.empty-field {
  border-color: red;
}
.attach-file-containerss:hover {
  background-color: rgb(226, 223, 223);
  border-radius: 5px;
}
.inquiry-customercompany-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.inquiry-customerperson-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.close-popup-button {
  background-color: #dc3545;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
}
.form-group1 {
  display: flex;
  margin: 10px;
}

.form-group1 label {
  width: 20%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  display: inline-block;
}

.form-group1 input {
  width: 100% !important;
  padding: 7px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.bold-text {
  font-weight: bold;
}

.point-fr {
  width: 30% !important;
  margin-right: 10px;
  padding: 7px 10px 7px 10px !important;
}
.flex-group {
  display: flex;
  margin-top: 15px !important;
}

.flex-group input {
  width: 100% !important;
}
.new-frequency-rowss {
  display: flex;
  flex-direction: column;
  width: 45% !important;
}

.generate-mat-code-button {
  background-color: #358eec;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 6px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-left: 10px;
}

.generate-mat-code-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.generate-mat-code-button:active {
  background-color: #004080;
  transform: translateY(0); /* Reset the lift on click */
}

.generate-mat-code-button:disabled {
  background-color: #cccccc; /* Gray out the button when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's not clickable */
}
/* .error-message {
  width: 30vw;
} */

.cancel-button {
  background: #a5a5a5;
  padding: 4.005px 9.997px;
  border: 1px solid #646566 !important;
  border-radius: 5px;
  color: #ffffff;
  font-size: 13px;
  margin-left: 5px;
}
.w-100 {
  width: 100% !important;
}

div#react-select-17-placeholder,
div#react-select-19-placeholder,
div#react-select-21-placeholder,
.new-data-form input::placeholder {
  font-size: 14px !important;
}
.css-13hxqku-control{
  transition: none !important;
}
.css-vbhje1-control{
  transition: none !important;
}
/* .css-qbdosj-Input{
  display: none !important;
} */
 .error-messages125 {
  font-size: 14px;
  margin-top: 5px;
  color: red;
}


.upload-progress-container {
  position: fixed;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  background: #f3f3f3;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.upload-progress-bar {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 5px;
}

.upload-progress-bar div {
  height: 100%;
  background: #4caf50;
  width: 0%;
  transition: width 0.2s ease-in-out;
}
.uplod-progress-button{
  border: none;
  background: none;
}
