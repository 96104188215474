.invoice-request-container {
  background-color: white;
  position: absolute;
  top: 6.9%;
  overflow: hidden;
  width: 87%;
}

.invoice-request-content-container .page-content {
  transition: all 0.3s;
}

.invoice-request-header-content {
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebeaf2;
}

.invoice-request-heading-bottom button {
  border: 1px dashed #d7d5e2;
  border-radius: 6px;
  background-color: #fff;
}

.invoice-request-heading-content-left-side {
  color: #212529;
  font-size: 22px;
  font-weight: 500;
}

.invoice-request-table {
  display: flex;
  flex-direction: column;
}

.invoice-request-scroll-body {
  height: 39vh !important;
}

.invoice-request-table {
  position: relative !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 100%;
  /* height: 39vh !important; */
}

.invoice-request-table {
  position: absolute;
  max-height: 39vh !important;
}


.invoice-request-table::-webkit-scrollbar {
  width: 8px;
}

.invoice-request-table::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.invoice-request-table::-webkit-scrollbar-track {
  background-color: transparent;
}

.invoice-request-scroll-bodys tr:hover {
  background-color: #F1F1F1;
}

.schedule-body{
  height: 68vh;
  overflow-y: scroll;
}
.schedule-body::-webkit-scrollbar {
  width: 8px;
}

.schedule-body::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.schedule-body::-webkit-scrollbar-track {
  background-color: transparent;
}





.project-detailss {
  padding: 8px 20px;
}

.project-details-left-side {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.project-details-right-side {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
}

.project-detail {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.detail-part,
.detail-part-customer {
  font-size: 12px;
  font-weight: 700;
  display: flex;
  margin-bottom: 2px;
}

.detail-header {
  font-size: 12px;
  margin-right: 5px;
}

.download-icons {
  color: white;
  vertical-align: text-top;
  margin-right: 5px;
}

.download-button {
  background-color: #5dbea3;
  border-radius: 0.25rem;
  color: white;
  padding: 5.005px 9.997px;
  border: none;
  font-size: 13px;
  margin-left: 10px;
}

.person-detail {
  /* border: 2px solid #3bed00; */
  border: none;
  background: #61E856;
  border-radius: 0.35rem;
  padding: 2px 4px;
  cursor: pointer;
  position: relative;
}

.person-details {
  /* border: 2px solid #ffca0a; */
  background: #E8C856;
  border: none;
  border-radius: 0.35rem;
  padding: 2px 4px;
  cursor: pointer;
  position: relative;
}

.person-icon {
  vertical-align: text-top;
  margin-left: 5px;
  margin-top: 1px;
  color: #F1F6FE !important;
  /* background-color: #F1F6FE; */
  background-color: #E8C856;
}

.person-icons {
  vertical-align: text-top;
  margin-left: 5px;
  margin-top: 0px !important;
  color: #F1F6FE !important;
  /* background-color: #F1F6FE; */
  background-color: #61E856;
}

.person-icons {
  width: 17px;
  height: 17px;
}



.popup-person {
  position: absolute;
  top: 100%;
  width: max-content !important;
  right: -10%;
  background-color: #fff;
  border: 1px solid gray;
  /* padding: 10px; */
  z-index: 999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  visibility: visible !important;
}

.popup-prson-content {
  padding: 5px 15px 5px 5px !important;
}

.details-header {
  font-size: 11px;
  padding: 1px 0px;
  font-weight: 400;
  color: #646464;
  width: 100% !important
}

.details-part {
  font-size: 11px;
  padding: 0px 0px;
  font-weight: 700;
  width: 100% !important
}

.person-detail:hover .popup-person {
  visibility: visible;
}

.person-icon {
  width: 17px;
  height: 17px;
  color: rgb(100, 99, 99);
}

.popup-person {
  display: flex;
}

.detail-person {
  display: flex;
}

.details-headers {
  font-size: 13px;
  padding: 2px 5px;
  font-weight: 400;
  width: 100% !important
}

.details-parts {
  font-size: 13px;
  padding: 2px 5px;
  font-weight: bold;

}

.detail-persons {
  border-bottom: 1px solid rgb(230, 226, 226);
}

.detail-model {
  display: flex;

}

.copy-button {
  background-color: white;
  border-radius: 50px;
  border: 1px solid rgb(211, 209, 209);
  /* margin-top: 5px; */
  margin-left: 65px;
  margin-bottom: 5px;
}

.copy-icon {
  margin-top: 5px !important;
}

.project-detail {
  border-radius: 3px;
  box-shadow: 0 2px 6px #0003;
  margin: 3px;
  padding: 5px 5px 5px 15px;
}

.invoice-upload-files {
  border: 1px solid #ddd;
  margin: 10px 30px;
  width: 50%;
  padding: 8px 15px;
  border-radius: 5px;
  background-color: #f9f9fb;
}

.invoice-body-container-content {
  display: flex;
}

.input-group-attachments {
  width: 80% !important;
}

.custom-select {
  display: inline;
  width: 33%;
  padding: 6.005px;
  margin-left: 2px;
  margin-top: 2px !important;
  font-size: 16px;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-select:hover {
  border-color: #80bdff;
}

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.status-completed {
  color: white;
  background-color: #2E8B57;
  border: 1px solid #2E8B57;
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}

.status-cancelled {
  color: white;
  background-color: #F81700;
  border: 1px solid #F81700;
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}

.status-hold {
  color: white;
  background-color: #D4AC0D;
  border: 1px solid #D4AC0D;
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}
.status-active {
  color: white;
  background-color: rgb(41, 128, 185);
  border: 1px solid rgb(41, 128, 185);
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}
.status-overdue{
  background-color: rgb(192, 97, 43);
  color: white;
  border: 1px solid rgb(192, 97, 43);
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}
.status-progress {
  color: white;
  background-color: #df7f26;
  border: 1px solid #df7f26;
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}
.status-not-in-tenure{
  color: white;
  background-color: rgb(49, 54, 57);
  border: 1px solid rgb(49, 54, 57);
  padding: 5.005px 9.997px;
  border-radius: 15px;
  margin-left: 5px;
}

/* Updated styles for Other Information popup */
.other-info-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent overlay */
  z-index: 1000;
  /* Ensure popup is above other content */
}

.other-info-popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Shadow for depth */
}

.input-group {
  /* margin-bottom: 15px; */
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  /* Match existing font weight */
  color: #495057;
  /* Match existing label color */
}

.input-group input,
.input-group textarea {
  /* width: calc(100% - 20px); */
  /* Adjust for padding */
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ced4da;
  /* Match existing input border */
  border-radius: 4px;
  font-size: 14px;
  /* Match existing font size */
}

.input-group textarea {
  resize: vertical;
  width: 317.5px !important;
  /* Allow vertical resizing */
}

.button-groups {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

button.info-btn {
  margin-left: 10px;
  padding: 5.005px 9.997px;
  background-color: #007bff;
  /* Match existing button color */
  color: white;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
}

button.close-btn {
  padding: 5.005px 9.009px;
  background-color: #c82333;
  /* Match existing button color */
  color: white;
  border: none;
  border-radius: 4px;
  margin-left: 5px;
  cursor: pointer;
}

button.close-btn:hover {
  background-color: #c82333;
  /* Darker shade on hover */
}


/* Additional Details Section */
.additional-details-section {
  margin: 20px 30px;
}

.additional-details-section h3 {
  font-size: 18px;
  font-weight: 600;
  color: #212529;
  /* Match existing heading color */
  margin-bottom: 10px;
}

.additional-detail-item {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebeaf2;
  /* Match existing border color */
}

.additional-detail-item:last-child {
  border-bottom: none;
}

.detail-header {
  font-size: 14px;
  font-weight: 500;
  color: #495057;
  /* Match existing detail header color */
  margin-bottom: 5px;
}

.detail-part {
  font-size: 14px;
  font-weight: 700;
  color: #212529;
  /* Match existing detail part color */
  /* margin-top: 5px; */
}

.detail-part::before {
  margin-right: 5px;
}

.detail-part-customer {
  font-size: 14px;
  font-weight: 700;
  color: #007bff;
  /* Match existing customer detail color */
  margin-top: 5px;
}


.other-info-popup .input-group,
.other-info-popup .input-group input {
  width: 100% !important;
}

.attach-popups {
  position: fixed;
  top: 43.5%;
  left: 37.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 18%;
}

.invoice-upload-files div {
  /* margin-bottom: 10px; */
  /* display: flex;
    text-align: center;
    align-items: center; */
}

.attach-popup-preventive {
  position: absolute;
  top: 45%;
  left: 77%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 18%;
}

.form-group-attachments {
  position: relative;
}


.download-icon {
  cursor: pointer;
  margin-left: 10px;
  color: #007bff;
  transition: color 0.3s;
  font-size: 20px;
}

.download-icon:hover {
  color: #0056b3;
  /* Customize the hover color as needed */
}

.reset-button {
  margin-left: 10px;
  border-radius: 6px;
  border: 1px solid #eee;
  background: #f00;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 5.005px 9.997px;

}

.input-group-datepickerss {
  width: 55%;
}

.form-group-date {
  display: flex;
  margin-left: 15px;
}

.form-group-date input {
  border: 1.5px solid #ccc !important;
  border-radius: 4px;
  color: #212529;
  margin-bottom: 5px;
  padding: 7px 25px 7px 10px !important;
  width: 100% !important;
}

/* .status-content {
  justify-content: space-between;
} */
.invoice-upload-files-schedules {
  border: 1px solid #ddd;
  margin: 10px 30px;
  width: 92.5%;
  padding: 12px 18px;
  border-radius: 5px;
  background-color: #f9f9fb;
}
.invoice-body-container-content-schedule {
  /* display: flex; */
  /* display: grid;
  grid-template-columns: repeat(2, 1fr); */
  width: 100%;
}
.detail-part span{
  
}
.file-name-lists{
  display: flex;
}
.current-status{
  padding: 5px 0;
}
.schedules-button{
  display: flex;
  padding-top: 18px;
}
.challan-btns {
  background-color: #408dfb;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 12px;
  margin-bottom: 3px;
  padding: 5.005px 9.997px;
  /* width: 100%; */
}


/* new css */


.css-1u9des2-indicatorSeparator {
  margin-bottom: 0 !important;
  display: none !important;
}