.view-button {
  background-color: #408dfb;
  border: none;
  border-radius: 0.462rem;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  padding: 5.005px 9.997px;
}
.filter-dropdown-container {
  margin-right: 10px;
}
.input-group-datepickers {
  margin-right: 10px;
  border: 1px solid #ddd;
  padding: 5.005px;
  border-radius: 0.25rem;
  line-height: normal;
  height: max-content;
}
.dates {
  border: none;
}
.dates:focus {
  outline: none;
}
.project-dropdown {
  border: none;
  margin-right: 10px;
  /* padding: 5.005px 9.997px; */
}
.filter-dropdown {
  border-radius: 4px;
  padding: 9.5px 15px 9.5px 5px;
  border: 1px solid #ddd;
}
.MuiButton-containedPrimary {
  margin-right: 10px !important;
}


/* Modal Container */
.modal-container {
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 80%;
  overflow-y: auto;
  z-index: 1000; /* Ensure the modal is above other content */
  transition: transform 0.3s ease-in-out; /* Smooth opening transition */
}

/* Modal Header */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;  /* Equal padding bottom */
  margin-bottom: 20px;  /* Spacing between header and body */
  border-bottom: 2px solid #ddd;  /* Border after header */
}

.modal-header h6 {
  font-weight: bold;
  font-size: 1.25rem;  /* Slightly larger font for header */
  margin: 0;
}

.close-btn {
  color: #888;
  &:hover {
    color: #000;
  }
}

/* Modal Body */
.modal-body {
  .details-content {
    display: flex;
    flex-direction: column;
    gap: 15px;  /* Space between each detail */
    font-size: 1rem;
    color: #333;
  }
  
  p {
    width: 50%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
  }
  
  strong {
    color: #555;  /* Darker color for labels */
  }
}

.config {
  text-align: left;
}
.status-schedules div {
  border-radius: 30px;
  padding: 5px 8px;
  color: white;
  text-align: center !important;
}
.project-ridio-status {
  margin-right: 20px;
}
.project-ridio-status label {
  margin-right: 10px;
  font-size: 17px;
  font-weight: 600;
}
.project-ridio-status input {
  margin-right: 7px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-container {
    max-width: 95%;
  }
}
.react-datepicker-popper {
  z-index: 1001 !important;
  line-height: 0;
}
.data-list-header-contents {
  padding: 20px 20px;
  display: flex;
  /* justify-content: space-between; */
}
.project-dropdowns .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 27.3% !important;
}
.project-dropdowns {
  display: flex;
}
.dropdown-container {
  border: none;
  border-radius: 4px;
  font-size: 13px;
  margin-right: 10px;
  padding: 0;
  width: 178px !important;
}
.dropdown-container .css-b62m3t-container {
  width: 100% !important;
}
.css-1nmdiq5-menu {
  z-index: 2 !important;
}
.active-td {
  text-decoration: underline;
  color: #007bff;
}

.search-bar-container {
  display: flex !important;
  align-items: center;
  height: 31px;
}

.project-dropdown-schedules {
  margin-right: 10px;
  width: 13vw;
  /* height: 5vh; */
}

.project-dropdown-schedules .css-yf8vq0-MuiSelect-nativeInput {
  bottom: 5px !important;
}
.css-yjsfm1 > span {
  margin-bottom: 5px !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  margin-bottom: 5px !important;
}
#filter-select-label .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  margin-bottom: 5px !important;
}
label#filter-select-label{
  background-color: white;
  padding: 0px 3px 0px 0px;
}


.custom-select {
  padding: 0px 14px !important;
}

.project-dropdown-schedules .custom-select {
  margin-right: 10px;
  width: 13vw;
}

div#filter-select {
  padding: 7.009px 14px !important;
}
.inquiry-attach-popups {
  position: absolute;
  top: 47px;
  right: 22%;
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: max-content;
}

.inquiry-attach-popupss {
  position: absolute;
  top: 83.5%;
  right: 10%;
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: max-content;
}
.delete-file {
  color: red;
  visibility: hidden;
}
.inquiry-attach-file-container:hover .delete-file {
  visibility: visible;
}
