/* * {
    margin-top: 0 !important;
  }
   */
  .back-user {
    cursor: pointer;
  }
  
  .user-view-header {
    border-bottom: 1px solid #dee2e6;
    display: flex;
    padding: 20px;
    justify-content: space-between;
  }
  
  .user-view-body {
    /* background-color: #f9f9fb; */
    background-color: white;
  }
  
  .user-view-body-container-content {
    background-color: white;
    margin: 20px 40px;
    border-radius: 6px;
    color: #212529;
    /* width: 100%; */
    /* color: #408dfb !important; */
    /* border: 1px solid #ebeaf2; */
  }
  
  .header-title-edit {
    display: flex;
  }
  
  .view-close-button {
    cursor: pointer;
    color: #777;
    font-size: 1.5rem;
    font-weight: bold !important;
    margin-left: 15px !important;
    margin-bottom: -10px !important;
    padding-bottom: 0 !important;
  }
  
  .view-close-button:hover {
    color: black;
  }
  
  .row {
    display: flex;
  }
  
  .text-medium-header {
    font-size: 14px;
    padding: 12px 15px;
    font-weight: 500;
    border-bottom: 1px solid #ebeaf2;
  }
  
  .text-medium-body {
    padding: 16.25px;
    font-size: 13px;
  }
  
  .rows label {
    color: #6c7184;
    margin-bottom: 6.5px;
    width: 18vw !important;
  }
  
  .answer {
    padding: 0 15px;
    margin-bottom: 6.5px;
    width: 40vw !important;
  }
  
  
  
  .person-user-containers {
    background-color: white;
    position: absolute;
    top: 6.9%;
    overflow: hidden;
    width: 30% !important;
  }
  

  
  .main-container {
    width: 100%;
    display: flex;
  }
  
  .user-popups {
    position: fixed;
    top: 54px;
    right: 0;
    bottom: 0;
    width: 990px;
    height: 93% !important;
    background-color: white;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    animation: slideIn 0.10s forwards;
    /* Animation for sliding effect */
    z-index: 3 !important;
    border-left: 1px solid rgb(233, 226, 226);
  }
  
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
  
    to {
      transform: translateX(0);
    }
  }
  
  .person-user-header-content {
    padding: 20px 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .new-button {
    background: #444ed0;
    border: none;
    display: inline-block;
    font-weight: 400;
    color: #FFFFFF;
    padding: 5px 10px;
    font-size: 1rem;
    line-height: 1.6;
    border-radius: 0.25rem;
  }
  
  .person-user-content-container .page-content {
    transition: all 0.3s;
  }
  
  .expanded {
    width: calc(99% - 250px);
    /* Adjust the width based on your needs */
  }
  
  .collapsed {
    width: calc(99% - 50px);
    /* Adjust the width based on your needs */
  }
  
  .new-button a {
    color: #FFFFFF;
  }
  
  .person-user-heading-content-left-side {
    color: #212529;
    font-size: 22px;
    font-weight: 500;
  }
  
  .person-user-table {
    display: flex;
    flex-direction: column;
  }
  
  table {
    border-collapse: collapse;
    display: table;
    border-spacing: 2px;
    border-color: gray;
    color: #212529
  }
  
  thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }
  
  tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
    /* border-top: 1.5px solid #ebeaf2; */
  
  }
  
  th {
    /* position: sticky !important; */
    top: -1px;
    z-index: 1;
    border-top: 1px solid #ebeaf2;
    color: #757383;
    font-size: 11px;
    padding: 8px;
    letter-spacing: 0.7px;
    font-weight: 600;
  }
  
  td {
  
    color: #212529;
    font-size: 13px;
    padding: 12px 8px;
    text-align: center;
    cursor: pointer;
  }
  
  /* Add this to your CSS file */
  .no-users-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    /* Adjust height as needed */
    border-top: 1px solid #ebeaf2 !important;
    box-shadow: 0 -4px 5px -3px rgba(0, 0, 0, .1) !important;
  }
  
  .no-users-message {
    font-size: 18px;
    color: #666;
    text-align: center;
  }
  
  .person-user-table {
    position: relative !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    width: 100%;
    height: 80vh !important;
    margin: 0px !important;
  }
  
  .person-user-table {
    position: absolute;
    max-height: 80vh !important;
  }
  
  .table thead th {
    position: sticky;
    top: -2 !important;
    background-color: transparent;
    color: black;
    z-index: 1;
  }
  
  .person-user-table::-webkit-scrollbar {
    width: 8px;
  }
  
  .person-user-table::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
  }
  
  .person-user-table::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .person-user-scroll-bodys tr:hover {
    background-color: #F1F1F1;
  }
  
  .edit-data-button {
    /* display: none; */
    visibility: hidden;
    border: none;
  }
  
  .edit-button {
    background: #408dfb;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 16px;
  }
  
  .hoverable-row {
    border-bottom: 1px solid #e5e6e8;
    position: relative;
  }
  
  .hoverable-row:hover .edit-data-button {
    /* display: block; */
    visibility: visible;
  }
  
  .edit-popup {
    position: absolute;
    top: 69.5%;
    right: 1%;
    background-color: white;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .edit-popup-button button {
    border: none;
    background: white;
    border-radius: 8px;
    padding: 10px;
  }
  
  .edit-popup-button button:hover {
    border: none;
    background: #408DFB;
    color: white;
    border-radius: 8px;
    padding: 10px;
  }
  
  .action-header {
    padding: 16px 10px;
  }
  
  .action-header-content {
    display: flex;
    justify-content: space-between;
  }
  
  .print,
  .bulk-update,
  .delete {
    background-color: #F5F5F5;
    color: #212529;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 5.05px 9.997px;
    margin-left: 10px;
  }
  
  .print:hover,
  .bulk-update:hover,
  .delete:hover {
    background-color: #f3f0f0;
  }
  
  .print-icon,
  .delete-icon {
    vertical-align: text-bottom !important;
  }
  
  .action-header-close-button {
    background: none;
    border: none;
    font-size: 16px;
  }
  
  .action-header-close-icon {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    margin-top: 5px;
    text-shadow: 0 1px 0 #fff;
  }
  
  .inquiry-checkbox {
    cursor: pointer !important;
    border: 1px solid #00000040;
  }
  
  .inquiry-checkbox:hover {
    border: 1px solid #408DFB !important;
  }
  
  .delete-popup {
    position: absolute;
    top: 14.3%;
    left: 24.5%;
    background-color: white;
  
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
  }
  
  .delete-popup-content {
    padding: 4px;
  }
  
  .delete-popup-content button {
    display: block;
    width: 100%;
    padding: 7px 10px;
    clear: both;
    font-weight: 400;
    color: #333850;
    text-align: inherit;
    white-space: nowrap;
    background-color: #00000000;
    border: 0;
  }
  
  .delete-popup-content button:hover {
    background: #408DFB;
    color: #FFFFFF;
    border-radius: 6px;
  }
  
  .confirm-delete-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* Use rgba to set opacity */
    z-index: 1000;
  }
  
  .confirm-delete-popup-content {
    position: fixed;
    top: 0;
    left: 33%;
    width: 100%;
    height: 100%;
    z-index: 1050;
  }
  
  .confirm-model {
    position: relative;
  }
  
  .confirm-model-content {
    position: relative;
    width: 35%;
    /* Adjust the width as needed */
    padding: 0px;
    /* Add padding as needed */
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    z-index: 1080;
    font-size: 13px;
  }
  
  .confirm-model-content-body {
    padding: 20px;
  }
  
  .confirm-model-content-footer {
    padding: 20px;
    border-top: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: flex-start
  }
  
  .confirm-delete-button {
    background-color: #408dfb;
    color: #fff;
    border: 1px solid #408dfb;
    margin: 0px 3.25px 0px 0px;
    padding: 5.005px 9.99px;
    border-radius: 7.392px;
    ;
  }
  
  .confirm-cancel-button {
    background-color: #F5F5F5;
    color: #212529;
    border: 1px solid#ddd;
    margin: 0px 0px 0px 3.25px;
    padding: 5.005px 9.99px;
    border-radius: 7.392px;
  }
  
  .new-plus-icon {
    vertical-align: text-top !important;
    margin-top: 1.1px;
  }
  
  .sort-button {
    margin-left: 10px;
    vertical-align: middle;
    padding: 5.005px 9.997px;
    border: 1px solid #ddd;
    background-color: #f5f5f5;
    color: #212529;
    font-weight: 400;
    border-radius: 0.462rem;
  }
  
  .sort-button:hover {
    background-color: #f3f0f0;
  }
  
  .sort-icon {
    vertical-align: middle;
    font-size: 16px;
  }
  
  .sort-popup-container {
    position: absolute;
    top: 15%;
    /* Position below the button */
    right: 1.5%;
    background-color: white;
    border: 1px solid #ebeaf2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    text-align: left;
    width: 14%;
    border-radius: 8px;
  }
  
  .sort-popup-content {
    display: inline-block !important;
    padding: 4px;
    min-width: 95% !important;
  }
  
  .sort-by {
    color: #838195;
    min-width: 10%;
    color: #838195;
    padding: 7px 16px 7px 8px;
    font-size: 12px;
  }
  
  .sort-by-button button {
    display: inline-block !important;
    margin: 2px 0;
    border: none;
    color: #333850;
    background: white;
    text-align: left;
    width: 100% !important;
    padding: 7px 20px 7px 10px;
  }
  
  .sort-by-button button:hover {
    display: inline-block !important;
    margin: 2px 0;
    border: none;
    background: #408DFB;
    color: white;
    border-radius: 4px;
    text-align: left;
    width: 100%;
    padding: 7px 20px 7px 10px;
  }
  
  .person-user-heading-content-right-side {
    display: flex;
  }
  
  .delete-item {
    display: inline-block !important;
    margin: 0px 0;
    border: none;
    color: #333850;
    background: white;
    text-align: left;
    width: 100%;
    padding: 7px 20px 7px 10px;
    border-top: 1px solid #ebeaf2;
  }
  
  
  
  .user-header-title {
    color: #212529;
    font-size: 22px;
    font-weight: 500;
  }
  .selected-user {
    background-color: #408dfb;
    color: #fff !important;
  }

  
@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.data-view-popup {
    /* position: fixed; */
    top: 54px;
    right: 0;
    bottom: 0;
    /* width: 990px !important; */
    /* width: 100% !important; */
    width: 75%;
    height: 93% !important;
    background-color: white;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
    animation: slideIn 0.10s forwards;
    /* Animation for sliding effect */
    z-index: 3;
    border: 1px solid rgb(233, 226, 226);
}


.data-view-header {
    border-bottom: 1px solid #dee2e6;
    display: flex;
    padding: 20px;
    justify-content: space-between;
}

.data-view-body {
    /* background-color: #f9f9fb; */
    background-color: white;
}

.data-view-body-container-content {
    background-color: white;
    margin: 20px 40px;
    border-radius: 6px;
    color: #212529;
    /* width: 100%; */
    /* color: #408dfb !important; */
    /* border: 1px solid #ebeaf2; */
}

.header-title-edit {
    display: flex;
}

.view-close-button {
    cursor: pointer;
    color: #777;
    font-size: 1.5rem;
    font-weight: bold !important;
    margin-left: 15px !important;
    margin-bottom: -10px !important;
    padding-bottom: 0 !important;
}

.view-close-button:hover {
    color: black;
}

.row {
    display: flex;
}

.text-medium-header {
    font-size: 14px;
    padding: 12px 15px;
    font-weight: 500;
    border-bottom: 1px solid #ebeaf2;
}

.text-medium-body {
    padding: 16.25px;
    font-size: 13px;
}

.rows label {
    color: #6c7184;
    margin-bottom: 6.5px;
    width: 50%;
}

.answer {
    padding: 0 15px;
    margin-bottom: 6.5px;
}

.selected-row {
    background-color: #f0f0f0 !important;
    /* Gray background color */
}

.inquiry-attach-popup-view {
    position: absolute;
    top: 7.8%;
    right: 13.1%;
    background-color: #fff;
    border: 1px solid #ccc;
    /* padding: 10px; */
    border-radius: 5px;
    z-index: 999;
    width: 25%;
}

.inquiry-file-btn-view {
    background-color: #F5F5F5;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    padding: 5.005px 9.997px;
    margin-right: 10px;
    cursor: pointer;


}

.inquiry-file-icon-view {
    color: black;
    font-size: 16px;
    font-weight: 400;
    padding-top: 4px !important;
}

.view-attachments-file {
    margin-top: 2px !important;
    vertical-align: text-top;
}

.inquiry-attach-file-container {
    padding: 3px;
    margin: 3px !important;
}

.inquiry-attach-file-container:hover {
    padding: 3px;
    background-color: #dfdfe6;
    margin: 3px !important;
    border-radius: 5px;
}

.inquiry-attacth-file-model {
    /* display: flex;
    justify-content: space-between; */
    cursor: pointer;

}

.inquiry-right-side-button {
    display: flex;
    visibility: hidden;
}


.inquiry-attach-file-container:hover .inquiry-right-side-button {
    /* display: block; */
    visibility: visible;
}

.file-delete-btns,
.file-delete-btnss {
    padding: 10px 5px;
    cursor: pointer;
}

.file-download-attachment {
    visibility: hidden;
    font-size: 10px;
    color: #408dfb;
    cursor: pointer;
    margin-right: 10px;
}

.inquiry-attach-file-container:hover .file-download-attachment {

    visibility: visible;
}

.file-footer {
    display: flex;
    cursor: pointer;
    /* margin-left: 10px; */
}

  .main-container {
    width: 100%;
    display: flex;
    margin-left: 0px !important;
  }
  .selected-inquiry {
    background-color: #408dfb;
    color: #fff !important;
  }
  .edit-header-button {
    background-color: #408dfb;
    border: none;
    border-radius: .25rem;
    color: #fff;
    padding: 5.005px 9.997px;
    margin-left: 5px;
}
.edit-view-icon {
    vertical-align: text-top;
    margin: 1.5px 3.5px 0 0;
}
.user-view-body {
  /* background-color: #f9f9fb; */
  background-color: white;
  height: 79.5vh !important;
  overflow-y: scroll;
  /* border: 1px solid lightgray; */
}
.data-list-contents{
  width: 14vw !important;
}
/* .page-contents {
  width: 30vw !important;
} */