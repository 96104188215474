.active span {
  border: 2px solid black;
  border: none;
  border-radius: 0.7rem;
  color: white !important;
  padding: 5px 12px;
  text-transform: capitalize;
  background-color: #4caf50 !important;
}

th.total {
  color: blue !important;
}
th.quantity {
  color: green !important;
}
th.pending {
  color: #d4ac0d !important;
}
td.total {
  color: blue;
  font-weight: 600;
}
td.quantity {
  color: green !important;
  font-weight: 600;
}
td.pending {
  color: #d4ac0d !important;
  font-weight: 600;
}

.import-button-container {
  display: flex;
  justify-content: flex-end;
  /* margin-bottom: 20px; */
  margin-left: 10px;
}

.import-modal {
  width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-title {
  margin-bottom: 20px;
  font-size: 24px;
}

.import-button {
  background-color: #3d94b3;
  border: none;
  color: white;
  padding: 8px 9.997px;
  text-align: center;
  text-decoration: none;
  margin-left: 5px;
  display: inline-flex;
  font-size: 13px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.file-error-message {
  color: red;
  font-size: 10px;
}
.import-modal-body {
  display: flex;
  justify-content: space-between;
}

.import-icons-button {
}
.import-icons-button {
  stroke-width: 0.8px;
  margin-top: 2.5px !important;
  margin-right: 3px;
}
.import-modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.import-modal {
}
.import-button1 {
  background-color: #3d94b3;
  border: none;
  color: white;
  padding: 4.005px 9.997px;
  border-radius: 5px;
}
.view-button {
  position: relative;
  /* width: 9.5vw; */
}
.view-button-inventery {
  width: 9vw;
}