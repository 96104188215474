
.form-group-attachmentss label {
    width: 60%;
    font-size: 13px;
    font-family: "Source Sance Pro", Helvetica;
    font-weight: 400;
    display: inline-block;
    color: #212529;
}
.form-group-attachmentss {
    position: relative;
}

.attach-popup-attachments {
    position: absolute;
    top: 5%;
    right: -2.5%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 999;
    width: 18%;
    max-width: 300px;
}
