@media (min-width: 1100px) and (max-width: 1199px) {
  .entity-text-right {
    width: 91%;
  }
  .entity-text {
    padding: 0px 0px;
    margin: 0px -20px;
  }
  .entity-dashboard-contant-item {
    width: 96px !important;
  }
  .entity-text-title {
    font-size: 14px !important;
  }
  .modal-container {
    top: 50%;
    left: 58%;
  }
  .data-list-tables {
    overflow-x: scroll;
  }
  .delete-popup {
    top: 15.3% !important;
    left: 39.5% !important;
  }
  .data-view-popup {
    width: 60% !important;
  }
  .form-group input {
    margin-top: 5px !important;
  }
  .inquiry-attach-popups {
    top: 52.5%;
    right: 27%;
    width: 24%;
  }
  .inquiry-attach-popupss {
    top: 55.5%;
    right: 1%;
    width: 24%;
  }
  .breakdown-second-popup {
    top: 70% !important;
    left: 58% !important;
  }
  .breakdown-first-popup {
    top: 47% !important;
    left: 58% !important;
  }
  .attach-popup-amc1 {
    width: 20% !important;
    left: 44.5% !important;
    top: 44px !important;
  }
  .attach-popup-amc2 {
    width: 20% !important;
    left: 44.5% !important;
    bottom: -162px !important;
  }
  .vendor-third-popup {
    width: 20% !important;
    left: 42.5% !important;
    bottom: -164px !important;
  }
  .prRequest-popup {
    left: 66%;
  }
  .action-button {
    width: 23%;
  }
  .data-view-header-left-side {
    width: 27%;
  }
  .attach-popup-amc4 {
    left: 1%;
  }
  .Pr-popup {
    top: 72% !important;
    right: 25% !important;
  }
  .User-popup {
    top: 73% !important;
    right: 6% !important;
  }
  .data-list-heading-content-left-side {
    font-size: 18px;
  }
  .page-content.expanded .paginations {
    width: 77% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1299px) {
  .entity-text-left {
    width: 23px;
  }
  .entity-text-right {
    width: 83% !important;
  }
  .entity-text {
    align-self: self-end;
    margin-right: 15px;
  }
  .data-view-popup {
    width: 58% !important;
  }
  .inquiry-attach-popupss {
    top: 48.5%;
    left: 76%;
  }
  .action-button {
    width: 22.5%;
  }
  .refresh-button {
    height: 5vh !important;
  }
  .export-btn {
    height: 5vh !important;
  }
  .data-view-header-left-side {
    width: 56%;
  }
  .Whole-popup {
    top: 70%;
    left: 76%;
  }
  .page-content.expanded .paginations {
    width: 77% !important;
  }
}
@media (min-width: 1300px) and (max-width: 1399px) {
  .entity-text-right {
    margin-left: 26px !important;
  }
  .entity-text {
    margin-right: -32px !important;
  }
  .data-view-popup {
    width: 60% !important;
  }
  input[type="radio"] {
    margin-top: 5px !important;
  }
  .inquiry-attach-popups {
    top: 47px;
    right: 21%;
  }
  .inquiry-attach-popupss {
    position: absolute;
    top: 63.5%;
    right: 7%;
  }
  .data-view-header-left-side {
    width: 72% !important;
  }
  .page-content.expanded .paginations {
    width: 80.5% !important;
  }
}
@media (min-width: 1400px) and (max-width: 1499px) {
  .modal-container {
    left: 61%;
  }
  .inquiry-attach-popupss {
    top: 62.5%;
    right: 8%;
  }
}
@media (min-width: 1900px) and (max-width: 1999px) {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 2vh !important;
  }
  .page-content.expanded .paginations {
    width: 87% !important;
  }
  .Whole-popup {
    top: 80.5% !important;
    right: 20% !important;
  }
}
@media (min-width: 2100px) and (max-width: 2199px) {
  .page-content.expanded .paginations {
    width: 88% !important;
}
}
@media (min-width: 2200px) and (max-width: 2299px) {
  .page-content.expanded .paginations {
    width: 88% !important;
}
}
@media (min-width: 2300px) and (max-width: 2399px) {
  .page-content.expanded .paginations {
    width: 89% !important;
}
}
