* {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  /* font-family: Arial, Helvetica, sans-serif; */
  /* background-color: hsl(0, 0%, 100%); */
  /* color: hsl(0, 0%, 3.9%); */
}


.product-content-container {
  display: flex;
  flex-grow: 1;
  color: white !important;
  width: max-content;
  height: 100vh;
  position: relative;
  position: absolute;
  /* overflow-y: auto; */
  font-family: Arial, Helvetica, sans-serif;

}
.sidebar-container {
  /* background-color: rgb(61 87 151); */
  background-image: linear-gradient(180deg, #233b80 0%, rgba(40, 67, 140, 0.9) 100%);
  transition: width .3s ease-in-out;
  border-right: 1px solid #e4e4e4;
  margin-top: 0;
  margin-bottom: 0;
  /* background: blue; */
  transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out, min-width 0.3s ease-in-out;
}
.heading-start-theme{
  /* padding: 8px; */
  text-align: center;
  display: flex;
  gap: 5px;
}
.heading-start{
  /* padding: 8px; */
  text-align: center;
}
.color-them1{
  background-color: rgb(61 87 151);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}
.color-them2{
  background-color: rgb(61 87 151);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}
.color-them3{
  background-color: rgb(61 87 151);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid white;
}
.heading-border{
  border-bottom: 1px solid #3D5493;
  padding: 17px 10px;
  text-align: center;
}
.search-start {
  /* border: 1px solid #ccc; */
  /* border-radius: 4px; */
  padding: 8.5px 4px;
  /* margin: 20px 13px; */
  /* background-color: rgb(55 81 154 / 90%); */
  /* width: 250px; */
  /* padding: 8px 4px 8px 8px; */
  /* display: flex; */
  display: inline-flex
;
background-color: #3C5392;
border: 1px solid #6576A9 !important;
border-radius: 0.375rem !important;

}
.search-sidebar{
  text-align: center;
  margin: 20px 0px;
  /* padding: 5px 4px; */


}

.search-start input {
  border: none;
  outline: none;
  flex: 1;
  font-size: 16px;
  /* background-image: linear-gradient(180deg, #28438C 0%, rgba(40, 67, 140, 0.9) 100%); */
  /* background-color: rgba(40, 67, 140, 0.9); */
  /* background-color: rgb(55 81 154 / 90%); */
  background-color: #3C5392;
  color: #B1BAD4;

}

.search-start svg {
  margin: 0 8px;
  color: #555;
}


.expand-collapse-icon{
  /* margin-left: 10px; */
  vertical-align: top !important;
  position: absolute;
  right: 6px;
}
.sidebar-hover:hover .expand-collapse-icon{
  /* margin-left: 10px; */
  vertical-align: top !important;
  position: absolute;
  /* right: 17px; */
}
.expand-collapse-icon svg{
  /* margin-left: 10px; */
  vertical-align: top !important;
  /* position: absolute;
  right: 15px; */
}
.sidebar-content {
  /* padding: 5px; */
  width: 250px;
  /* flex-direction: column !important;
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 70% !important;
  transition: width 0.3s ease-in-out; */

}
.sidebar-content1 {
  /* padding-top: 12px; */
  width: 250px;
  flex-direction: column !important;
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 75vh !important;
  transition: width 0.3s ease-in-out;

}

.sidebar-content2 {
  padding-top: 12px;
  width: 50px !important;
  flex-direction: column !important;
  display: block;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 79vh !important;
  transition: width 0.3s ease-in-out;

}

.sidebar-content1::-webkit-scrollbar,
.sidebar-content2::-webkit-scrollbar {
  width: 5px;
}

.sidebar-content1::-webkit-scrollbar-thumb,
.sidebar-content2::-webkit-scrollbar-thumb {
  /* background-color: #b2b0bf; */
  background-image: linear-gradient(180deg, #7a8dc4 0%, rgb(116 141 208 / 90%) 100%);
  border-radius: 10px;
}

.sidebar-content1::-webkit-scrollbar-track,
.sidebar-content2::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-hover a {
  text-decoration: none;
  /* color: #181C2E; */
  color: white !important;
}

.sidebar-hover:hover {
  /* background: #EdEdF7; */
  /* background: #262626; */
  /* background-color: #e4eaec;
  color: black; */
  background-color: #4563b1;
    color: #fff;
  border-radius: 8px !important;
  cursor: pointer;
}
.active .user-icon{
  /* color: #1b6a6e !important; */
  color: #f4f4f8;
}

a {
  text-decoration: none;
  /* color: #181C2E; */
  color: white !important;
}

.sidebar-data{
  display: flex;
  padding: 8px;
  margin: 0px 8.5px;
  margin-bottom: 1px;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  transition: background-color .3s ease;
}

.sidebar-data-icon {
  margin: 2px 6px 0 2px;
  opacity: 1;

}
.sidebar-data-font {
  margin-top: 1.5px;
}
.sidebar-icon {
  /* filter: invert(1); */
  width: 23.5px;
  height: 23.5px;
  /* color: whi; */
  
}
.sidebar-footer {
  /* background: #EdEdF7; */
  /* background: #e9e9e9; */
  background-color: white;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 220px;
  /* height: 40px; */
  height: 46px;
  cursor: pointer;
  /* border-right: 1px solid #e4e4e4 !important; */
  border-top: 1px solid #e4e4e4;
  justify-content: center;
  display: flex;
  align-items: center;
  transition: width 0.3s ease-in-out;
}

.sidebar-footer button {
  background: none;
  color: black;
}

button {
  cursor: pointer;
}
.expand-sidebar {
  color: black;
  margin-bottom: 0px;
  margin-top: 2px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active .sidebar-hover, .active .sidebar-hover:hover, .product-link.active, .product-link.active:hover {
  /* background: #408dfb !important; */
  /* background: #dcebed !important; */
  /* background: linear-gradient(90deg, rgba(111,100,235,1) 41%, rgba(111,100,235,0) 100%);; */
  border-radius: 8px;
  /* background-image: linear-gradient(180deg, #7a8dc4 0%, rgb(116 141 208 / 90%) 100%); */

  background: #51659C;
  /* border: 1px solid #9ce0e4; */
  color: #f4f4f8;
  /* font-weight: 600; */
  /* color: #1b6a6e  !important; */
}
.active-icon .sidebar-icon, .active-icon .sidebar-icon:hover{
  /* background: #408dfb !important; */
  background: linear-gradient(90deg, rgba(111,100,235,1) 41%, rgba(111,100,235,0) 100%);;
  border-radius: 8px;
  color: #1b6a6e  !important;
}
.sidebar-hover{
  display: flex;
}
.user-icon {
  margin: 2px 8px 0 2px;
  opacity: 1;
  font-size: 19px;
  font-weight: bold;

}
.user-font{
  margin-top: 1.5px;
  /* width: 44%; */
}
.category-items{
  margin-bottom: 30px;
}
.user {
  /* color: black; */
  /* color: white; */
  color: #f4f4f8;
  display: flex
;
  padding: 2.5px 4px 2.5px 8px;
  margin: 1px 8px;

  cursor: pointer;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  transition: background-color .3s ease;
}
.active-icon {
  filter: grayscale(100%) brightness(200%) invert(100%);
  font-weight: 900 !important;
}




.customer-clicked {
  color: #408dfb;
  background-color: #ededf7;
  border-radius: 8px;
}

/* Karan Custom CSS */
.settings-icon svg {
  font-size: 19px;
}
.user{
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
.user-icon,.settings-arrow{
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-arrow{
  margin-left: 10px;
}
.settings-submenu .user.sidebar-hover{
  margin-left: 30px;
}
.submenu-menu .submenu {
  margin-left: 14px;
}


.expand-collapse-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}


/* .dot{
  position: 0;
}
.dot::after{
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0%;
  top: 50%;
  transform: translate(-50%,-50%);
  background: #fff;
} */

.header-logos{
  width: 75%;
}
.menu-category-header{
  padding: 7px 4px 11px 8px;
    margin: 7px;
    margin-bottom: 0px;
    /* color: #c1c0c0; */
    color: #A6AFCA;
    font-size: 13px;
    font-weight: 700;
}
.search-start input::placeholder {
  /* color: rgb(163 158 158); */
  color: rgba(255, 255, 255, 0.6);
  opacity: 1; /* Ensures it's fully visible */
}
.dropdown-menu{
  padding-left: 16px;
}
.dropdown-menu .user-icon{
  /* filter: invert(); */
}

.no-results{
  text-align: center;
}






















