.notification-body-contents {
    border-bottom: 1px solid #e4e4e4;
}

.notification-body-contents {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    gap: 60px;
    text-align: left !important;
}
.notification-body-contents {
   
}
.align{
    text-align: left !important;
    width: 50vw;
}