/* Table Container */
.table-container {
  overflow-y: auto;
  max-height: 82vh;
  margin: 30px auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 90%;
  max-width: 1100px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  max-width: 534px
}

.table-container:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  transform: translateY(-3px);
}

/* Table Title */
.table-title {
  text-align: center;
  font-size: 24px;
  color: #34495e; /* Sophisticated grey-blue */
  font-weight: bold;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

/* Access Table */
.access-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px; /* Better spacing between rows */
  font-family: 'Roboto', sans-serif;
}

/* Table Header */
.table-header {
  background-color: #2d3436; /* Modern dark grey */
  color: #ffffff; /* Crisp white text */
  padding: 16px;
  font-size: 17px;
  text-align: left;
  font-weight: 700;
  border-radius: 8px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}


/* Table Cell */
.table-cell {
  padding: 16px;
  font-size: 16px;
  color: #2c3e50; /* Neutral grey-blue */
  text-align: left;
  border-bottom: 1px solid #e1e5e9;
}

/* Menu Item */
.menu-item {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: #505050 !important;
}

/* Checkbox */
.checkbox {
  margin-right: 15px;
  width: 20px;
  height: 20px;
  accent-color: #2d3436; /* Matches header background */
  border: 2px solid #bdc3c7; /* Subtle border for elegance */
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Toggle Button */
.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #7f8c8d; /* Muted grey */
  transition: color 0.3s ease, transform 0.2s ease;
}

.toggle-btn:hover {
  color: #2d3436; /* Match table header for consistency */
  transform: scale(1.1);
}

/* Menu Label */
.menu-label {
  cursor: pointer;
  font-size: 14px;
  color: #242425;
  transition: color 0.3s ease;
}

/* Loading and Error States */
.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
}

.error {
  color: #e74c3c; /* Subtle error red */
}
.user-access-foteer{
  padding: 1px 14px;
}
/* Save Button */
/* Footer Styles */
.user-access-footer {
  display: flex;
margin-left: 15px;
  /* padding: 20px; */
  background-color: #f8f9fa;
  border-top: 1px solid #ddd;
}

.footer-buttons-container {
  display: flex;
  gap: 20px; /* Space between buttons */
}

.save-btn,
.cancel-btn {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.save-btn {
  background-color: #0073e6;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #005bb5;
}

.cancel-btn {
  background-color: #e0e0e0;
  color: #333;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #c0c0c0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .user-access-footer {
    justify-content: center; /* Center buttons on smaller screens */
  }

  .footer-buttons-container {
    flex-direction: column; /* Stack buttons on smaller screens */
    gap: 10px;
  }
}


.save-btn:active {
  background-color: #1a5f8a; /* Darkest blue for active */
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(0);
}

input[type=checkbox] {
  cursor: pointer;
  width: 14px;
  height: 14px;
  border: 1px solid #00000040;
  border-radius: 3px;
  margin-left: 10px;
}

.checkbox:checked {
  accent-color: #0073e6; /* Blue fill when checked */
  transform: scale(1.15);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* Responsive Design */
@media (max-width: 768px) {
  .table-container {
    margin: 20px auto;
    padding: 18px;
  }

  .table-title {
    font-size: 22px;
    margin-bottom: 18px;
  }

  .table-header,
  .table-cell {
    font-size: 15px;
    padding: 14px;
  }

  .checkbox {
    width: 18px;
    height: 18px;
  }

  .menu-label,
  .toggle-btn {
    font-size: 15px;
  }

  .save-btn {
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .table-container {
    margin: 15px auto;
    padding: 15px;
  }

  .table-title {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .table-header,
  .table-cell {
    font-size: 14px;
    padding: 12px;
  }

  .checkbox {
    width: 16px;
    height: 16px;
  }

  .menu-label,
  .toggle-btn {
    font-size: 14px;
  }

  .save-btn {
    padding: 10px 18px;
  }
}
