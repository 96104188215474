.hot-work-template{
    /* background-color: #ff5371  !important; */
    /* border: 1px solid black; */
    /* padding: 10px 15px; */
    padding: 20px 20px;
    /* margin: 10px 15px; */
    /* width: 100vw; */
    font-size: 18px;
    background-color: #DB5E7E !important;
}
/* .hot-work-template-container{
    border: 3px solid black !important;
} */
#section1, #section2{
    border: 3px solid black !important;
    margin: 10px 0;
}
.hot-work-table thead th {
    background-color: #DB5E7E;
}
.hot-work-table th,
.hot-work-table td{
    background: #DB5E7E !important;
    color: black;
    border: 1px solid black !important;
    font-weight: 600 !important;
    font-size: 20px;
}
.hot-work-table td{
    font-size: 16px;
    /* text-align: left !important; */
}
.hot-work-table tr:hover {
    background-color: #DB5E7E !important;
}
.hot-work-table td {
    color: #212529;
    /* font-size: 15px !important; */
    font-size: 18.7px !important;
    font-weight: 600 !important;
    /* padding: 5px 8px; */
    /* padding: 5px 0px 5px 6px; */
    padding: 2px 0px 2px 5px!important;
    text-align: center;
    cursor: pointer;
}
.hot-heading{
    padding-bottom: 12px;
}
.hot-pera{
    font-size: 19px !important;
}
.hot-template-container {
    /* padding: 0px 20px; */
}

.hot-template-container {
    background-color: #DB5E7E !important;
    padding: 8px 8px;
}
.hot-template-container-content {
    display: flex
;
    justify-content: space-between;
    align-items: center;
    /* padding: 15px 0; */
}