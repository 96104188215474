.checkbox-input {
  display: flex;
  margin-right: 10px;
  align-items: start;
}

.form-group-attachments label {
  width: 22%;
  font-size: 13px;
  font-family: "Source Sance Pro", Helvetica;
  font-weight: 400;
  display: inline-block;
  color: #212529;
}

.msmme {
  padding: 10px 0;
}

.attach-popup-amc1 {
  position: absolute;
  /* top: 103%;
    left: 61.5%; */
  top: 39px;
  left: 39.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Added shadow */
  z-index: 999;
  width: max-content;
  max-width: 300px;
}

.attach-popup-amc2 {
  position: absolute;
  /* top: 103%;
    left: 61.5%; */
  bottom: -162px;
  left: 40.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Added shadow */
  z-index: 999;
  width: max-content;
  max-width: 300px;
}

.vendor-third-popup {
  position: absolute;
  /* top: 103%;
    left: 61.5%; */
  bottom: -168px;
  left: 40.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Added shadow */
  z-index: 999;
  width: max-content;
  max-width: 300px;
}

/* .attach-popup-amc4 {
  position: absolute;
  
  top: 519px;
  left: 48.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); 
  z-index: 999;
  
  max-width: 300px;
} */
.Edit-pr-popup {
  position: relative;

  top: 15px;
  left: 48.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 250px;
  /* max-width: 300px; */
}

.attach-popup-amc5 {
  position: absolute;
  /* top: 103%;
    left: 61.5%; */
  top: -120px;
  left: 85.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Added shadow */
  z-index: 999;
  width: 18%;
  max-width: 300px;
}

.form-group-attachments {
  position: relative;
}

.attach-popup-amcs {
  position: absolute;
  top: 14%;
  left: 86.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 18%;
}
.edit-breakdown-popup1{
  position: absolute;
  top: 50%;
  left: 51.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 18%;
}

/* .attach-popup-amcss {
  position: absolute;
  top: 58%;
  left: 55.5%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  
  border-radius: 5px;
  z-index: 999;
  width: 18%;
} */
.attach-popup-amc {
  position: absolute;
  /* bottom: -220px;
  left: 40.5%; */
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 18%;
  max-width: 300px;
}
.fourth-popup{
  left: 40.5%;
  bottom: -160px;
}

.breakdown-first-popup {
  position: absolute;
  top: 52% ;
  left: 53% ;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 18%;
}

.breakdown-second-popup {
  position: absolute;
  top: 74% ;
  left: 53.75%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 9999 !important;
  width: 18%;
}
