.signup-container {
    /* background: #14195dcf !important; */
    background-color: #edf1f7 !important;
    width: 100%;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    align-items: flex-start !important;
    height: 100vh;
  }
  
  .signup-contant {
    width: 25% !important;
    margin: 10px 0 !important;
  }
  
  .msg {
    font-weight: 470;
    font-size: 15px;
  }
  
  .btn-link-otp {
    text-decoration: none;
    color: blue;
  }
  
  .btn-link-otp:hover {
    color: rgb(204, 16, 16);
    text-decoration: underline;
  
  }
  
  .checkmark {
    position: absolute;
    top: 50%;
    right: 10px;
    /* Adjust this value for the desired spacing from the right */
    transform: translateY(-50%);
    color: green;
  }
  
  img.signup-logo {
    width: 100% !important;
    height: 30% !important;
    margin: 15px 0 10px 0 !important;
  }
  
  .signup {
    width: 100% !important;
    margin-bottom: 5px !important;
    background: white;
    border-radius: 5px;
  }
  
  .signup-card {
    margin: 5px 0 10px 5px;
    text-align: left !important;
    justify-content: left !important;
    display: flex;
  }
  
  .signup-lable {
    text-align: left !important;
    justify-content: left !important;
    display: flex;
    margin: 5px 0;
  }
  
  .form-groupss {
    margin: 0 7px 12px 7px;
  }
  
  .signup-btn {
    text-align: left !important;
    justify-content: left !important;
    display: flex;
    margin: 25px 0 5px 0 !important;
    /* background: #FFC107; */
  
  
  }
  
  .continue {
    width: 100% !important;
    margin: 0 10px !important;
    background: #ff9c34;
    /* background-color: #14195d !important; */
    /* color: white; */
    border: none;
    border-radius: 7px;
    padding: 5px 0;
  }
  
  .continue1 {
    width: 96.5% !important;
    margin: 0 0 !important;
    background: #FFC107;
    /* background-color: #14195d !important; */
    /* color: white; */
    border: none;
    border-radius: 7px;
    padding: 5px 0;
  }
  
  .last {
  
    margin-top: 10px !important;
  }
  
  .last a {
    text-decoration: none !important;
  }
  
  .last a:hover {
    color: rgb(204, 16, 16);
    text-decoration: underline !important;
  
  }
  
  .otp {
    width: 100% !important;
    margin-bottom: 20px !important;
    background: white;
    border-radius: 5px;
  }
  
  .sign {
    width: 100% !important;
    margin-right: 10px !important;
    background: #FFC107;
    border: none;
    border-radius: 7px;
    padding: 5px 0;
  }
  
  .forgot-container {
    /* background: #14195dcf !important; */
    background-color: #14195d !important;
    width: 100%;
    text-align: center !important;
    justify-content: center !important;
    display: flex !important;
    align-items: flex-start !important;
    height: 100vh;
  }
  
  .forgot-contant {
    width: 25.5% !important;
    margin: 20px 0 !important;
  }
  
  img.forgot-logo {
    height: 100px;
    margin: 0 0 17px 0 !important;
  }
  
  .forgot {
    width: 100% !important;
    margin-bottom: 5px !important;
    background: white;
    border-radius: 5px;
    padding: 0;
  }
  
  .card-body {
    padding: 23px 12px !important;
    margin: 0px;
  }
  
  .forgot-lable {
    text-align: left !important;
    justify-content: left !important;
    display: flex;
    margin: 5px 2px;
  }
  
  .forgot-card {
    align-items: left !important;
    text-align: left !important;
    display: flex !important;
    justify-content: left !important;
    margin: 0 8px !important;
  }
  
  .password {
  
    text-align: left !important;
    justify-content: left !important;
    display: flex;
  }
  
  .small-font {
    margin: 8px 0px;
    text-align: left !important;
    justify-content: left !important;
    display: flex;
    align-items: left !important;
  
  }
  
  .text-center {
    margin: 10px 0 0 0 !important;
  }
  
  .signup-form-groups {
    /* display: flex; */
    margin-bottom: 5px;
  }
  
  .signup-form-groups label {
    width: 100%;
    font-size: 16px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    color: #212529;
  }
  
  .signup-form-groups input {
    width: 90% !important;
    padding: 7px 25px 7px 10px !important;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 5px;
    color: #212529;
  }