.permit-form {
  padding: 24px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.form-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.serial-number {
  text-align: right;
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.request-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.date-time {
  text-align: right;
}

.form-field {
  margin-bottom: 16px;
  display: flex;
  align-items: anchor-center;
}

.field-label {
  margin-bottom: 8px;
}
.line1-isolation {
  width: 80%;
}

.input-line {
  border-bottom: 1px solid #000;
  width: 100%;
  height: 24px;
  margin-bottom: 4px;
}

.operational-requirement {
  text-align: right;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.signature-field {
  width: 30%;
  text-align: center;
}

.signature-line {
  border-top: 1px solid #000;
  margin-bottom: 4px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-header,
  .request-header {
    flex-direction: column;
  }

  .serial-number,
  .date-time {
    text-align: left;
    margin-top: 8px;
  }

  .signature-section {
    flex-direction: column;
  }

  .signature-field {
    width: 100%;
    margin-bottom: 16px;
  }
}

p.field-label.line2-isolation {
  width: 60%;
}
p.field-label.line3-isolation {
  width: 76%;
}
p.field-label.line4-isolation {
  width: 100%;
}