.data-edit-form-container {
    background-color: white;
    position: absolute;
    top: 6.9%;
    overflow: hidden;
    width: 87%;
    /* padding: 0 20px 50px */
}

.data-edit-form-container {
    transition: all 0.3s;
}

.data-edit-form-header-content {
    display: flex;
    justify-content: space-between;
}

.data-edit-form-header {
    padding: 17px 20px;
}

.data-edit-form-heading-content-left-side {
    color: #212529;
    font-size: 22px;
}
.data-edit-form-heading-content-right-side {
    display: flex;
}
.data-edit-form-body {
    /* overflow-y: auto; */
    padding: 17px 20px;
    height: 70vh !important;
    overflow-y: scroll;
    /* background-color: gray; */
}

.data-edit-form-body::-webkit-scrollbar {
    width: 8px;
}

.data-edit-form-body::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 10px;
}


.data-edit-form-body::-webkit-scrollbar-track {
    background-color: transparent;
}

.header-separator {
    border-bottom: 1px solid #eee;
}
.data-edit-form-save-button {
    background: #408DFB;
    padding: 5.005px 9.997px;
    border: 1px solid #408DFB !important;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 13px;
}

.data-edit-form-cancel-button {
    background-color: #F5F5F5;
    padding: 5.005px 9.997px;
    border: 1px solid rgb(197, 191, 191);
    border-radius: 5px;
    font-size: 13px;
    color: #212529;
    font-size: 13px;
    margin-left: 10px;
}

.data-edit-form-save-button:hover {
    background-color: #0870df;
}

.data-edit-form-cancel-button:hover {
    background-color: #f3f0f0;
}


.Change-password {
    border: none;
    padding: 5.005px 9.997px;
    border-radius: 5px;
    background-color: #408DFB;
    color: #FFFFFF;
    border: 1px solid #408DFB !important;
    font-size: 13px;
}

.change-password-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    /* width: 100%;
    height: 100%; */
    align-items: center;
    justify-content: center;
    z-index: 9999;
    height: auto;
}

.change-password-container-content {
    /* Background and border */
    background-color: #fff;
    top: 50;
    left: 50;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    /* padding: 20px; */
    width: 30vw !important;
    height: auto;
}

.change-password-header-content {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.change-password-header {
    border-bottom: 1px solid #eee;
}
.change-password-container-body{
    padding: 17px 18px;
    height: 40%;
}
.form-group-popup {
    display: flex;
    margin: 15px 0px;
}
.form-group-popup label {
    width: 32%;
    font-size: 13px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 400;
    display: inline-block;
}
.input-group-popup {
    display: inline-block !important;
}

.input-group-popup {
    width: 50%;
}
.form-group-popup input {
    width: 100% !important;
    padding: 5px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group-popup input:focus {
    outline: 1.5px solid #5da0bd;
}
.form-group-popup input:hover {
    border: 1.5px solid #5da0bd !important;
    box-shadow: 0 -4px 5px -3px rgba(67, 89, 131, 0.1) !important;
}
.footer-popup{
    border-top: 1px solid #eee;
}
.footer-popup{
   padding: 13px 20px;
   margin-top: -10px !important;
}
.edit-person-user-save-button {
    background: #408dfb;
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    padding: 5.005px 9.997px;
}
.edit-person-user-cancel-button {
    background-color: #f5f5f5;
    border: 1px solid #c5bfbf;
    border-radius: 5px;
    color: #212529;
    font-size: 13px;
    margin-left: 10px;
    padding: 5.005px 9.997px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  .modal h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
  }
  
  .modal p {
    margin: 20px 0;
    font-size: 1rem;
    color: #555;
  }
  
  .modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .modal-buttons button:first-child {
    background-color: #007bff;
    color: white;
  }
  
  .modal-buttons button:first-child:hover {
    background-color: #0056b3;
  }
  
  .modal-buttons button:last-child {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .modal-buttons button:last-child:hover {
    background-color: #bdbdbd;
  }
  
  .attchment-file-list{
    margin-left: 300px;
  }
  /* .change-password-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .change-password-container-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px; /
    max-width: 90%;
  }
   */