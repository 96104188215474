@media (min-width: 1100px) and (max-width: 1199px) {
    .notification-popup-content {
        position: fixed;
        top: 36.5%;
        right: 19.5%;
        transform: translate(28%, -48%);
       
    }
  }
  @media (min-width: 1200px) and (max-width: 1299px) {
    .notification-popup-content {
        position: fixed;
        top: 36.5%;
        right: 18%;
        transform: translate(28%, -48%);
       
    }
  }
  @media (min-width: 1300px) and (max-width: 1399px) {
    .notification-popup-content {
        position: fixed;
        top: 36.5%;
        right: 17.5%;
        transform: translate(28%, -48%);
       
    }
  }
  @media (min-width: 1400px) and (max-width: 1499px) {
    .notification-popup-content {
        position: fixed;
        top: 36.5%;
        right: 17.5%;
        transform: translate(28%, -48%);
       
    }
  }
  @media (min-width: 1900px) and (max-width: 1999px) {
    .notification-popup-content {
        position: fixed;
        top: 36.5%;
        right: 16.5%;
        transform: translate(28%, -48%);
       
    }
  }
  @media (min-width: 2100px) and (max-width: 2199px) {
   
  }
  @media (min-width: 2200px) and (max-width: 2299px) {
   
  }
  @media (min-width: 2300px) and (max-width: 2399px) {
   
  }
  