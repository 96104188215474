.add-company-button {
    margin-top: 10px;
    background-color: white;
    color: #007bff;
    border-top: 1px solid #ccc !important;
    border: none;
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
}

.add-company-button:hover {
    margin-top: 10px;
    background-color: #f5f5f5;
    color: #007bff;
    border-top: 1px solid #ccc !important;
    border: none;
    width: 100%;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    text-align: left;
}
.new-plus-circle {
    vertical-align: text-top;
    font-weight: bold;
    font-size: 16px;
    margin-right: 5px;
}