  .login-container {
    background: #edf1f7;
    width: 100% !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-content {
    background-color: white;
    width: 93%;
    text-align: center !important;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }

.login-content-left {
  width: 50%;
  background: #1f3d87 !important;
  border-radius: 5px;
  height: 83vh;
}

.login-content-right {
  width: 50%;
  border-left: 1px solid rgb(235, 229, 229);
  text-align: center !important;
}

/* .login-content-left-content {
  background: #1f3d87 !important;
    border-radius: 5px;
    height: 83vh;
    width: 100vh;
} */



.login-image {
  height: 20%;
  width: 10%;
  margin-top: 40px;
  margin-bottom: 20px;
}

.login-logo {
  width: 45%;
  height: 30% !important;
  /* margin-bottom: 10px; */
}

.login-body {
  text-align: center;
  padding: 30px 50px;
}

.login-body .welcome {
  font-size: 25px;
  color: #1f3d87;
  font-size: 2.25rem;
  font-family: Open Sans, sans-serif;
  font-weight: 700;
  margin-bottom: 5px;
}

.login-body p {
  color: #394a7e;
  font-family: Open Sans, sans-serif;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.login-form-table {
  margin-bottom: 20px;
  text-align: left;
}

.login-form-table label {
  color: #9ca2ad;
  text-align: left !important;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Source Sance Pro, Helvetica;
  font-size: 13px;
  font-weight: 400;
}

.login-form-table input {
  width: 95%;
  background: #f9f9fb;
  border-radius: 6px;
  border: solid 1px #ddd;
  padding: 10px 10px;

}

.login-form-table input:hover {
  border: 1px solid #b0bfc6 !important;
}

.login-form-table input:focus {
  /* outline: 0.5px solid #5da0bd; */
  outline: 0.5px solid #a1bcc7 !important;
}

.login-button {
  text-align: left;
  padding-top: 25px;
}

.login-button button {
  background-color: #1f3d87;
  color: white;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 10px 0
}

.login-alert {
  justify-content: center;
  display: flex;
  text-align: center;
}

.alert-danger {
  /* color: white; */
  color: #dc3545;
  padding: 2px 10px;
  font-size: 13px;
  font-weight: bold;
}

.alert-success {
  color: white;
  color: #28a745;
  padding: 2px 10px;
  font-size: 13px;
  font-weight: bold;
}
.invalid-feedbacks{
  color: #dc3545;
  font-size: 14px;
  padding-top: 5px;
}

.loads {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 3.5px solid #fdfefe;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  margin-left: 285px !important;
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* .btn-link {
    color: blue;
    font-weight: bold;
    cursor: pointer;
  }
  
  .signup-para {
    display: flex;
    justify-content: center;
  } */