.loader-containers1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: #000;  */
    height: 100vh;
    width: 100vw;
  }
  
  .spinner {
    position: relative;
    width: 64px;
    height: 64px;
  }
  
  .spinner-segment {
    position: absolute;
    width: 8px;
    height: 24px;
    background: black; /* Adjust color */
    border-radius: 4px;
    transform-origin: 50% 100%;
    opacity: 0;
    animation: fade 1.2s linear infinite;
  }
  
  .spinner-segment:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: 0s;
  }
  .spinner-segment:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: 0.1s;
  }
  .spinner-segment:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: 0.2s;
  }
  .spinner-segment:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: 0.3s;
  }
  .spinner-segment:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: 0.4s;
  }
  .spinner-segment:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: 0.5s;
  }
  .spinner-segment:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: 0.6s;
  }
  .spinner-segment:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: 0.7s;
  }
  .spinner-segment:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: 0.8s;
  }
  .spinner-segment:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: 0.9s;
  }
  .spinner-segment:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: 1s;
  }
  .spinner-segment:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 1.1s;
  }
  
  @keyframes fade {
    0%, 39%, 100% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
  }
  