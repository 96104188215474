.notification-popup-model {
    position: fixed;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    /* border: 1px solid #e4e4e4 !important; */
}
.notification-popup-content {
    position: fixed;
    top: 36.5%;
    right: 14%;
    transform: translate(28%, -48%);
    background: white;
    border-radius: 10px;
    width: 420px;
    height: 60%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    border: 1px solid #e4e4e4 !important;
}
.notification-popup-model-content-header{
    display: flex;
    justify-content: space-between;
    padding: 14px 16px;
}
.notification-body{
    /* padding: 10px; */
    height: 44.5vh;
    overflow-y: scroll;
}
.notification-body-content{
    display: flex;
    justify-content: space-between;
    padding: 12px;
    gap: 15px;
}
.notification-popup-model-content-header-left-part{
    font-size: 17px;
    font-weight: 600;
}
.notification-dot{
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background: #2da6e8;
    box-shadow: 0 3px 10px #2da6e847;
}
.letter-background-notification {
    /* vertical-align: middle; */
    /* padding: 5.005px 9.997px; */
    border: 1px solid #9ce0e4;
    background-color: #9ce0e4;
    color: white;
    font-weight: 400;
    height: 39px;
    vertical-align: middle;
    border-radius: 50%;
    width: 39px;
    position: relative;
}
.first-letter-n {
    /* padding: 5.5px 8.5px; */
    font-size: 16px;
    position: absolute;
    top: 6px;
    left: 9px;
}
.notification-body-content{
    border-bottom: 1px solid #e4e4e4;
}
.notification-body-content:hover{
   background: #d0d3d4;
}
.notification-title-message{
    text-align: left !important;
}
.notification-title{
    font-size: 15px;
}
.notification-message{
    font-size: 12px;
}
.view-notification{
    background: none;
    border: none;
}
.notification-body::-webkit-scrollbar {
    width: 8px;
  }
  
  .notification-body::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
  }
  
  .notification-body::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .bg-blue {
    background-color: blue;
  }
  
  .bg-gray {
    background-color: gray;
  }
  
  .notification-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  .footers-notification{
    padding: 14px 16px;
  }
  .view-notification{
font-size: 15px;
  }