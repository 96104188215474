.entity-dashboard {
    background-color: #f9f9fb;
    border-radius: 10px;
    margin: 10px;
  }
  
  .entity-dashboard-contant {
    padding: 25px 30px;
    display: flex;
  }
  
  .entity-dashboard-contant-item {
    padding: 0 25px 0 45;
    width: 150px;
    position: relative;
  }
  
  .entity-dashboard-contant-item::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 50%;
    background: #ddd;
    bottom: 0;
    right: 0;
    transform: rotate(11deg);
  }
  
  .entity-dashboard-contant-item::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 50%;
    background: #ddd;
    top: 0;
    right: 0;
    transform: rotate(-11deg);
  }
  
  .entity-text-left {
    display: flex;
    justify-content: space-between !important;
    text-align: center;
    align-items: center;
  }
  
  .entity-text-right {
    display: flex;
    justify-content: space-between !important;
    text-align: center;
    align-items: center;
    width: 80%;
    margin-left: 50px;
  }
  
  .entity-text-title {
    color: #4c526c;
    font-size: 18px;
  }
  
  .entity-text-title-total {
    font-weight: bold;
    /* cursor: pointer; */
  }
  .data-list-scroll-bodyss {
    height: 59.5vh !important;
  }
  .data-list-tables{

  }
  .data-list-tables {
    position: absolute;
    max-height: 59.5vh !important;
}

.data-list-tables {
    position: relative !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  width: 100%;
  height: 59.5vh !important;
  margin: 0px !important;
}
.data-list-tables {
    display: flex;
    flex-direction: column;
}
.data-list-tables::-webkit-scrollbar {
    width: 8px;
  }
  
  .data-list-tables::-webkit-scrollbar-thumb {
    background-color: #b2b0bf;
    border-radius: 10px;
  }
  
  .data-list-tables::-webkit-scrollbar-track {
    background-color: transparent;
  }