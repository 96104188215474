.input-group-datepicker {
    width: 25% !important;
}
.react-datepicker-wrapper {
    width: 100%;
}
.form-group-attachment {
    display: flex;
    margin: 10px 0 !important;

}
.form-group-attachment label {
    width: 22%;
    font-size: 13px;
    font-family: 'Source Sance Pro', Helvetica;
    font-weight: 400;
    display: inline-block;
}

.form-group-attachment input {
    width: 100% !important;
    /* padding: 5px 8px; */
    padding: 7px 25px 7px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;

}

.input-group-attachment {
    width: 25% !important;
}
.inquiry-file-btn {
    background: #007bff;
    color: white;
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    padding: 9.005px 9.009px !important;
    font-size: 12px;
    cursor: pointer;
    border: none;
    height: 22px;
    /* border: 1px solid #007bff; */

}
.attach-popup {
    position: fixed;
    top: 68.5%;
    left: 52%;
    transform: translateX(-50%);
    background-color: #fff;
    border: 1px solid #ccc;
    /* padding: 10px; */
    border-radius: 5px;
    z-index: 999;
    width: 18%;
}

.attach-popup-contant-body {
    padding: 2px 10px;
    height: auto;
    /* overflow-y: scroll !important; */
    overflow-x: hidden;
}

.attach-file-container {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
}

.file-delete-btn {
    text-align: center;
    padding: 8.8px 20.4px;
}

.file-delete-icon {
    font-size: 19px;
    color: red;
    cursor: pointer;
}

.attachment-header {
    background-color: #F9F9Fb;
    border-bottom: 1px solid #ccc;
    padding: 8px 12px;
    justify-content: space-between;
    display: flex;
    text-align: center;
    align-items: center;
}

.attach-file-show {
    font-size: 15px;
    padding: 6px 10px;
}

.attach-file-container:hover {
    background-color: rgb(226, 223, 223);
    border-radius: 5px;

}

.file-close-icon {
    color: red;
    vertical-align: middle;
    font-size: 18px;
}

.fiile-close-btn {
    border: none;
    background: none;
}

.text-attachment {
    font-size: 13px;
    color: #212529;
    text-align: left !important;
}

.text-attachment-file {
    font-size: 11px;
    text-align: left;
}

.attach-scrroll-body {
    /* Set a specific height for the container */
    height: 115px;
    overflow-y: scroll;

}

.file-actions-container {
    text-align: left !important;
    padding: 5px 8px;
}

.attach-popup-contant-body-container {
    /* Apply overflow-y:auto to enable vertical scrollbar when content exceeds height */
    overflow-y: scroll;
}


.challan-btn {
    background-color: #408dfb;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    height: 39px;
    margin-bottom: 3px;
    padding: 4.005px 9.997px;
    /* width: 30%; */
}
.react-datepicker__close-icon{
    display: none !important;
}
.attach-popup-amc4{
    position: relative;
  top: 83.5%;
  right: 27%;
  background-color: #fff;
  border: 1px solid #ccc;
  /* padding: 10px; */
  border-radius: 5px;
  z-index: 999;
  width: 25%;
}

.input-group-check {
    /* width: 27.5% !important; */
}