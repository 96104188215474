.email-comfig-button{
    background-color: green;
    border-radius: 4px;

    margin-left: 10px;
    vertical-align: middle;
    padding: 5.005px 9.997px;
    border: 1px solid green;
    /* background-color: #f5f5f5; */
    color: #fff;
    font-weight: 400;
    border-radius: 0.462rem;
}
.email-comfig-button-icon{
    vertical-align: middle;
    font-size: 16px;
}

.email-config-container {
    position: relative;
  }
  
  .email-config-button {
    cursor: pointer;
    border: none;
    background: none;
  }
  
  .popup-container-email {
    position: absolute;
    top: 109px;
    right: 109px;
    background-color: white;
    border: 1px solid #ebeaf2;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 999;
    text-align: left;
    width: 14% !important;
    border-radius: 8px;
  }
  
  .popup-container-content {
    /* position: absolute;
    top: 40px;
    right: 0;
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */

    padding: 10px;
  }
  
  .popup-button {
    display: block;
    width: 100%;
    margin: 5px 0;
    padding: 5px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 3px;
    cursor: pointer;
    text-align: left;
  }
  
  .popup-button:hover {
    background: #0056b3;
  }
  