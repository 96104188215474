.checklist-container {
    padding: 20px;
  }
  
  .checklist-item {
    margin-bottom: 10px;
  }
  
  .checklist-options {
    display: flex;
    gap: 10px;
    margin-top: 5px;
  }
  .checklist-item label{
margin-bottom: 10px;
margin-right: 30px;
  }

  .data-form-next-button {
    background: #408dfb;
    padding: 4.005px 9.997px;
    border: 1px solid #408dfb !important;
    border-radius: 5px;
    color: #ffffff;
    margin-left: 10px;
    font-size: 13px;
}
.radio-buttons input{
  margin-right: 10px;
}
.radio-buttons{
  margin-left: 22px;
}
.cold-error{
  color: red;
  text-align: center;
}

.added-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.added-tag-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 15px;
}

.added-tag-item span {
  margin-right: 5px;
}

.remove-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: red;
  padding: 0;
}

.remove-btn-icon {
  font-size: 16px;
  vertical-align: super;
}
.form-group-tag-no Select{
  margin-right: 5px;

}

.form-group-tag-no .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  margin-right: 5px !important;
}
.input-groupv {
  width: 27.5% !important;
  display: flex
;
}
.data-form-reject-button {
  background-color: red;
  padding: 5.005px 9.997px;
  border: 1px solid red;
  border-radius: 5px;
  font-size: 13px;
  color: white;
  font-size: 13px;
  /* margin-left: 10px; */
}
.dropdowns{
  display: flex;
  margin: 15px;
  
}
.dropdowns label{
  width: 20%;
  font-weight: bold;
 

}
.dropdowns .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  width: 27.3% !important;
}
.dropdowns input{
width: 100%;
}
.data-form-body-permit {
  padding: 17px 20px;
  height: 65vh !important;
  overflow-y: scroll;
  /* background-color: gray; */
}
.data-form-body-permit::-webkit-scrollbar {
  width: 8px;
}

.data-form-body-permit::-webkit-scrollbar-thumb {
  background-color: #b2b0bf;
  border-radius: 10px;
}

.data-form-body-permit::-webkit-scrollbar-track {
  background-color: transparent;
}
.permit-input textarea {
  resize: vertical;
  width: 334.5px !important;
}
.tab-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.status-icon {
  background-color: green;
  color: white;
  font-size: 10px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  margin-left: 8px;
}

/* 
.input-group-select .css-13cymwt-control{
  width: 27.3% !important;
}
.input-group-select input{
  width: 27.3% !important;
} */

.approverd-button-permit{
  margin-right: 10px;
}