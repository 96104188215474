.container {
  padding: 20px;
}

.file-input {
  margin-bottom: 20px;
}

.excel-table {
  width: 100%;
  border-collapse: collapse;
}

.excel-table th,
.excel-table td {
  border: 1px solid #ccc;
  padding: 5px 8px;
  text-align: center !important;
}

.excel-table th {
  background-color: #f4f4f4;
}

.excel-table thead th {
  /* background-color: #fff; */
  color: #000;
  position: sticky;
  top: -2 !important;
  z-index: 1;
}

td.sticky-col {
  position: sticky;
  background: white;
  z-index: 1;
}









.data-list-table-boq {
  position: absolute;
  max-height: 76vh !important;
}

.data-list-table-boq {
  position: relative !important;
  overflow-y: scroll !important;
  width: 100%;
  height: 74vh !important;
  margin: 0px !important;
}
.data-list-table-boq {
  display: flex
;
  flex-direction: column;
}


/* Add this CSS to your stylesheet */

.excel-table-container {
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
  max-width: 100%;
  max-height: 80vh;
  border: 1px solid #ddd;
}

.excel-table {
  border-collapse: collapse;
  width: 100%;
  white-space: nowrap;
}

.excel-table th,
.excel-table td {
  /* padding: 8px; */
  border: 1px solid #ddd;
  background-color: #fff;
}

/* Make the thead sticky */
.excel-table thead tr th {
  position: sticky;
  /* top: 0; */
  z-index: 10;
  background-color: #f2f2f2;
}
.excel-table thead .sticky-back{
  background-image: linear-gradient(180deg, #233b80 0%, rgba(40, 67, 140, 0.9) 100%);
  color: white;
}

/* For the second header row */
.excel-table thead tr:nth-child(2) th {
  top: 28px; /* Adjust based on the height of your first header row */
}

/* Make the left columns sticky */

.excel-table tbody {
background: white;
}

.excel-table .sticky-col {
  position: sticky;
  /* background-color: #fff; */
  z-index: 5;
}

/* Position each sticky column */
/* .excel-table td:nth-child(1),
.excel-table th:nth-child(1) {
  left: 0;
  z-index: 15;
  width: 10% !important;
}

.excel-table td:nth-child(2),
.excel-table th:nth-child(2) {
  left: 95px; 
  z-index: 14;
}

.excel-table td:nth-child(3),
.excel-table th:nth-child(3) {
  left: 181px; 
  z-index: 13;
}

.excel-table td:nth-child(4),
.excel-table th:nth-child(4) {
  left: 258px; 
  z-index: 12;
}

.excel-table td:nth-child(5),
.excel-table th:nth-child(5) {
  left: 333px; 
  z-index: 11;
}

.excel-table td:nth-child(6),
.excel-table th:nth-child(6) {
  left: 404px; 
  z-index: 10;
} */

/* For the corner cells (intersection of sticky headers and sticky columns) */
.excel-table thead th.sticky-col {
  z-index: 20;
}

/* Style for inputs in table cells */
.excel-table input[type="number"] {
  width: 105px;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


/* .boq-th1{
  max-width: 10vw;
}
.boq-th2{
  max-width: 15vw;
}

.boq-th3{
  max-width: 15vw;
}

.boq-th4{
  max-width: 15vw;
}

.boq-th4{
  max-width: 15vw;
}

.boq-th5{
  max-width: 15vw;
}

.boq-th6{
  max-width: 15vw;
} */









/* Table container styles */

.data-list-table {
  position: relative;
  width: 100%;
}

/* Table styles */
.excel-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.excel-table th,
.excel-table td {
  border: 1px solid #e5e7eb;
  padding: 8px;
  text-align: left;
  min-width: 120px;
}

.excel-table th {
  background-color: #f9fafb;
  font-weight: 600;
  position: sticky;
  top: 0;
  z-index: 10;
}

.excel-table th:nth-child(n + 7) {
  z-index: 9;
}

/* Sticky columns */
/* .sticky-col {
  position: sticky;
  background-color: #fff;
  z-index: 20;
} */

.sticky-back {
  background-color: #f9fafb !important;
}

.boq-th1 {
  left: 0;
  z-index: 21;
  min-width: 120px;
}

.boq-th2 {
  left: 120px;
  z-index: 21;
  min-width: 250px;
}

.boq-th3 {
  /* left: 370px; */
  z-index: 21;
  min-width: 80px;
}

.boq-th4 {
  /* left: 450px; */
  z-index: 21;
  min-width: 100px;
}

.boq-th5 {
  /* left: 550px; */
  z-index: 21;
  min-width: 100px;
}

.boq-th6 {
  /* left: 650px; */
  z-index: 21;
  min-width: 180px;
}

/* Input styles */
.qty-input {
  width: 100%;
  padding: 6px;
  border: 1px solid #d1d5db;
  border-radius: 4px;
  font-size: 14px;
}

.qty-input:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
}

/* Planned quantity styles */
.planned-qty {
  font-size: 12px;
  color: #6b7280;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 5PX 10px;
}

.planned-dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #fbbf24;
}

/* Responsive adjustments */




.legend-container {
  display: flex;
  padding: 12px 16px;
  gap: 24px;
  margin-bottom: 12px;
  margin-left: 16px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
}

.legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 6px;
}

.legend-color.actual-more {
  background-color: #10b981;
}

.legend-color.actual-less {
  background-color: #ef4444;
}

.legend-color.actual-equal {
  background-color: #3b82f6;
}

.legend-color.no-value {
  background-color: #fbbf24;
  /* border: 1px solid #9ca3af; */
}


.planned-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.green-dot {
  background-color: #10b981;
}

.red-dot {
  background-color: #ef4444;
}

.blue-dot {
  background-color: #3b82f6;
}

.yellow-dot {
  background-color: #fbbf24;
}
