.input-error {
    border: 1px solid red;
  }
  
  .error-message {
    color: red;
    font-size: 0.875rem; /* Slightly smaller text for error message */
    margin-top: 5px;
  }
  .mate-code-button input::placeholder {
    font-size: 18px; /* Set the font size for the placeholder */
    color: #999; /* Optionally set the color of the placeholder */
}












/* Styles for the input field container */
.mate-code-button {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin: 20px; /* Add some margin around */
}

/* Styles for the input field */
.mate-code-button input {
  font-size: 2rem; /* Slightly larger font size */
  padding: 10px 15px; /* Add padding inside the input */
  border: 2px solid #ccc; /* Light border color */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  background-color: #f9f9f9; /* Light background color */
  transition: all 0.3s ease; /* Smooth transition for focus effect */
  text-align: center; /* Center text inside the input */
  width: 60%;
  margin-top: 50px !important;
}

/* Styles for the input field on focus */
.mate-code-button input:focus {
  outline: none;
}
.mate-code-button input {
  font-size: 26px; /* Set the font size for the input text */
}

.mate-code-button input::placeholder {
  font-size: 26px !important; /* Set the font size for the placeholder text */
  color: #999 !important; /* Optional: Change the color of the placeholder text */
}
